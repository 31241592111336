import React from 'react';
import UserInfo from './UserInfo';
import Password from './Password';
import Iban from './Iban';
import StaticNote from './StaticNote';

const Settings = () => {
    const Divider = () => <div className='w-full my-6 py-1 border-b border-gray-300'></div> 
    return (
        <div className='text-center mx-6 mb-6 mt-3'>
            <Iban />
            <Divider />
            <StaticNote />
            <Divider />
            <UserInfo />
            <Divider />
            <Password />
        </div>
    )
};

export default Settings;
