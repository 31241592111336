import React from 'react';
import { useSanctum } from 'react-sanctum';
import GetRequest from 'components/GetRequest';
import InvoiceInfo from './InvoiceInfo';
import PurchaseProcess from './PurchaseProcess';
import ExpiryAlert from './ExpiryAlert';
import PurchaseTable from './PurchaseTable';

const Purchases = () => {

    const { user, setUser } = useSanctum();
    const [pending, setPending] = React.useState(false)
    const [purchases, setPurchases] = React.useState(null)
    const [updatedPurchase, setUpdatedPurchase] = React.useState(false)

    const Divider = () => <div className='w-full my-6 py-1 border-b border-gray-300'></div> 

    React.useEffect(() => {
        setPending(true)
        GetRequest('api/purchases')
        .then((response) => {
            setPurchases(response.data)
            setPending(false)
        })
        .catch(error => {
            setPurchases(null)
            setPending(false)
        })
    }, [updatedPurchase])
    
    return (
        <div className='text-center mx-6 mb-6 mt-3'>  
            <ExpiryAlert />          
            <InvoiceInfo />
            <Divider />
            {(user.tax_no === null || user.tax_dep === null || user.address === null) ? 
            (
                <>
                    <div className='py-2 border-b-2 border-gray-400 flex items-center justify-between'>
                        <p className='text-gray-800 text-xl'>Satın Al</p>
                    </div>  
                    <div className='mt-4'>
                        <p>Satın almaya devam edebilmek için fatura bilgilerinin eksiksiz olması gerekmektdir. Eksik bilgileri doldurmak için yukarıda yer alan tabloda güncelleme yapabilirsiniz.</p>
                    </div>
                </>
            ) : 
            <PurchaseProcess updatedStatus={updatedPurchase} updatedAction={setUpdatedPurchase} /> 
            }
            <Divider />
            <PurchaseTable data={purchases} actions={{}} progressPending={pending}/>
            
        </div>
    )
};

export default Purchases;
