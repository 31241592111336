
const FormatDate = (date, time = null) => {
  var jsDate = new Date(date)
  var day = jsDate.getDate()
  var month = jsDate.getMonth()
  var year = jsDate.getFullYear()
  var hhmm = time !== null ? time.substring(0,5) : '' 
  return `${day}.${month+1}.${year} ${hhmm}`;
}

export {FormatDate}