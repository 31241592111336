import React from 'react';
import Header from './Header';
import { Outlet, useMatch, useNavigate } from 'react-router-dom';

const Invoices = () => {
    let match = useMatch({ path: "/invoices", end: true })
    let navigate = useNavigate();
    
    React.useEffect(() => {        
        if(match) {
            navigate(`/invoices/issued`);
        }
    })
    

    

    return (
        <div className='text-center mx-6 mb-6 mt-3'>
            <Header />
            <Outlet />
        </div>
    )
};

export default Invoices;
