import React from 'react'
import { Link, useSearchParams, useParams } from 'react-router-dom';
import {useForm, useFieldArray} from "react-hook-form"
import {v4 as uuidv4} from "uuid"
import ButtonBigWithAction from 'elements/ButtonBigWithAction';
import InputWithLabelSmall from 'elements/InputWithLabelSmall';
import SelectWithLabelSmall from 'elements/SelectWithLabelSmall';
import TextareaWithLabelSmall from 'elements/TextareaWithLabelSmall';
import SelectWithSearchSmall from 'elements/SelectWithSearchSmall';
import { Icon } from 'elements/Icons';
import LoadingIndicator from 'pages/LoadingIndicator';
import PostRequest from 'components/PostRequest';
import GetRequest from 'components/GetRequest';
import PutRequest from 'components/PutRequest';
import InfoModal from 'components/InfoModal';
import currencies from 'data/Currencies';
import invoiceTypes from 'data/InvoiceTypes';
import countries from 'data/Countries';
import units from 'data/Units';
import vatRatios from 'data/VatRatios';
import deductionCodes from 'data/DeductionCodes';
import ItemInput from './ItemInput';

const InvoiceCreate = () => {
  let { invoiceId } = useParams()
  const getCurrentDate = () => {
    var date = new Date()
    var d = date.getDate()
    var m = date.getMonth() + 1
    var y = date.getFullYear()
    return '' + y + '-' + (m<=9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d)
  }
  const getCurrentTime = () => {
    var date = new Date()
    var h = date.getHours()
    var m = date.getMinutes()
    var s = date.getSeconds()
    return '' + (h<=9 ? '0' + h : h) + ':' + (m<=9 ? '0' + m : m)
  }
  const {register, handleSubmit, reset, trigger, resetField, formState: { errors }, setValue, getValues, watch, control} = useForm({
    defaultValues: {
        uuid: uuidv4(),
        invoiceNo: null,
        issueDate: getCurrentDate(),
        issueTime: getCurrentTime(),
        invoiceType: "SATIS",
        currency: "TRY",
        exchangeRate: 0,
        taxNo: null,
        customerName: null,
        taxDepartment: null,
        customerPhone: null,
        customerEmail: null,
        customerAddress: null,
        country: "Türkiye",
        notes: null,
        dispatchNo: null,
        dispatchDate: getCurrentDate(),
        returnInvoiceDate: getCurrentDate(),
        returnInvoiceNo: null,
        deductionCode: null,
        deductionRatio: 0,
        itemInputs: [
          { name: null, quantity: 1, unit: 'C62', unitPrice: null, discountRate: 0, discount: 0, itemPrice: 0, vatRatio: "18", vatAmount: 0 }
        ]
    },
    shouldFocusError: false
  })

  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: "itemInputs"
  });

  const watchItemsArray = watch("itemInputs");
  const controlledItemFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchItemsArray[index]
    };
  });

  const watchCurrency = watch('currency')
  const watchInvoiceType = watch('invoiceType')
  const [isInvoiceIdUpdate, setIsInvoiceIdUpdate] = React.useState(false)

  React.useEffect(() => {
    if(invoiceId !== null && invoiceId !== 0) {
      setLoading(true)
      GetRequest('api/invoices/'+invoiceId)
      .then(response => {
        setValue('uuid', response.data.uuid)
        setValue('invoiceNo', response.data.invoiceNo)
        setValue('issueDate', response.data.issueDate)
        setValue('issueTime', response.data.issueTime.substr(0,5))
        setValue('invoiceType', response.data.invoiceType)
        setValue('currency', response.data.currency)
        setValue('exchangeRate', response.data.exchangeRate.toFixed(2).replace(".", ","))
        setValue('dispatchNo', response.data.dispatchNo || null)
        setValue('dispatchDate', response.data.dispatchDate || getCurrentDate())
        setValue('returnInvoiceDate', response.data.returnInvoiceDate || null)
        setValue('returnInvoiceNo', response.data.returnInvoiceNo || null)
        setValue('deductionCode', response.data.deductionCode || null)
        setValue('deductionRatio', response.data.deductionRatio || null)
        setValue('customerName', response.data.customerName)
        setValue('taxNo', response.data.taxNo)
        setValue('taxDepartment', response.data.taxDepartment)
        setValue('customerPhone', response.data.customerPhone)
        setValue('customerEmail', response.data.customerEmail)
        setValue('customerAddress', response.data.customerAddress)
        setValue('country', response.data.country)
        setValue('notes', response.data.notes)
        var invoiceItems = response.data.items.map(value => {
          return {
            name: value.name,
            quantity: value.quantity.toFixed(2).replace(".", ","),
            unit: value.unit,
            unitPrice: value.unitPrice.toFixed(2).replace(".", ","),
            discountRate: value.discountRate.toFixed(2).replace(".", ","),
            discount: value.discount.toFixed(2).replace(".", ","),
            itemPrice: value.itemPrice.toFixed(2).replace(".", ","),
            vatRatio: value.vatRatio,
            vatAmount: value.vatAmount.toFixed(2).replace(".", ","),
          }
        })
        reset({itemInputs : invoiceItems})
        setIsInvoiceIdUpdate(!isInvoiceIdUpdate)
        setLoading(false)
      }).catch(error => {
        setLoading(false)
      })
    } 
  }, [])

  React.useEffect(() => {
    handleCalculation()
  }, [isInvoiceIdUpdate])

  const invoiceInputs = {
    uuid: {
        key: "createInput1",
        type: "text",
        name: "uuid",
        placeholder: "Zorunlu Alan",
        label: "ETTN *",
        register: register,
        validator: {
            required: "Bu alan zorunludur.",                 
        },
        error: errors?.uuid?.message,
        readOnly: true, 
    },
    invoiceNo: {
        key: "createInput0",
        type: "text",
        name: "invoiceNo",
        placeholder: "Zorunlu Alan",
        label: "Fatura No *",
        register: register,
        validator: {
            required: "Bu alan zorunludur.",                 
        },
        error: errors?.invoiceNo?.message,
        readOnly: true, 
    },
    issueDate: {
        key: "createInput2",
        type: "date",
        name: "issueDate",
        placeholder: "Zorunlu Alan",
        label: "Düzenleme Tarihi *",
        register: register,
        validator: {
            required: "Bu alan zorunludur.", 
            pattern: {value: /^(19|20)\d\d[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])$/,
              message: "Geçerli bir tarih girmelisiniz."}                
        },
        error: errors?.issueDate?.message 
    },
    issueTime: {
        key: "createInput3",
        type: "time",
        name: "issueTime",
        placeholder: "Zorunlu Alan",
        label: "Düzenleme Saati *",
        register: register,
        validator: {
            required: "Bu alan zorunludur.", 
            pattern: {value: /^([0-2][0-3]|[0-1][0-9]):[0-5][0-9]+$/,
              message: "Geçerli bir saat girmelisiniz."}                
        },
        error: errors?.issueTime?.message 
    },
    invoiceType: {
        key: "createInput4",
        type: "select",
        name: "invoiceType",
        placeholder: "Zorunlu Alan",
        label: "Fatura Tipi *",
        register: register,
        validator: {
            required: "Bu alan zorunludur.",                 
        },
        error: errors?.invoiceType?.message,
        data: invoiceTypes 
    },
    currency: {
        key: "createInput5",
        type: "select",
        name: "currency",
        placeholder: "Zorunlu Alan",
        label: "Para Birimi *",
        register: register,
        validator: {
            required: "Bu alan zorunludur.",                 
        },
        error: errors?.currency?.message,
        data: currencies 
    },
    exchangeRate: {
      key: "createInput6",
      type: "text",
      name: "exchangeRate",
      placeholder: "Zorunlu Alan",
      label: "Döviz Kuru *",
      register: register,
      validator: {
          required: "Bu alan zorunludur.",          
          pattern: {value: /^[0-9]\d*(,\d+)?$/,
            message: "12345,67 formatında bir sayı olmalı"},
          validate: {
            positive: v => (getValues("currency") === "TRY" || parseFloat(v.toString().replace(",", ".")) > 0) || 'Sıfırdan büyük olmalı'
          },
      },
      error: errors?.exchangeRate?.message 
    },
    dispatchNo: {
      key: "createInput7",
      type: "text",
      name: "dispatchNo",
      placeholder: "Boş bırakılabilir",
      label: "İrsaliye Numarası",
      register: register,
      validator: {
        minLength: {value: 4, 
          message: "En az 4 karakter olmalı"}, 
        maxLength: {value: 255, 
                  message:"En fazla 255 karakter olmalı"}              
      },
      error: errors?.dispatchNo?.message
    },
    dispatchDate: {
      key: "createInput8",
      type: "date",
      name: "dispatchDate",
      placeholder: "Boş bırakılabilir",
      label: "İrsaliye Tarihi",
      register: register,
      validator: {
          pattern: {value: /^(19|20)\d\d[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])$/,
            message: "Geçerli bir tarih girmelisiniz."}                
      },
      error: errors?.dispatchDate?.message 
    },
  }

  const customerInputs = {
    taxNo: {
        key: "customerInput1",
        type: "text",
        name: "taxNo",
        placeholder: "Zorunlu Alan",
        label: "TC/Vergi No *",
        register: register,
        validator: {
            required: "Bu alan zorunludur.", 
            pattern: {value: /^[0-9]{10,11}$/,
                        message: "10 veya 11 haneli rakam olmalı"},
            onBlur: (e) => handleCustomerInfo(e)           
        },
        error: errors?.taxNo?.message,
    },
    customerName: {
        key: "customerInput2",
        type: "text",
        name: "customerName",
        placeholder: "Zorunlu Alan",
        label: "Müşteri Adı *",
        register: register,
        validator: {
            required: "Bu alan zorunludur.",
            minLength: {value: 4, 
                        message: "En az 4 karakter olmalı"}, 
            maxLength: {value: 255, 
                        message:"En fazla 255 karakter olmalı"}             
        },
        error: errors?.customerName?.message,
    },
    taxDepartment: {
        key: "customerInput3",
        type: "text",
        name: "taxDepartment",
        placeholder: "Zorunlu Alan",
        label: "Vergi Dairesi *",
        register: register,
        validator: {
            required: "Bu alan zorunludur.",   
            minLength: {value: 4, 
                        message: "En az 4 karakter olmalı"}, 
            maxLength: {value: 255, 
                        message:"En fazla 255 karakter olmalı"}              
        },
        error: errors?.taxDepartment?.message,
    },
    customerPhone: {
        key: "customerInput4",
        type: "text",
        name: "customerPhone",
        placeholder: "Boş olabilir",
        label: "Telefon",
        register: register,
        validator: {
                required: false, 
                minLength: {value: 10, 
                    message: "En az 10 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"}
        },
        error: errors?.customerPhone?.message,
    },
    customerEmail: {
        key: "customerInput5",
        type: "text",
        name: "customerEmail",
        placeholder: "Boş olabilir",
        label: "E-Posta",
        register: register,
        validator: {
                  required: false, 
                  pattern: {value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: "Geçerli bir e-posta adresi olmalı"}                 
        },
        error: errors?.customerEmail?.message,
    },
    customerAddress: {
        key: "customerInput6",
        type: "textarea",
        name: "customerAddress",
        placeholder: "Boş olabilir",
        label: "Adres",
        register: register,
        validator: {},
        error: errors?.customerAddress?.message,
    },
    country: {
      key: "customerInput7",
      type: "select",
      name: "country",
      placeholder: "Zorunlu Alan",
      label: "Ülke *",
      register: register,
      validator: {
          required: "Bu alan zorunludur.",                 
      },
      error: errors?.country?.message,
      data: countries 
    },
  }

  const noteInputs = {
    notes: {
        key: "noteInput1",
        type: "textarea",
        name: "notes",
        placeholder: " ",
        label: "Notlar",
        register: register,
        validator: {
            required: false,                 
        },
        error: errors?.notes?.message,
    },
  }

  const returnInputs = {
    returnInvoiceNo: {
        key: "returnInput1",
        type: "text",
        name: "returnInvoiceNo",
        placeholder: "Zorunlu Alan",
        label: "İade Fatura No *",
        register: register,
        validator: {
          validate: {
            notNull: v => (getValues("invoiceType") !== "IADE" || (v !== null && v !== "")) || 'Zorunlu Alan'
          },
          pattern: {value: /^[0-9A-Z]{3}[0-9]{13}$/,
            message: "'GIB2022000000001' formatında fatura no girmelisiniz."}                 
        },
        error: errors?.returnInvoiceNo?.message
    },
    returnInvoiceDate: {
        key: "returnInput2",
        type: "date",
        name: "returnInvoiceDate",
        placeholder: "Zorunlu Alan",
        label: "İade Fatura Tarihi *",
        register: register,
        validator: {
            validate: {
              notNull: v => (getValues("invoiceType") !== "IADE" || (v !== null && v !== "")) || 'Zorunlu Alan'
            }, 
            pattern: {value: /^(19|20)\d\d[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])$/,
              message: "Geçerli bir tarih girmelisiniz."}                
        },
        error: errors?.returnInvoiceDate?.message 
    },
  }

  const deductionInputs = {
    deductionCode: {
        key: "deductionInput1",
        type: "select",
        name: "deductionCode",
        placeholder: "Zorunlu Alan",
        label: "Tevkifat Kodu *",
        register: register,
        validator: {
          validate: {
            notNull: v => (getValues("invoiceType") !== "TEVKIFAT" || (v !== null && v !== "")) || 'Zorunlu Alan'
          },
          onChange: (e) => {setValue("deductionRatio", deductionCodes.filter(v => v.key === e.target.value)[0].ratio); handleCalculation()}                 
        },
        error: errors?.deductionCode?.message,
        data: deductionCodes 
    },
    deductionRatio: {
        key: "deductionInput2",
        type: "text",
        name: "deductionRatio",
        placeholder: " ",
        label: "Tevkifat Oranı",
        register: register,
        validator: {},
        error: errors?.deductionRatio?.message,
        readOnly: true, 
    },
  }

  const itemValidation = {
    name: {
      required: "Bu alan zorunludur.",   
      minLength: {value: 3, 
                  message: "En az 3 karakter olmalı"}, 
      maxLength: {value: 255, 
                  message:"En fazla 255 karakter olmalı"}
    },
    quantity: {
      required: "Bu alan zorunludur.", 
      pattern: {value: /^[0-9]\d*(,\d{1,6})?$/,
                  message: "12345,67891 formatında bir sayı olmalı"},
      validate: {
        positive: v => parseFloat(v.toString().replace(",", ".")) > 0 || 'Sıfırdan büyük olmalı'
      },
      onChange: (e) => handleCalculation()
    },
    unit: {
      required: "Bu alan zorunludur."
    },
    unitPrice: {
      required: "Bu alan zorunludur.", 
      pattern: {value: /^[0-9]\d*(,\d{1,6})?$/,
                  message: "12345,67891 formatında bir sayı olmalı"},
      validate: {
        positive: v => parseFloat(v.toString().replace(",", ".")) > 0 || 'Sıfırdan büyük olmalı'
      },
      onChange: (e) => handleCalculation()
    },
    discountRate: {
      required: "Bu alan zorunludur.", 
      pattern: {value: /^[0-9]\d*(,\d{1,2})?$/,
                  message: "12345,67 formatında bir sayı olmalı"},
      onChange: (e) => handleCalculation()
    },
    discount: {
      required: "Bu alan zorunludur.", 
      pattern: {value: /^[0-9]\d*(,\d{1,2})?$/,
                  message: "12345,67 formatında bir sayı olmalı"},
      onChange: (e) => handleCalculation()
    },
    itemPrice: {
      required: "Bu alan zorunludur.", 
      pattern: {value: /^[0-9]\d*(,\d{1,2})?$/,
                  message: "12345,67 formatında bir sayı olmalı"},
      validate: {
        positive: v => parseFloat(v.toString().replace(",", ".")) > 0 || 'Sıfırdan büyük olmalı'
      }
    },
    vatRatio: {
      required: "Bu alan zorunludur.",
      onChange: (e) => handleCalculation()
    },
    vatAmount: {
      required: "Bu alan zorunludur.", 
      pattern: {value: /^[0-9]\d*(,\d{1,2})?$/,
                  message: "12345,67 formatında bir sayı olmalı"}
    },
  }

  const [totalAmounts, setTotalAmounts] = React.useState({
                                                  price: 0,
                                                  discount: 0,
                                                  vat: 0,
                                                  total: 0,
                                                  paid: 0,
                                                  deduction: 0,
                                                })

  const [loading, setLoading] = React.useState(false)
  const [createdStatus, setCreatedStatus] = React.useState({status: false, content: null})
  const [isOpenCreated, setIsOpenCreated] = React.useState(false)
  
  const handleCalculation = () => {
    var totals = {
      price: 0,
      discount: 0,
      vat: 0,
      total: 0,
      paid: 0,
      deduction: 0,
    }
    controlledItemFields.map((item,index) => {
      var inputValues = getValues(`itemInputs.${index}`)
      if(inputValues.quantity && inputValues.unitPrice) {
        if(parseFloat(inputValues.discountRate) > 0) {
          let calculatedDiscount = (parseFloat(inputValues.quantity.toString().replace(",", ".")) * parseFloat(inputValues.unitPrice.toString().replace(",", "."))) *  parseFloat(inputValues.discountRate.toString().replace(",", ".")) / 100
          inputValues.discount = calculatedDiscount.toFixed(2).replace(".", ",")
          setValue(`itemInputs.${index}.discount`, calculatedDiscount.toFixed(2).replace(".", ","))
        }
        var itemPrice = parseFloat(inputValues.quantity.toString().replace(",", ".")) * parseFloat(inputValues.unitPrice.toString().replace(",", ".")) - parseFloat(inputValues.discount.toString().replace(",", "."))
        var vatAmount = itemPrice * parseFloat(inputValues.vatRatio.toString().replace(",", ".")) / 100
        totals.price += parseFloat(inputValues.quantity.toString().replace(",", ".")) * parseFloat(inputValues.unitPrice.toString().replace(",", "."))
        totals.discount += parseFloat(inputValues.discount.toString().replace(",", "."))
        totals.vat += vatAmount
        totals.total += (parseFloat(inputValues.quantity.toString().replace(",", ".")) * parseFloat(inputValues.unitPrice.toString().replace(",", "."))) - (parseFloat(inputValues.discount.toString().replace(",", "."))) + (vatAmount)
        totals.deduction += watchInvoiceType === "TEVKIFAT" ? getValues("deductionRatio")*vatAmount/100 : 0
        totals.paid = (totals.total - totals.deduction)
        setValue(`itemInputs.${index}.itemPrice`, itemPrice.toFixed(2).replace(".", ","))
        setValue(`itemInputs.${index}.vatAmount`, vatAmount.toFixed(2).replace(".", ","))
      }
    })
    setTotalAmounts({
      price: totals.price.toFixed(2).replace(".", ","),
      discount: totals.discount.toFixed(2).replace(".", ","),
      vat: totals.vat.toFixed(2).replace(".", ","),
      total: totals.total.toFixed(2).replace(".", ","),
      paid: totals.paid.toFixed(2).replace(".", ","),
      deduction: totals.deduction.toFixed(2).replace(".", ","),
    })
  }

  const handleCustomerInfo = (e) => {
    PostRequest('api/invoices/customerinfo', {taxNo: e.target.value})
      .then(response => {
        var customerData = response.data?.data
        if(customerData) {
          if(customerData.unvan !== '' && customerData.unvan !== null) {
            setValue('customerName', customerData.unvan)
          } else {
            setValue('customerName', customerData.adi+' '+customerData.soyadi)
          }
          setValue('taxDepartment', customerData.vergiDairesi)
        }
        
      })
      .catch(error => {
        console.log(error.response)
      })
  }

  const infoModalLinks = (
    <>
      <Link to="/invoices/issued" className='p-2 mt-4 text-emerald-100 bg-emerald-600'> Kesilen Faturalara Git</Link>
    </>
  ) 

  const handleForm = (data) => {
    setLoading(true)
    data.totalAmounts = totalAmounts
    PutRequest('api/invoices/'+invoiceId, data)
        .then(response => {
          console.log(response.data)
            const successMessage = (
                <div className='text-sm text-gray-700 text-bold mb-2 p-1'>
                    <p>Fatura başarıyla güncellendi. Onaylama işlemini kesilen faturalar sayfasından yapabilirsiniz.</p> 
                </div>
            )
            setCreatedStatus({status: true, content: successMessage})
            setLoading(false)
            setIsOpenCreated(true)
        })
        .catch(error => {
            const err = error.response.data.errors
            if(err) {
                const errorItems = Object.keys(err).map((value, index) => {
                    return (
                        <li key={value.toString()}>{err[value][0]}</li>
                    )
                })
                const errorMessage = (
                    <div className='text-sm text-gray-700 text-bold mb-2 p-1'>
                        <p>{error.response.data.message}</p>   
                        <ul className='list-[circle] list-inside'>
                            {errorItems}
                        </ul>                     
                    </div>
                )
                setCreatedStatus({status: false, content: errorMessage})
            } else if(error.response?.data?.ErrorStatus) {
                if(error.response.data.ErrorStatus?.data) {
                  const errorMessage = (
                    <div className='text-sm text-gray-700 text-bold mb-2 p-1'>
                        <p>{`${error.response.data.ErrorStatus.Message} - ${error.response.data.ErrorStatus.data} (${error.response.data.ErrorStatus.Code})`}</p> 
                    </div>
                  )
                  setCreatedStatus({status: false, content: errorMessage})
                } else {
                  const errorMessage = (
                    <div className='text-sm text-gray-700 text-bold mb-2 p-1'>
                        <p>{`${error.response.data.ErrorStatus.Message} (${error.response.data.ErrorStatus.Code})`}</p> 
                    </div>
                  )
                  setCreatedStatus({status: false, content: errorMessage})
                } 
                
            }  else {
              const errorMessage = (
                  <div className='text-sm text-gray-700 text-bold mb-2 p-1'>
                      <p>{`${error.response.statusText} (${error.response.status})`}</p> 
                  </div>
              )  
              setCreatedStatus({status: false, content: errorMessage})
          }                
            
            setLoading(false)
            setIsOpenCreated(true)
        })
  }

  const handleOpenInvoiceInfoSection = (id) => {
      const section =  document.getElementById('invoiceInfoSection')
      const chewron =  document.getElementById('invoiceInfoButtonChewron')
     
      section.classList.toggle('visible')
      section.classList.toggle('hidden')
    
      chewron.classList.toggle('rotate-180')
  }
  return (
    <div className='mt-4'>
      <form onSubmit={handleSubmit(handleForm)}>
          <button type="button" onClick={handleOpenInvoiceInfoSection} className='w-full py-1 px-2 mb-4 text-cyan-100 bg-cyan-800 text-sm lg:text-base text-left flex justify-between items-center'>
            <span>Fatura Bilgileri</span>
            <span id="invoiceInfoButtonChewron" className='inline lg:hidden'>
                <Icon name="chewronDownFilled" size="18"/>
            </span>
          </button>
          <div id="invoiceInfoSection" className='hidden lg:block'>
            <div className='lg:grid lg:grid-cols-6 gap-4'>
              <div className='col-span-1'>
                <InputWithLabelSmall {...invoiceInputs["uuid"]} addClass="mt-2" />
              </div>
              <div className='col-span-1'>
                <InputWithLabelSmall {...invoiceInputs["invoiceNo"]} addClass="mt-2" />
              </div>
              <div className='col-span-1'>
                <InputWithLabelSmall {...invoiceInputs["issueDate"]} addClass="mt-2" />
              </div>
              <div className='col-span-1'>
                <InputWithLabelSmall {...invoiceInputs["issueTime"]} addClass="mt-2" />
              </div>
              <div className='col-span-1'>
                <SelectWithLabelSmall {...invoiceInputs["invoiceType"]} addClass="mt-2" />
              </div>
              <div className='col-span-1'>
                <SelectWithLabelSmall {...invoiceInputs["currency"]} addClass="mt-2" />
                <div className={(watchCurrency !== "TRY" && watchCurrency !== "") ? ' ' : 'hidden'}>
                  <InputWithLabelSmall {...invoiceInputs["exchangeRate"]} addClass="mt-2" />
                </div>
              </div>
              <div className='col-span-2'>
                <InputWithLabelSmall {...invoiceInputs["dispatchNo"]} addClass="mt-2" />
              </div>
              <div className='col-span-1'>
                <InputWithLabelSmall {...invoiceInputs["dispatchDate"]} addClass="mt-2" />
              </div>
            </div>
            <div className={['lg:grid lg:grid-cols-6 gap-4', watchInvoiceType === "IADE" ? 'block lg:grid' : 'hidden lg:hidden'].join(' ')}>
              <div className='col-span-2'>
                <InputWithLabelSmall {...returnInputs["returnInvoiceNo"]} addClass="mt-2" />
              </div>
              <div className='col-span-2'>
                <InputWithLabelSmall {...returnInputs["returnInvoiceDate"]} addClass="mt-2" />
              </div>
            </div>
            <div className={['lg:grid lg:grid-cols-6 gap-4', watchInvoiceType === "TEVKIFAT" ? 'block lg:grid' : 'hidden lg:hidden'].join(' ')}>
              <div className='col-span-4'>
                <SelectWithLabelSmall {...deductionInputs["deductionCode"]} addClass="mt-2" />
              </div>
              <div className='col-span-1'>
                <InputWithLabelSmall {...deductionInputs["deductionRatio"]} addClass="mt-2" />
              </div>
            </div>
          </div>
          <div className='py-1 px-2 mb-4 text-cyan-100 bg-cyan-800 text-sm lg:text-base text-left mt-4'>Müşteri Bilgileri</div>
          <div className='lg:grid lg:grid-cols-6 gap-x-4'>
            <div className='col-span-1'>
              <InputWithLabelSmall {...customerInputs["taxNo"]} addClass="mt-2" />
            </div>
            <div className='col-span-2'>
              <InputWithLabelSmall {...customerInputs["customerName"]} addClass="mt-2" />
            </div>
            <div className='col-span-1'>
              <InputWithLabelSmall {...customerInputs["taxDepartment"]} addClass="mt-2" />
            </div>
            <div className='col-span-1'>
              <InputWithLabelSmall {...customerInputs["customerPhone"]} addClass="mt-2" />
            </div>
            <div className='col-span-1'>
              <InputWithLabelSmall {...customerInputs["customerEmail"]} addClass="mt-2" />
            </div>
          </div>  
          <div className='lg:grid lg:grid-cols-6 gap-4'>
            <div className='col-span-5'>
            <TextareaWithLabelSmall {...customerInputs["customerAddress"]} addClass="mt-2" />
            </div>
            <div className='col-span-1'>
              <SelectWithLabelSmall {...customerInputs["country"]} addClass="mt-2" />
            </div>
          </div>
          <div className='py-1 px-2 mb-4 text-cyan-100 bg-cyan-800 text-sm lg:text-base text-left mt-4'>Fatura Notları</div>
          <div className='lg:grid lg:grid-cols-6 gap-4'>
            <div className='col-span-6'>
            <TextareaWithLabelSmall {...noteInputs["notes"]} addClass="mt-2" rowNumber="3" />
            </div>
          </div>
          <div className='py-1 px-2 mb-4 text-cyan-100 bg-cyan-800 text-sm lg:text-base text-left mt-4'>Mal/Hizmet Kalemleri</div>
          <div className='hidden lg:grid lg:grid-cols-12 gap-2 mt-2 border-b border-gray-700 text-gray-700 text-sm'>
            <div className='col-span-3 flex flex-col gap-y-1 items-start'>
              <span className='text-[12px] line-clamp-1'>Mal/Hizmet Kalemi</span>
            </div>
            <div className='col-span-1 flex flex-col gap-y-1 items-start'>
              <span className='text-[12px] line-clamp-1'>Miktar</span>
            </div>
            <div className='col-span-1 flex flex-col gap-y-1 items-start'>
              <span className='text-[12px] line-clamp-1'>Birim</span>
            </div>
            <div className='col-span-1 flex flex-col gap-y-1 items-start'>
              <span className='text-[12px] line-clamp-1'>Birim Fiyat</span>
            </div>
            <div className='col-span-1 flex flex-col gap-y-1 items-start'>
              <span className='text-[12px] line-clamp-1'>İskonto (%)</span>
            </div>
            <div className='col-span-1 flex flex-col gap-y-1 items-start'>
              <span className='text-[12px] line-clamp-1'>İskonto Tut.</span>
            </div>
            <div className='col-span-1 flex flex-col gap-y-1 items-start'>
              <span className='text-[12px] line-clamp-1'>Mal/Hizmet Tut.</span>
            </div>
            <div className='col-span-1 flex flex-col gap-y-1 items-start'>
              <span className='text-[12px] line-clamp-1'>KDV</span>
            </div>
            <div className='col-span-1 flex flex-col gap-y-1 items-start'>
              <span className='text-[12px] line-clamp-1'>KDV Tutarı</span>
            </div>
            <div className='col-span-1 flex flex-col gap-y-1 items-start'>
              <span></span>
            </div>
          </div>
            {controlledItemFields.map((field, index) => {
              return (<div className='lg:grid lg:grid-cols-12 gap-2 mt-2' key={`itemInput${index}`}>
                <div className='col-span-3 flex flex-col gap-y-1 items-start'>
                  <label htmlFor={`itemInputs.${index}.name`} className='text-gray-700 text-sm lg:hidden'>Mal/Hizmet Kalemi</label>
                  <ItemInput name={`itemInputs.${index}.name`} 
                              placeholder="Mal/Hizmet Kalemi" 
                              register={register} 
                              validation={itemValidation.name} 
                              setValue={setValue}
                              index={index}
                              calculation={handleCalculation}
                  />
                  <span className="text-sm text-red-600">{(typeof errors?.itemInputs !== "undefined" && typeof errors?.itemInputs[index] !== "undefined") && errors?.itemInputs[index]?.name?.message}</span>
                </div>
                <div className='col-span-1 flex flex-col gap-y-1 items-start'>
                <label htmlFor={`itemInputs.${index}.quantity`} className='text-gray-700 text-sm lg:hidden'>Miktar</label>
                  <input className='form-input w-full rounded-sm px-1 py-1 text-sm border border-gray-400' 
                          name={`itemInputs.${index}.quantity`}
                          placeholder="Miktar"
                          {...register(`itemInputs.${index}.quantity`, itemValidation.quantity)} 
                          />
                  <span className="text-sm text-red-600">{(typeof errors?.itemInputs !== "undefined" && typeof errors?.itemInputs[index] !== "undefined") && errors?.itemInputs[index]?.quantity?.message}</span>
                </div>
                <div className='col-span-1 flex flex-col gap-y-1 items-start'>
                  <label htmlFor={`itemInputs.${index}.unit`} className='text-gray-700 text-sm lg:hidden'>Birim</label>
                  <select className='form-input w-full rounded-sm px-1 py-1 text-sm border border-gray-400' 
                          name={`itemInputs.${index}.unit`}
                          placeholder="Birim"
                          {...register(`itemInputs.${index}.unit`, itemValidation.unit)} 
                          >
                        {
                          units.map(unit => {
                            return <option key={unit.key} value={unit.key}>{unit.value}</option>
                          })
                        }
                  </select>
                  <span className="text-sm text-red-600">{(typeof errors?.itemInputs !== "undefined" && typeof errors?.itemInputs[index] !== "undefined") && errors?.itemInputs[index]?.unit?.message}</span>
                </div>
                <div className='col-span-1 flex flex-col gap-y-1 items-start'>
                  <label htmlFor={`itemInputs.${index}.unitPrice`} className='text-gray-700 text-sm lg:hidden'>Birim Fiyat</label>
                  <input className='form-input w-full rounded-sm px-1 py-1 text-sm border border-gray-400' 
                          name={`itemInputs.${index}.unitPrice`}
                          placeholder="Birim Fiyat"
                          {...register(`itemInputs.${index}.unitPrice`, itemValidation.unitPrice)} 
                          />
                  <span className="text-sm text-red-600">{(typeof errors?.itemInputs !== "undefined" && typeof errors?.itemInputs[index] !== "undefined") && errors?.itemInputs[index]?.unitPrice?.message}</span>
                </div>
                <div className='col-span-1 flex flex-col gap-y-1 items-start'>
                  <label htmlFor={`itemInputs.${index}.discountRate`} className='text-gray-700 text-sm lg:hidden'>İskonto (%)</label>
                  <input className='form-input w-full rounded-sm px-1 py-1 text-sm border border-gray-400' 
                          name={`itemInputs.${index}.discountRate`}
                          placeholder="İndirim"
                          {...register(`itemInputs.${index}.discountRate`, itemValidation.discountRate)} 
                          />
                  <span className="text-sm text-red-600">{(typeof errors?.itemInputs !== "undefined" && typeof errors?.itemInputs[index] !== "undefined") && errors?.itemInputs[index]?.discountRate?.message}</span>
                </div>
                <div className='col-span-1 flex flex-col gap-y-1 items-start'>
                  <label htmlFor={`itemInputs.${index}.discount`} className='text-gray-700 text-sm lg:hidden'>İskonto</label>
                  <input className='form-input w-full rounded-sm px-1 py-1 text-sm border border-gray-400' 
                          name={`itemInputs.${index}.discount`}
                          placeholder="İndirim"
                          {...register(`itemInputs.${index}.discount`, itemValidation.discount)} 
                          />
                  <span className="text-sm text-red-600">{(typeof errors?.itemInputs !== "undefined" && typeof errors?.itemInputs[index] !== "undefined") && errors?.itemInputs[index]?.discount?.message}</span>
                </div>
                <div className='col-span-1 flex flex-col gap-y-1 items-start'>
                  <label htmlFor={`itemInputs.${index}.itemPrice`} className='text-gray-700 text-sm lg:hidden'>Mal/Hizmet Tutarı</label>
                  <input className='form-input w-full rounded-sm px-1 py-1 text-sm border border-gray-400 bg-gray-300' 
                          name={`itemInputs.${index}.discount`}
                          placeholder="Mal/Hizmet Tutarı"
                          {...register(`itemInputs.${index}.itemPrice`, itemValidation.itemPrice)} 
                          readOnly={true}
                          />
                  <span className="text-sm text-red-600">{(typeof errors?.itemInputs !== "undefined" && typeof errors?.itemInputs[index] !== "undefined") && errors?.itemInputs[index]?.itemPrice?.message}</span>
                </div>
                <div className='col-span-1 flex flex-col gap-y-1 items-start'>
                  <label htmlFor={`itemInputs.${index}.vatRatio`} className='text-gray-700 text-sm lg:hidden'>KDV Oranı</label>
                  <select className='form-input w-full rounded-sm px-1 py-1 text-sm border border-gray-400' 
                          name={`itemInputs.${index}.vatRatio`}
                          placeholder="Birim"
                          {...register(`itemInputs.${index}.vatRatio`, itemValidation.vatRatio)} 
                          >
                        {
                          vatRatios.map(unit => {
                            return <option key={unit.key} value={unit.key}>{unit.value}</option>
                          })
                        }
                  </select>
                  <span className="text-sm text-red-600">{(typeof errors?.itemInputs !== "undefined" && typeof errors?.itemInputs[index] !== "undefined") && errors?.itemInputs[index]?.vatRatio?.message}</span>
                </div>
                <div className='col-span-1 flex flex-col gap-y-1 items-start'>
                  <label htmlFor={`itemInputs.${index}.vatAmount`} className='text-gray-700 text-sm lg:hidden'>KDV Tutarı</label>
                  <input className='form-input w-full rounded-sm px-1 py-1 text-sm border border-gray-400 bg-gray-300' 
                          name={`itemInputs.${index}.discount`}
                          placeholder="KDV Tutarı"
                          {...register(`itemInputs.${index}.vatAmount`, itemValidation.vatAmount)} 
                          readOnly={true}
                          />
                  <span className="text-sm text-red-600">{(typeof errors?.itemInputs !== "undefined" && typeof errors?.itemInputs[index] !== "undefined") && errors?.itemInputs[index]?.vatAmount?.message}</span>
                </div>
                <div className='col-span-1'>
                  <div>
                    {
                      index === 0 
                      ? <button className='w-full rounded px-2 py-1 text-sm bg-sky-700 text-sky-100' type="button" onClick={() => append({ name: null, quantity: 1, unit: 'C62', unitPrice: null, discount: 0, itemPrice: 0, vatRatio: "18", vatAmount: 0 })}>
                        <span className='hidden lg:inline'>Ekle</span>
                        <span className='inline lg:hidden'>Yeni Satır Ekle</span>
                        </button>
                      : <button className='w-full rounded px-2 py-1 text-sm bg-rose-700 text-rose-100' type="button" onClick={() => remove(index)}>Sil</button>
                    }
                    
                  </div>
                </div>
              </div>)
            }) }
            <div className='lg:grid lg:grid-cols-2 mt-4 pt-4 border-t border-cyan-800'>
              <div className='col-span-1'></div>
              <div className='col-span-1 flex justify-end gap-x-4 text-sm'>
                <div className='flex flex-col gap-y-2 items-end'>
                  <p>Mal/Hizmet Toplam Tutarı:</p>
                  <p>Toplam İskonto:</p>
                  <p>Hesaplanan KDV:</p>
                  {watchInvoiceType === "TEVKIFAT" && (<><p>Tevkifata Tabi İşlem Tutar:</p>
                  <p>Tevkifata Tabi İşlem Üzerinden Hes. KDV:</p>
                  <p>Hesaplanan KDV TEVKİFAT:</p></>)}
                  <p>Vergiler Dahil Toplam Tutar:</p>
                  <p>Ödenecek Tutar:</p>
                </div>
                <div className='flex flex-col gap-y-2 items-start'>
                  <p>{totalAmounts.price} {getValues('currency')}</p>
                  <p>{totalAmounts.discount} {getValues('currency')}</p>
                  <p>{totalAmounts.vat} {getValues('currency')}</p>
                  {watchInvoiceType === "TEVKIFAT" && (<><p>{totalAmounts.price} {getValues('currency')}</p>
                  <p>{totalAmounts.vat} {getValues('currency')}</p>
                  <p>{totalAmounts.deduction} {getValues('currency')}</p></>)}
                  <p>{totalAmounts.total} {getValues('currency')}</p>
                  <p>{totalAmounts.paid} {getValues('currency')}</p>
                </div>
              </div>

            </div>
            <div className='flex gap-x-3 items-center justify-end mt-4 pt-4 border-t border-cyan-800'>
              <ButtonBigWithAction type="submit" addClass="text-white bg-emerald-500" title="Faturayı Güncelle" />
            </div>
      </form>
      <InfoModal isOpen={isOpenCreated} 
                    isOpenAction={setIsOpenCreated} 
                    id="invoiceCreated" 
                    title="Fatura Durumu" 
                    cancelButton={createdStatus.status === false ? true : false} 
                    actions={createdStatus.status === true ? infoModalLinks : null}
                    success={createdStatus.status}>
            <div>
                {createdStatus.content}
            </div>
        </InfoModal>
      {loading && <LoadingIndicator />}
    </div>
  )
}

export default InvoiceCreate