import React from 'react'
import { Link, useMatch, useResolvedPath } from 'react-router-dom'
import { Icon } from 'elements/Icons'

const Header = () => {

    const LinkButton = ({linkData}) => {
        let resolved = useResolvedPath(linkData.path)
        let match = useMatch({ path: resolved.pathname, end: true })
        let className = null

        if(linkData.className !== null) {
            className = linkData.className
        } else {
            className = match  
                        ? 'flex flex-col items-center justify-center gap-y-2 border border-emerald-800 py-2 px-4 text-emerald-100 bg-emerald-700 font-semibold'
                        : 'flex flex-col items-center justify-center gap-y-2 border border-gray-800 py-2 px-4 text-gray-100 bg-gray-700 font-semibold'
        }
        

        return (<Link to={linkData.path} className={className} key={linkData.id}>
                    <Icon name={linkData.iconName} size="24" />
                    <span>{linkData.title}</span>
                    
                </Link>)
    }

    const MobileLinkButton = ({linkData}) => {
        let resolved = useResolvedPath(linkData.path)
        let match = useMatch({ path: resolved.pathname, end: true })

        const className = match  
                        ? 'w-full px-2 py-1 text-left bg-gray-200 text-gray-700 font-semibold'
                        : 'w-full px-2 py-1 text-left text-gray-500'
        
        return <Link to={linkData.path} className={className} key={linkData.id}>{linkData.title}</Link>
    }

    const [mobileMenuTitle, setMobileMenuTitle] = React.useState('Fatura İşlemleri')
    const [isOpenMobileMenu, setIsOpenMobileMenu] = React.useState(false)

    const handleOpen = (e) => {
        e.preventDefault()
        setIsOpenMobileMenu(!isOpenMobileMenu)
    }

    const handleMobileMenuClick = (e) => {
        setMobileMenuTitle(e.target.text)
        setIsOpenMobileMenu(false)
    }

    const links = [
        
        {
            id: "link1",
            path: "/invoices/create",
            title: "Yeni Fatura Oluştur",
            className: "flex flex-col items-center justify-center gap-y-2 border border-sky-800 py-2 px-4 text-sky-100 bg-sky-700 font-semibold",
            iconName: "documentPlus"
        },
        {
            id: "link2",
            path: "/invoices/issued",
            title: "Kesilen Faturalar",
            className: null,
            iconName: "documentDuplicate"
        },
        {
            id: "link3",
            path: "/invoices/draft",
            title: "Şablon Faturalar",
            className: null,
            iconName: "collection"
        },
        {
            id: "link4",
            path: "/invoices/incoming",
            title: "Gelen Faturalar",
            className: null,
            iconName: "documentCopy"
        },
        {
            id: "link5",
            path: "/invoices/cancelled",
            title: "İptal Faturalar",
            className: null,
            iconName: "documentRemove"
        },
        {
            id: "link6",
            path: "/invoices/old",
            title: "Eski Faturalar",
            className: null,
            iconName: "cloudDownload"
        },
        {
            id: "link7",
            path: "/invoices/report",
            title: "Muhasebe Raporu",
            className: null,
            iconName: "excel"
        },
    ]

    return (
        <>
        <div className=' flex-start items-center gap-x-4 hidden lg:flex'>
            {links.map((link) => {
                return <LinkButton linkData={link} key={link.id}/>
            })}
            <div className=''></div>
        </div>
        <div className='w-full block lg:hidden relative'>
            <div>
                <button onClick={handleOpen} className='w-full px-2 py-1 rounded-md border border-gray-700 flex justify-between items-center'>
                    <span>{mobileMenuTitle}</span>
                    <span className={isOpenMobileMenu ? 'rotate-180' : ''}><Icon name="chevronDown" size="16" /></span>                    
                </button>
                <div onClick={handleMobileMenuClick} className={'absolute w-full border border-gray-300 bg-white z-10 mt-1 flex-col'+(isOpenMobileMenu ? ' flex' : ' hidden')}>
                    {links.map((link) => {
                        return <MobileLinkButton linkData={link} key={"mobile"+link.id}/>
                    })}
                </div>
            </div>
        </div>
        </>
    )
}

export default Header