import {useEffect, useState} from 'react'
import PreviewModal from 'components/PreviewModal'
import ButtonBigWithAction from 'elements/ButtonBigWithAction'
import GetRequest from 'components/GetRequest'
import PostRequest from 'components/PostRequest'
import LoadingIndicator from 'pages/LoadingIndicator'

const Preview = ({isOpen, isOpenAction, selectedData}) => {
    const [invoice, setInvoice] = useState({status: false, content: null})
    
    useEffect(() => {
        if(selectedData) {
            handlePreview(selectedData.id)        
        } else {
            setInvoice({status: false, content: null})
        }
    }, [selectedData])

    const [loading, setLoading] = useState(false)

    const getErrorMessage = (error) => {
        const err = error.response.data.errors
        if(err) {
            const errorItems = Object.keys(err).map((value, index) => {
                return (
                    <li key={value.toString()}>{err[value][0]}</li>
                )
            })
            const errorMessage = (
                <div className='text-sm text-gray-700 text-bold mb-2 p-1'>
                    <p>{error.response.data.message}</p>   
                    <ul className='list-[circle] list-inside'>
                        {errorItems}
                    </ul>                     
                </div>
            )
            setInvoice({status: false, content: errorMessage})
            setLoading(false)
        } else if(error.response?.data?.ErrorStatus) {
            if(error.response.data.ErrorStatus?.data) {
                const errorMessage = (
                <div className='text-sm text-gray-700 text-bold mb-2 p-1'>
                    <p>{`${error.response.data.ErrorStatus.Message}-${error.response.data.ErrorStatus.data} (${error.response.data.ErrorStatus.Code})`}</p> 
                </div>
                )
                setInvoice({status: false, content: errorMessage})
            } else {
                const errorMessage = (
                <div className='text-sm text-gray-700 text-bold mb-2 p-1'>
                    <p>{`${error.response.data.ErrorStatus.Message} (${error.response.data.ErrorStatus.Code})`}</p> 
                </div>
                )
                setInvoice({status: false, content: errorMessage})
            } 
            
        }  else {
            const errorMessage = (
                <div className='text-sm text-gray-700 text-bold mb-2 p-1'>
                    <p>{`${error.response.statusText} (${error.response.status})`}</p> 
                </div>
            )  
            setInvoice({status: false, content: errorMessage})
        } 
    }

    const handlePreview = (invoiceId) => {
        setLoading(true)
        GetRequest('api/invoices/'+invoiceId+'/preview')
            .then(response => {
                // console.log(response.data)
                setInvoice({status: true, content: response.data})
                setLoading(false)
            })
            .catch(error => {
                getErrorMessage(error)
                setLoading(false)
            })
        
    }

    const downloadPdf = () => {
        var iframe = document.getElementById("display");
        var innerDoc = iframe.contentDocument || iframe.contentWindow.document;
        var qrcodeDiv = innerDoc.getElementById('qrcode')
        var src = qrcodeDiv.getElementsByTagName('img')[0].src
        setLoading(true)
        PostRequest('api/invoices/'+selectedData.id+'/pdf', {qrcode: src}, {responseType: 'blob'})
            .then(response => {
                const temp = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = temp
                link.setAttribute('download', `${selectedData.invoiceNo}.pdf`)
                document.body.appendChild(link)
                link.click()
                setLoading(false) 
            })
            .catch(error => {
                getErrorMessage(error)
                setLoading(false) 
            })
    }

    const downloadXml = () => {
        setLoading(true)
        GetRequest('api/invoices/'+selectedData.id+'/xml', {responseType: 'blob'})
            .then(response => {
                const temp = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = temp
                link.setAttribute('download', `${selectedData.invoiceNo}.xml`)
                document.body.appendChild(link)
                link.click()
                setLoading(false) 
            })
            .catch(error => {
                getErrorMessage(error)
                setLoading(false) 
            })
        
    }

    const downloadZip = () => {
        setLoading(true)
        GetRequest('api/invoices/'+selectedData.id+'/zip', {responseType: 'blob'})
            .then(response => {
                const temp = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = temp
                link.setAttribute('download', `${selectedData.invoiceNo}.zip`)
                document.body.appendChild(link)
                link.click()
                setLoading(false) 
            })
            .catch(error => {
                getErrorMessage(error)
                setLoading(false) 
            })
        
    }

    const actions = invoice.status ? (
        <>
            <ButtonBigWithAction type="button" addClass="text-white bg-gray-700" title="ZİP" onClick={downloadZip}/>
            <ButtonBigWithAction type="button" addClass="text-white bg-gray-700" title="XML" onClick={downloadXml}/>
            <ButtonBigWithAction type="button" addClass="text-white bg-gray-700" title="PDF" onClick={downloadPdf}/>
            <ButtonBigWithAction type="button" addClass="text-white bg-gray-700" title="Yazdır" onClick={() => document.querySelector("#display").contentWindow.print()}/>
        </>
    ) : (
        <></>
    )
    return (
        <PreviewModal isOpen={isOpen} 
                    isOpenAction={isOpenAction} 
                    id="invoicePreviewModal" 
                    title="Fatura Görüntüleme" 
                    cancelButton={true} 
                    actions={actions}
                    actionsForClear={[]}
                    clearedData={{status: false, content: null}}>
            <div className=''>                
                {invoice.status ? 
                <iframe id="display" srcDoc={invoice.content} style={{width: '100%', height:'70vh', paddingLeft:'10px'}}></iframe>
                : invoice.content
                }
            </div>
            {loading && <LoadingIndicator />}
        </PreviewModal>
    );
};

export default Preview;
