import {useState, useEffect} from 'react';
import {useForm} from "react-hook-form"
import FormModal from 'components/FormModal';
import ButtonBigWithAction from 'elements/ButtonBigWithAction';
import InputWithLabel from 'elements/InputWithLabel';
import SelectWithLabel from 'elements/SelectWithLabel';
import InputSplit from 'components/InputSplit';
import PostRequest from 'components/PostRequest'
import GetRequest from 'components/GetRequest';
import LoadingIndicator from 'pages/LoadingIndicator';
import countries from 'data/Countries';

const Approve = ({isOpen, isOpenAction, updateStatus, updatedAction, selectedData}) => {
    const {register, handleSubmit, reset, setValue, formState: { errors }, getValues} = useForm({
        defaultValues: {
            code: null,
            oid: null
        }
    })

    const actions = (
        <>
            <ButtonBigWithAction type="submit" addClass="text-white bg-emerald-500" title="Onayla" />
        </>
    )

    const inputs = [
        {
            key: "approveInput1",
            type: "text",
            name: "code",
            placeholder: "Zorunlu Alan",
            label: "Onay Kodu *",
            register: register,
            validator: {
                required: "Bu alan zorunludur."
            },
            error: errors?.code?.message 
        },
    ]

    const [approveStatus, setApproveStatus] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if(selectedData !== null && Object.keys(selectedData).length > 0) {
            setLoading(true)
            GetRequest('api/invoices/'+selectedData.id+'/oid')
                .then(response => {
                    const successMessage = (
                        <div className='text-sm text-green-100 bg-green-600 text-bold mb-2 p-1'>
                            <p>Cep telefonunuza gelen onay kodunu aşağıdaki alana giriniz.</p> 
                        </div>
                    )
                    setApproveStatus(successMessage)
                    setValue('oid', response.data)
                    setLoading(false)                    
                })
                .catch(error => {
                    const err = error.response.data.errors
                    if(err) {
                        const errorItems = Object.keys(err).map((value, index) => {
                            return (
                                <li key={value.toString()}>{err[value][0]}</li>
                            )
                        })
                        const errorMessage = (
                            <div className='text-sm text-red-600'>
                                <p>{error.response.data.message}</p>   
                                <ul className='list-[circle] list-inside'>
                                    {errorItems}
                                </ul>                     
                            </div>
                        )
                        setValue('oid', null)
                        setApproveStatus(errorMessage)
                    } else if(error.response?.data?.ErrorStatus) {
                        if(error.response.data.ErrorStatus?.data) {
                        const errorMessage = (
                            <div className='text-sm text-gray-700 text-bold mb-2 p-1'>
                                <p>{`${error.response.data.ErrorStatus.Message}-${error.response.data.ErrorStatus.data} (${error.response.data.ErrorStatus.Code})`}</p> 
                            </div>
                        )
                        setValue('oid', null)
                        setApproveStatus(errorMessage)
                        } else {
                        const errorMessage = (
                            <div className='text-sm text-gray-700 text-bold mb-2 p-1'>
                                <p>{`${error.response.data.ErrorStatus.Message} (${error.response.data.ErrorStatus.Code})`}</p> 
                            </div>
                        )
                        setValue('oid', null)
                        setApproveStatus(errorMessage)
                        } 
                        
                    }  else {
                    const errorMessage = (
                        <div className='text-sm text-gray-700 text-bold mb-2 p-1'>
                            <p>{`${error.response.statusText} (${error.response.status})`}</p> 
                        </div>
                    )  
                    setValue('oid', null)
                    setApproveStatus(errorMessage)
                    }
                    setLoading(false)
                })
        }
        
    }, [selectedData])

    const handleForm = (data) => {
        setLoading(true)
        PostRequest('api/invoices/'+selectedData.id+'/approve', data)
            .then(response => {
                const successMessage = (
                    <div className='text-sm text-green-100 bg-green-600 text-bold mb-2 p-1'>
                        <p>Fatura başarıyla onaylandı.</p> 
                    </div>
                )
                setApproveStatus(successMessage)
                setLoading(false)
                reset()
                updatedAction(!updateStatus)
            })
            .catch(error => {
                const err = error.response.data.errors
                    if(err) {
                        const errorItems = Object.keys(err).map((value, index) => {
                            return (
                                <li key={value.toString()}>{err[value][0]}</li>
                            )
                        })
                        const errorMessage = (
                            <div className='text-sm text-red-600'>
                                <p>{error.response.data.message}</p>   
                                <ul className='list-[circle] list-inside'>
                                    {errorItems}
                                </ul>                     
                            </div>
                        )
                        setValue('oid', null)
                        setApproveStatus(errorMessage)
                    } else if(error.response?.data?.ErrorStatus) {
                        if(error.response.data.ErrorStatus?.data) {
                        const errorMessage = (
                            <div className='text-sm text-gray-700 text-bold mb-2 p-1'>
                                <p>{`${error.response.data.ErrorStatus.Message}-${error.response.data.ErrorStatus.data} (${error.response.data.ErrorStatus.Code})`}</p> 
                            </div>
                        )
                        setValue('oid', null)
                        setApproveStatus(errorMessage)
                        } else {
                        const errorMessage = (
                            <div className='text-sm text-gray-700 text-bold mb-2 p-1'>
                                <p>{`${error.response.data.ErrorStatus.Message} (${error.response.data.ErrorStatus.Code})`}</p> 
                            </div>
                        )
                        setValue('oid', null)
                        setApproveStatus(errorMessage)
                        } 
                        
                    }  else {
                    const errorMessage = (
                        <div className='text-sm text-gray-700 text-bold mb-2 p-1'>
                            <p>{`${error.response.statusText} (${error.response.status})`}</p> 
                        </div>
                    )  
                    setValue('oid', null)
                    setApproveStatus(errorMessage)
                    }
                    setLoading(false)
            })
    }
    return (
        <FormModal isOpen={isOpen} 
                    isOpenAction={isOpenAction} 
                    id="invoiceApproveModal" 
                    title="Faturayı Onayla" 
                    cancelButton={true} 
                    actions={actions}
                    submitAction={handleSubmit(handleForm)}
                    actionsForClear={[setApproveStatus]}>
            <div>
                {approveStatus}
            </div>
            <div className='lg:grid lg:grid-cols-1 lg:gap-x-4'>                
                <InputWithLabel {...inputs[0]} addClass="mt-2"/>
            </div>
            {loading && <LoadingIndicator />}
        </FormModal>
    );
};

export default Approve;
