import React from 'react'
import { Icon } from 'elements/Icons';

const TableActions = ({rowId, actions, data}) => {

    const handleEdit = (e) => {
        actions.edit.action(true)
        actions.editted.action(data)
    }
    const handleDelete = (e) => {
        actions.delete.action(true)
        actions.deleted.action(data)
    }

    return (
        <div className='w-full grid grid-cols-2 gap-x-3'>
            <button type="button" onClick={handleEdit} className='inline-flex justify-center items-center gap-x-2 px-2 py-1 text-white bg-emerald-700' >
                <Icon name="edit" size="16"/>
                <span>Düzenle</span>
            </button>
            <button type="button" onClick={handleDelete} className='inline-flex justify-center items-center gap-x-2 px-2 py-1 text-white bg-red-700' >
                <Icon name="delete" size="16"/>
                <span>Sil</span>
            </button>
        </div>
    )
};

export default TableActions
