export default [
    {
        key: 'TRY',
        value: 'TRY'
    },
    {
        key: 'USD',
        value: 'USD'
    },
    {
        key: 'EUR',
        value: 'EUR'
    },
    {
        key: 'GBP',
        value: 'GBP'
    },
]