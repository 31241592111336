export default [
    {
        key: '0',
        value: '0'
    },
    {
        key: '1',
        value: '1'
    },
    {
        key: '10',
        value: '10'
    },
    {
        key: '20',
        value: '20'
    }
]