import {useState} from 'react';
import {useForm} from "react-hook-form"
import AuthLayout from "layouts/AuthLayout"
import InputFloatingLabelWithValidaiton from "elements/InputFloatingLabelWithValidaiton"
import ButtonBig from "elements/ButtonBig"
import PostRequest from 'components/PostRequest'
import LoadingIndicator from './LoadingIndicator';

const ForgotPassword = () => {

    const [sendStatus, setSendStatus] = useState(null)
    const {register, handleSubmit, formState: { errors }} = useForm()
    const [loading, setLoading] = useState(false)

    const handleLForgotPassword = (data) => {
        setLoading(true)
        PostRequest('forgot-password', data)
            .then(response => {
                const status = (
                    <>
                    <p className='text-sm font-bold text-green-600'>{response.data.status}</p>
                    <p className='text-sm font-bold text-red-600'>Lütfen e-posta kutunuzun gereksiz/spam klasörünü kontrol ediniz.</p>
                    </>
                    )
                setSendStatus(status)
                setLoading(false)
            })
            .catch(error => {
                const err = error.response.data.errors
                const errorItems = Object.keys(err).map((value, index) => {
                    return (
                        <li key={value.toString()}>{err[value][0]}</li>
                    )
                })
                const errorMessage = (
                    <div className='text-sm text-red-600'>
                        <p>{error.response.data.message}</p>   
                        <ul className='list-[circle] list-inside'>
                            {errorItems}
                        </ul>                     
                    </div>
                )
                setSendStatus(errorMessage)
                setLoading(false)
            })
    }

    return (
        <AuthLayout>
            <div className='flex flex-col justify-between h-full py-10 relative'>
                <div>
                    <p className='text-gray-700 text-sm'>Parolanızı mı unuttunuz? Sorun değil. Sadece bize e-posta adresinizi bildirin ve size yeni bir tane seçmenize olanak verecek bir şifre sıfırlama bağlantısı gönderelim. </p>
                    {sendStatus}
                </div> 
                <div>
                    <form className="w-full flex flex-col" onSubmit={handleSubmit(handleLForgotPassword)}>
                        <InputFloatingLabelWithValidaiton 
                                name="email" 
                                register={register} 
                                label="E-Posta" 
                                type="text" 
                                validator={{
                                    required: "Bu alan zorunludur.", 
                                    minLength: {value: 4, 
                                                message: "En az 4 karakter olmalı"}, 
                                    maxLength: {value: 255, 
                                                message:"En fazla 255 karakter olmalı"},
                                    pattern: {value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                message: "Geçerli bir e-posta adresi olmalı"}
                                }} 
                                
                            />
                            <p className="text-sm text-red-600">{errors?.email?.message}</p>
                            <ButtonBig type="submit" title="Şifre Sıfırlama Linkini Gönder" addClass="bg-sky-600 text-white"/>
                    </form>
                </div>
                {loading && <LoadingIndicator />}
            </div>
            
        </AuthLayout>
      );
};

export default ForgotPassword;
