import axios from 'axios'

const PutRequest = (url, data) => {
    const  baseUrl = 'https://earsiv-api.faveraportal.com'
    axios.defaults.withCredentials = true;
    const request = axios.put(`${baseUrl}/${url}`, data)

  return request;
};

export default PutRequest;
