import React, {Component} from "react"

class ButtonBig extends Component {
    render() {
        return (
            <button type={this.props.type} className={`p-2 mt-4 ${this.props?.addClass ?? ''}`}>{this.props.title}</button>
        );
    }
}

export default ButtonBig