import {useEffect, useState} from 'react';
import ActionModal from 'components/ActionModal';
import ButtonBigWithAction from 'elements/ButtonBigWithAction';
import InputWithLabel from 'elements/InputWithLabel';
import DeleteRequest from 'components/DeleteRequest'
import LoadingIndicator from 'pages/LoadingIndicator';

const Delete = ({isOpen, isOpenAction, updateStatus, updatedAction, selectedData}) => {
    const [customer, setCustomer] = useState({name: null, id: null})
    useEffect(() => {
        if(selectedData) {
            setCustomer({name: selectedData.name, id: selectedData.id})           
        } else {
            setCustomer({name: null, id: null})
        }
    }, [selectedData])

    const [deleteStatus, setDeleteStatus] = useState(null)
    const [loading, setLoading] = useState(false)

    const handleDelete = (data) => {
        setLoading(true)
        DeleteRequest('api/customers/'+customer.id)
            .then(response => {
                const successMessage = (
                    <div className='text-sm text-green-100 bg-green-600 text-bold mb-2 p-1'>
                        <p>Müşteri başarıyla silindi.</p> 
                    </div>
                )
                setDeleteStatus(successMessage)
                setLoading(false)
                updatedAction(!updateStatus)
            })
            .catch(error => {
                const err = error.response.data.errors
                if(err) {
                    const errorItems = Object.keys(err).map((value, index) => {
                        return (
                            <li key={value.toString()}>{err[value][0]}</li>
                        )
                    })
                    const errorMessage = (
                        <div className='text-sm text-red-600'>
                            <p>{error.response.data.message}</p>   
                            <ul className='list-[circle] list-inside'>
                                {errorItems}
                            </ul>                     
                        </div>
                    )

                    setDeleteStatus(errorMessage)
                } else {
                    const errorMessage = (
                        <div className='text-sm text-red-100 bg-red-600 text-bold mb-2 p-1'>
                            <p>{`${error.response.statusText} (${error.response.status})`}</p> 
                        </div>
                    )   
                    
                    setDeleteStatus(errorMessage)
                }                
                
                setLoading(false)
            })
    }

    const actions = (
        <>
            {!deleteStatus && <ButtonBigWithAction type="button" addClass="text-white bg-emerald-500" title="Sil" onClick={handleDelete}/>}
        </>
    )

    return (
        <ActionModal isOpen={isOpen} 
                    isOpenAction={isOpenAction} 
                    id="customerDeleteModal" 
                    title="Müşteri Bilgilerini Sil" 
                    cancelButton={true} 
                    actions={actions}
                    actionsForClear={[setDeleteStatus]}>
            <div>
                {deleteStatus}
            </div>
            <div className=''>                
                {!deleteStatus && <p className='text-md text-gray-700'><span className='font-semibold underline'>{customer.name}</span> isimli müşteriyi silmek istediğinize emin misiniz?</p>}
            </div>
            {loading && <LoadingIndicator />}
        </ActionModal>
    );
};

export default Delete;
