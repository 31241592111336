import React from 'react'
import trendyol from "assets/img/trendyol.png"

const index = () => {
  return (
    <div className='w-full flex items-center justify-center'>
        <img src={trendyol} alt="trendyol" className='w-full max-w-[600px] max-h-[600px]' />
    </div>
  )
}

export default index