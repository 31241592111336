import React from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from 'components/Navbar';

const AppLayout = () => {
  return (<div className='flex flex-col flex-1 w-full' >
      <Navbar />
      <div className='h-full overflow-y-auto'>
        <Outlet />
      </div>
  </div>);
};

export default AppLayout;
