import {useState, useEffect} from 'react'
import {useForm} from "react-hook-form"
import FormModal from 'components/FormModal'
import ButtonBigWithAction from 'elements/ButtonBigWithAction'
import InputWithLabel from 'elements/InputWithLabel'
import PostRequest from 'components/PostRequest'
import LoadingIndicator from 'pages/LoadingIndicator'
import FormatDateFromMysql from 'components/FormatDateFromMysql'

const Email = ({isOpen, isOpenAction, updateStatus, updatedAction, selectedData}) => {
    const {register, handleSubmit, reset, setValue, formState: { errors }} = useForm({
        defaultValues: {
            email: null,
        }
    })

    const actions = (
        <>
            <ButtonBigWithAction type="submit" addClass="text-white bg-emerald-500" title="Gönder" />
        </>
    )

    useEffect(() => {
        if(selectedData && Object.keys(selectedData).length > 0) {
            console.log(selectedData)
            setValue('email', selectedData.customerEmail)
        }
    }, [selectedData])

    const inputs = [
        {
            key: "emailInput1",
            type: "email",
            name: "email",
            placeholder: "Zorunlu Alan",
            label: "Faturanın Gönderileceği E-Posta Adresi *",
            register: register,
            validator: {
                required: "Bu alan zorunludur.",
                minLength: {value: 4, 
                    message: "En az 4 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"},
                pattern: {value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Geçerli bir e-posta adresi olmalı"}
            },
            error: errors?.email?.message 
        },
    ]

    const [emailStatus, setEmailStatus] = useState(null)
    const [loading, setLoading] = useState(false)

    const handleForm = (data) => {
        console.log(data)
        setLoading(true)
        PostRequest('api/invoices/'+selectedData.id+'/sendMail', data)
            .then(response => {
                
                const successMessage = (
                    <div className='text-sm text-green-100 bg-green-600 text-bold mb-2 p-1'>
                        <p>E-Posta başarıyla gönderildi.</p> 
                    </div>
                )
                setEmailStatus(successMessage)
                updatedAction(!updateStatus)
                setLoading(false)
            })
            .catch(error => {
                const err = error.response.data.errors
                if(err) {
                    const errorItems = Object.keys(err).map((value, index) => {
                        return (
                            <li key={value.toString()}>{err[value][0]}</li>
                        )
                    })
                    const errorMessage = (
                        <div className='text-sm text-red-600'>
                            <p>{error.response.data.message}</p>   
                            <ul className='list-[circle] list-inside'>
                                {errorItems}
                            </ul>                     
                        </div>
                    )
                    setEmailStatus(errorMessage)
                } else {
                    const errorMessage = (
                        <div className='text-sm text-red-100 bg-red-600 text-bold mb-2 p-1'>
                            <p>{`${error.response.statusText} (${error.response.status})`}</p> 
                        </div>
                    )   
                    
                    setEmailStatus(errorMessage)
                }  
                setLoading(false)
            })
    }

    return (
        <FormModal isOpen={isOpen} 
                    isOpenAction={isOpenAction} 
                    id="invoiceEmailModal" 
                    title="Faturayı E-Posta İle Gönder" 
                    cancelButton={true} 
                    actions={actions}
                    submitAction={handleSubmit(handleForm)}
                    actionsForClear={[setEmailStatus]}>
            <div className="w-full mb-3">
                <h3 className="pb-1 mb-1 border-b border-gray-500">Gönderilen E-Postalar</h3>
                <table className='w-full text-xs'>
                    <thead className='text-gray-100'>
                        <tr className='bg-gray-700'>
                            <th className='px-2 py-2'>E-Posta Adresi</th>
                            <th className='px-2 py-2'>Gönderme Tarihi</th>
                        </tr>
                    </thead>
                    <tbody>
                        {selectedData && Object.keys(selectedData.emails).length > 0 ? 
                        selectedData.emails.map((email) => {
                            return (<tr className='border border-x-0 border-t-0 border-gray-500 even:bg-gray-200' key={email.id}>
                                <td className='px-2 py-1'>{email.email}</td>
                                <td className='px-2 py-1'>{FormatDateFromMysql(email.created_at)}</td>
                            </tr>)
                        })
                        : 
                        <tr><td>Gösterilecek veri bulunamadı.</td></tr>
                        }   
                    </tbody>          
                </table>
            </div>
            <div className='w-full my-6 py-1 border-b border-gray-300'></div>
            <div>
                {emailStatus}
            </div>
            <div className='lg:grid lg:grid-cols-1 lg:gap-x-4'>                
                <InputWithLabel {...inputs[0]} addClass="mt-2"/>
            </div>            
            {loading && <LoadingIndicator />}
        </FormModal>
    );
};

export default Email;
