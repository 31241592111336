import {useState} from 'react';
import {useForm} from "react-hook-form"
import { useParams, useSearchParams } from 'react-router-dom';
import AuthLayout from "layouts/AuthLayout"
import InputFloatingLabelWithValidaiton from "elements/InputFloatingLabelWithValidaiton"
import ButtonBig from "elements/ButtonBig"
import LinkButtonBig from "elements/LinkButtonBig"
import PostRequest from 'components/PostRequest'
import LoadingIndicator from './LoadingIndicator';

const ResetPassword = () => {
    const params = useParams()
    const [searchParams, setSearchParams] = useSearchParams()
    const [resetStatus, setResetStatus] = useState(null)
    const {register, handleSubmit, formState: { errors }, getValues} = useForm()
    const [loading, setLoading] = useState(false)
    const [resetSuccess, setResetSuccess] = useState(false)

    const handleLForgotPassword = (data) => {
        data.token = params.token
        data.email = searchParams.get("email")
        console.log(data)
        setLoading(true)
        PostRequest('reset-password', data)
            .then(response => {
                const status = (
                    <>
                    <p className='text-sm font-bold text-green-600'>{response.data.status}</p>
                    </>
                    )
                setResetStatus(status)
                response.status === 200 ? setResetSuccess(true) : setResetSuccess(false)
                setLoading(false)
            })
            .catch(error => {
                const err = error.response.data.errors
                const errorItems = Object.keys(err).map((value, index) => {
                    return (
                        <li key={value.toString()}>{err[value][0]}</li>
                    )
                })
                const errorMessage = (
                    <div className='text-sm text-red-600'>
                        <p>{error.response.data.message}</p>   
                        <ul className='list-[circle] list-inside'>
                            {errorItems}
                        </ul>                     
                    </div>
                )
                setResetStatus(errorMessage)
                setLoading(false)
            })
    }

    return (
        <AuthLayout>
            <div className='flex flex-col justify-between h-full py-10 relative'>
                <div>
                    {resetStatus}
                </div> 
                <div>
                    {resetSuccess === false ? 
                    <form className="w-full flex flex-col" onSubmit={handleSubmit(handleLForgotPassword)}>
                    <InputFloatingLabelWithValidaiton 
                        name="password" 
                        register={register} 
                        label="Şifre" 
                        type="password" 
                        validator={{
                            required: "Bu alan zorunludur.", 
                            minLength: {value: 8, 
                                        message: "En az 8 karakter olmalı"}, 
                            maxLength: {value: 24, 
                                        message:"En fazla 24 karakter olmalı"}
                        }} 
                        
                    />
                    <p className="text-sm text-red-600">{errors?.password?.message}</p>
                    <InputFloatingLabelWithValidaiton 
                        name="password_confirmation" 
                        register={register} 
                        label="Şifre Tekrar" 
                        type="password" 
                        validator={{
                            validate: (value) =>
                                value === getValues("password")
                        }} 
                        
                    />
                    <p className="text-sm text-red-600">{errors.password_confirmation && errors.password_confirmation.type === "validate" && "Girdiğiniz şifreler eşleşmiyor."}</p>
                    <ButtonBig type="submit" title="Şifreyi Sıfırla" addClass="bg-red-600 text-white"/>
                    </form>
                    : 
                    <div> 
                        <LinkButtonBig destination="/login" title="Giriş Yap" addClass="bg-emerald-600 text-white"/>
                    </div>
                    }
                </div>
                {loading && <LoadingIndicator />}
            </div>
        </AuthLayout>
    );
};

export default ResetPassword;
