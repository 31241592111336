import React from 'react'
import {Link, useMatch, useResolvedPath} from 'react-router-dom'

const NavigationLinkMobile = ({destination, title}) => {
    let resolved = useResolvedPath(destination);
    let match = useMatch({ path: resolved.pathname, end: true });

    const className = match  
                    ? 'bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium'
                    : 'text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium'

    return (
        <Link to={destination} className={className}>{title}</Link>
    )
}

export default NavigationLinkMobile
