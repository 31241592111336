import React from 'react'
import { useSanctum } from 'react-sanctum'

const CreditCard = () => {

    const { user } = useSanctum();

    const paynetSetting = {
        class: "paynet-button",
        src: "https://pj.paynet.com.tr/public/js/paynet-custom.js",
        dataForm: "#checkout-form",
        dataKey: "pbk_AJhk9pkZnrPG9SGuSH-xAFUVtHD0",
        dataAmount: "600,00",
        dataAgent: "72252",
        dataRatioCode: "ZQX56XJQ",
        dataAddCommissionAmount: "true",
        dataDescription: `${user.name} - e-Arşiv Yıllık Plan`
    }

    React.useEffect(() => {
        const script = document.createElement('script')

        script.src = paynetSetting.src
        script.async = true
        script.onload = () => scriptLoaded()
        script.setAttribute('class', paynetSetting.class)
        script.setAttribute('data-form', paynetSetting.dataForm)
        script.setAttribute('data-key', paynetSetting.dataKey)
        script.setAttribute('data-amount', paynetSetting.dataAmount)
        script.setAttribute('data-agent', paynetSetting.dataAgent)
        script.setAttribute('data-ratio_code', paynetSetting.dataRatioCode)
        script.setAttribute('data-add_commission_amount', paynetSetting.dataAddCommissionAmount)
        script.setAttribute('data-description', paynetSetting.dataDescription)

        const addScript = document.body.appendChild(script)

        return () => {
            document.body.removeChild(script)
        }
    }, [])

    const showError = (code, message) => {
        var $errorBox = document.createElement("P")
        $errorBox.innerText = message
        document.getElementById("errors").appendChild($errorBox)
    }

    const clearError = () => {
        document.getElementById("errors").innerHTML = ''
    }

    const scriptLoaded = () => {
        const Paynet = window.Paynet
        Paynet.events.validationError(function (e) {
            showError(e.code, e.message)
        })
        Paynet.events.onAuthentication(function (c) {
            if (!c.ok) {
                showError(c.code, c.message)
            }
        })
        Paynet.events.onCreateToken(function (c) {
            if (!c.ok) {
                showError(c.code, c.message)
            } else {
            console.log(c)
            }
        })
        Paynet.events.onPaymentClick(function () {
            clearError()
        })
        Paynet.events.onCheckBin(function (d) {
            if (d && d.ok){
                console.log(d);
                const tableDiv = document.getElementById('installments')
                tableDiv.innerHTML = ''
                tableDiv.innerHTML = '<img class="w-16" src="'+d.bank.logoUrl+'">'
                d.bank.installments.sort(function (current, next) {
                    if (current.instalment > next.instalment) return 1
                    if (current.instalment < next.instalment) return -1
                    return 0
                })
                var installments = d.bank.installments
                var installmentTable = document.createElement('table')
                installmentTable.setAttribute('id', 'installmentTable')
                tableDiv.appendChild(installmentTable)
                for (var i = 0; i < installments.length; i++)
                    {
                    var rowCnt = installmentTable.rows.length
                    var row = installmentTable.insertRow(rowCnt)
                    row.classList.add("border-b", "border-gray-500")
                    var cell1 = row.insertCell(0)
                    var cell2 = row.insertCell(1)
                    var cell3 = row.insertCell(2)
                    cell1.innerHTML = "<input type='radio' name='__installment' data-key='"+installments[i].instalment_key+"'>"
                    cell2.innerHTML = installments[i].desc
                    cell3.innerHTML = installments[i].instalment_amount
                    cell1.classList.add("px-2", "py-1", "text-xs")
                    cell2.classList.add("px-2", "py-1", "text-xs")
                    cell3.classList.add("px-2", "py-1", "text-xs")
                    }
                if (d.tdsState == 'required') {
                    document.getElementById("tds").setAttribute("checked", "checked")
                    document.getElementById("tds").setAttribute("disabled", "disabled")
                    document.getElementById("tds").parentNode.classList.remove("cursor-pointer")
                    document.getElementById("tds").parentNode.classList.add("cursor-not-allowed")
                } else if (d.tdsState == 'optional') {
                    document.getElementById("tds").removeAttribute("checked")
                    document.getElementById("tds").removeAttribute("disabled")
                    document.getElementById("tds").parentNode.classList.remove("cursor-not-allowed")
                    document.getElementById("tds").parentNode.classList.add("cursor-pointer")
                }
                if (document.querySelector('input[name="__installment"]')) {
                    var installments_radio = document.querySelectorAll('input[name="__installment"]')
                    installments_radio.forEach((elem) => {
                        elem.addEventListener("click", function(event) {
                            var itemKey = event.target.getAttribute('data-key')
                            document.getElementById('installmentKey').value = itemKey
                        });
                    });
                    installments_radio[0].checked = "checked"
                    document.getElementById('installmentKey').value = installments_radio[0].getAttribute('data-key')
                }
            } else {
                console.log(d)
            }
        })
    }

    return (
        <div className="" x-show="showcard" >
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                <div className="">
                    <div id="errors" className="text-xs text-red-600 font-semibold">

                    </div>
                    <form action="https://earsiv-api.faveraportal.com/purchases/creditcard" method="post" name="checkout-form" id="checkout-form">
                        <div className="relative text-gray-500 focus-within:text-blue-600">
                            <input disabled value="600 TL" type="text" name="amount" maxLength="16" id="amount" data-paynet="amount" className="block w-full pl-10 mt-1 cursor-not-allowed text-sm text-gray-600 focus:border-blue-400 focus:outline-none focus:shadow-outline form-input" placeholder="Tutar" />
                            <div className="absolute inset-y-0 flex items-center ml-3 pointer-events-none">
                            <svg className="w-5 h-5" aria-hidden="true" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                                <path d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"></path>
                            </svg>
                            </div>
                        </div>
                        <div className="relative text-gray-500 focus-within:text-blue-600">
                            <input type="text" name="cardHolderName" id="cardHolderName" data-paynet="holderName" className="block w-full pl-10 mt-1 text-sm text-gray-800 focus:border-blue-400 focus:outline-none focus:shadow-outline form-input" placeholder="Kart Üzerindeki İsim" required />
                            <div className="absolute inset-y-0 flex items-center ml-3 pointer-events-none">
                            <svg className="w-5 h-5" aria-hidden="true" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                                <path d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path>
                            </svg>
                            </div>
                        </div>
                        <div className="relative text-gray-500 focus-within:text-blue-600">
                            <input type="text" pattern="[0-9]*" name="cardNumber" maxLength="16" id="cardNumber" data-paynet="number" className="block w-full pl-10 mt-1 text-sm text-gray-800 focus:border-blue-400 focus:outline-none focus:shadow-outline form-input" placeholder="Kart No" required />
                            <div className="absolute inset-y-0 flex items-center ml-3 pointer-events-none">
                            <svg className="w-5 h-5" aria-hidden="true" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                                <path d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"></path>
                            </svg>
                            </div>
                        </div>
                        <div className="grid grid-cols-3 gap-4">
                            <div className="col-span-2 grid grid-cols-2 gap-3">
                                <div className="">
                                    <select name="expMonth" id="expMonth" data-paynet="exp-month" className="block w-full mt-1 text-gray-800 text-sm form-select focus:border-blue-400 focus:outline-none focus:shadow-outline" required>
                                    <option value="">Ay</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                    </select>
                                </div>
                                <div className="">
                                    <select name="expYear" id="expYear" data-paynet="exp-year" className="block w-full mt-1 text-gray-800 text-sm form-select focus:border-blue-400 focus:outline-none focus:shadow-outline" required>
                                    <option value="">Yıl</option>
                                    <option value="2021">2021</option>
                                    <option value="2022">2022</option>
                                    <option value="2023">2023</option>
                                    <option value="2024">2024</option>
                                    <option value="2025">2025</option>
                                    <option value="2026">2026</option>
                                    <option value="2027">2027</option>
                                    <option value="2028">2028</option>
                                    </select>
                                </div>
                            </div>
                            <div className="relative text-gray-500 focus-within:text-blue-600">
                                <input type="text" maxLength="4" name="cvv" id="cvv" data-paynet="cvv" className="block w-full pl-10 mt-1 text-sm text-gray-800 focus:border-blue-400 focus:outline-none focus:shadow-outline form-input" placeholder="CVC" required />
                                <div className="absolute inset-y-0 flex items-center ml-3 pointer-events-none">
                                    <svg className="w-5 h-5" aria-hidden="true" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                                    <path d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z"></path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className="mt-2">
                            <label className="flex items-center cursor-pointer" htmlFor="tds">
                                <input type="checkbox" name="tds" id="tds" data-paynet="do3D" defaultChecked="checked" className="form-checkbox border border-gray-600 focus:shadow-none focus:outline-none"/>
                                <span className="ml-2 text-sm text-gray-700 hover:text-gray-900">3D Ödeme</span>
                            </label>
                        </div>
                        <input type="hidden" name="installmentKey" id="installmentKey" data-paynet="installmentKey" value="" />
                        <input type="hidden" name="user_id" id="user_id" value={user.id} />
                        <div className="">
                            <button type="submit" data-paynet="submit" className="w-full text-center px-4 py-2 bg-emerald-600 border border-transparent text-xs text-emerald-50 uppercase tracking-widest hover:bg-green-600 active:bg-green-900 focus:outline-none focus:border-green-900 focus:shadow-outline-green disabled:opacity-25 transition ease-in-out duration-150 my-1 capitalize">Öde</button>
                        </div>
                    </form>
                </div>
                <div id="installments" className="flex flex-col justify-center items-center">

                </div>
            </div>
        </div>
    )
}

export default CreditCard