import React from 'react'
import { Icon } from './Icons'
import {RandId} from 'components/RandId'

const SelectWithSearchSmall = ({title, items, selected, selectedChange}) => {

    const [isOpenMenu, setIsOpenMenu] = React.useState(false)

    const [itemList, setItemList] = React.useState([])

    const [selectedItem, setSelectedItem] = React.useState(null)

    const [titleSpanId, setTitleSpanId] = React.useState(RandId('title_'))

    React.useEffect(() => {
        setItemList(items)
    }, [items])

    const Item = ({value, name}) => {
        const match = selectedItem === value
        const className = match  
                        ? 'w-full px-2 py-1 text-left bg-gray-200 text-gray-800 font-semibold cursor-pointer text-sm hover:bg-gray-200'
                        : 'w-full px-2 py-1 text-left text-gray-700 cursor-pointer text-sm hover:bg-gray-200'
        
        return <li className={className} value={value} key={value} onClick={handleItemClick}>{name}</li>
    }

    const handleMobileMenuClick = (e) => {
        setIsOpenMenu(false)
    }

    const handleOpen = (e) => {
        e.preventDefault()
        setIsOpenMenu(!isOpenMenu)
    }

    const filterResult= (e) => {
        var result = items.filter(value => {
            return value.name.toLowerCase().includes(e.target.value.toLowerCase())
        })
        setItemList(result)
    }

    const handleItemClick = (e) => {
        setIsOpenMenu(false)
        setSelectedItem(e.target.value)
        selectedChange(e.target.value)  
    } 

    React.useEffect(() => {
        if(selectedItem === null || selectedItem === 0) {
            document.getElementById(titleSpanId).innerText = title
        } else {
            var selectedLabel = items.filter(value => {return value.id == selectedItem})
            document.getElementById(titleSpanId).innerText = selectedLabel[0].name
        }
    }, [selectedItem])

    

    return (
        <div className='lg:max-w-[400px] w-full block relative'>
            <button onClick={handleOpen} type="button" className='w-full rounded-sm px-1 py-1 text-sm border border-gray-400 flex justify-between items-center'>
                <span id={titleSpanId}>{title}</span>
                <span className={isOpenMenu ? 'rotate-180' : ''}><Icon name="chevronDown" size="16" /></span>
            </button>
            <div className={'absolute w-full max-h-[300px] overflow-y-auto rounded shadow border border-gray-300 bg-white z-10 mt-1 flex-col'+(isOpenMenu ? ' flex' : ' hidden')}>
                <input onChange={filterResult} type="search" name="search" placeholder='Ara...' className='form-input rounded-sm mx-2 mt-2 px-1 py-1 text-sm border border-gray-400' />
                <ul>
                <Item name="Seçiniz" value={0} key={0}/>
                {itemList.length ? itemList.map((item, index) => {
                    return <Item name={item.name} value={item.id} key={index}/>
                })
                : <li className='w-full px-2 py-1 text-left text-red-700 cursor-pointer text-sm'> Arama sonucu bulunamadı</li>}
                </ul>
            </div>
        </div>
    )
}

export default SelectWithSearchSmall 