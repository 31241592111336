import React, {Component} from "react"

class InputFloatingLabel extends Component {
    render() {
        return (
            <div className="relative z-0 w-full mb-6">
            <input type={this.props.type} ref={this.props.referanceProp} name={this.props.name} placeholder=" " required={this.props?.required === true ? 'required' : ''} className="pb-1 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-sky-500 border-sky-300" />
            <label htmlFor={this.props.name} className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500 mb-3">{this.props.label}</label>
            </div>
        );
    }
}

export default InputFloatingLabel