import React from 'react'
import { useSanctum } from "react-sanctum";
import ButtonBigWithAction from 'elements/ButtonBigWithAction';
import EditInvoiceInfo from './EditInvoiceInfo';

const InvoiceInfo = () => {
    const { user, setUser } = useSanctum();
    const [isOpenInvoiceEdit, setIsOpenInvoiceEdit] = React.useState(false)
    const [updatedInvoiceInfo, setUpdatedInvoiceInfo] = React.useState(false)
    
    const handleClick = () => {
        setIsOpenInvoiceEdit(true)
    }
    return (
        <>
            <div className='py-2 border-b-2 border-gray-400 flex items-center justify-between'>
                <p className='text-gray-800 text-xl'>Fatura Bilgileri</p>
            </div>  
            <div className='mt-4'>
                <table className='w-full text-xs'>
                    <thead className='text-gray-100'>
                        <tr className='bg-gray-700'>
                            <th className='px-2 py-2 hidden lg:table-cell'>Şirket/Kişi Adı</th>
                            <th className='px-2 py-2 hidden lg:table-cell'>Vergi No</th>
                            <th className='px-2 py-2 hidden lg:table-cell'>Vergi Dairesi</th>
                            <th className='px-2 py-2 hidden lg:table-cell'>Şirket Adresi</th>
                            <th className='px-2 py-2 hidden lg:table-cell'>Güncelle</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className='border lg:border-x-0 lg:border-t-0 border-gray-500 even:bg-gray-200 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-4 lg:mb-0 shadow-lg lg:shadow-none'>
                            <td className='px-2 py-1 w-full lg:w-auto lg:table-cell text-left'>
                                <span className="lg:hidden font-semibold">Şirket/Kişi Adı: </span>
                                <span>{user.company}</span>
                            </td>
                            <td className='px-2 py-1 w-full lg:w-auto lg:table-cell text-left lg:text-center'>
                                <span className="lg:hidden font-semibold">Vergi No: </span>
                                <span>{user.tax_no}</span>
                            </td>
                            <td className='px-2 py-1 w-full lg:w-auto lg:table-cell text-left lg:text-center'>
                                <span className="lg:hidden font-semibold">Vergi Dairesi: </span>
                                <span>{user.tax_dep}</span>
                            </td>
                            <td className='px-2 py-1 w-full lg:w-auto lg:table-cell text-left lg:text-center'>
                                <span className="lg:hidden font-semibold">Adres: </span>
                                <span>{user.address}</span>
                            </td>
                            <td className='px-2 py-1 w-full lg:w-auto lg:table-cell text-right lg:text-center'>
                                <ButtonBigWithAction type="button" onClick={handleClick} addClass="text-white bg-sky-700" title="Güncelle" />
                            </td>
                        </tr>
                    </tbody>          
                </table>
            </div>
            <EditInvoiceInfo isOpen={isOpenInvoiceEdit} isOpenAction={setIsOpenInvoiceEdit} updateStatus={updatedInvoiceInfo} updatedAction={setUpdatedInvoiceInfo} />
        </>
    )
}

export default InvoiceInfo