import {useEffect, useState} from 'react';
import TableActions from './TableActions';
import TableLayout from 'layouts/TableLayout';
import units from 'data/Units';


const Table = ({data, actions, progressPending, pagination, pageMeta}) => {

    const returnUnitName = (code) => {
        var unit = units.filter(unit => {return unit.key === code})
        return unit[0].value
    }

    const columns = [
        {
            name: 'Ürün/Hizmet Adı',
            selector: (row) => {return row.name},
            headerMobileHidden: false,
        },
        {
            name: 'Birim',
            selector: (row) => {return returnUnitName(row.unit)},
            headerMobileHidden: false,
        },
        {
            name: 'Birim Fiyat',
            selector: (row) => {return parseFloat(row.unitPrice).toFixed(2).replace(".", ",")},
            headerMobileHidden: false,
        },
        {
            name: 'KDV Oranı',
            selector: (row) => {return row.vatRatio},
            headerMobileHidden: false,
        },
        {
            name: 'İşlemler',
            selector: (row) => {return <TableActions rowId={row.id} actions={actions} data={row}/>},
            headerMobileHidden: true,
        },
    ];

    const mobileHeader = (row) => {return row.name}

    return (
        <>
        <TableLayout columns={columns} data={data} progressPending={progressPending} pagination={pagination} pageMeta={pageMeta} mobileHeader={mobileHeader} />
        </>
    );
};

export default Table;
