import React, {Component} from "react"
import authImage3 from "assets/img/login_banner_3.png"
import logo from "assets/img/favera_logo_2024.png"

class AuthLayout extends Component {
    render() {
        return (
            <div className="w-full h-full bg-gray-300 flex items-center justify-center drop-shadow-lg">
                <div className="border border-gray-300 bg-gray-100 md:flex md:min-w-[50%] max-w-5xl md:min-h-[50%]">
                    <div className="relative overflow-hidden hidden md:block md:w-1/2">
                        <a href="https://www.favera.com.tr/" target="_blank">
                        <img src={authImage3} className="min-h-[500px] max-h-[600px] aspect-square" alt=""></img>
                        </a>
                    </div>
                    <div className="px-8 pt-4 pb-8 flex flex-col justify-between md:w-1/2">
                        <div className="w-full flex justify-center">
                            <img src={logo} className="w-56" alt=""></img>
                        </div>
                        <div className="pb-0 pt-6 flex-grow">
                        { this.props.children }
                        </div>
                    </div>
                </div>
                
            </div>
        );
    }
}

export default AuthLayout