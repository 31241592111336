import React from 'react'
import einvoiceAd from "assets/img/einvoice_ad.png"

const index = () => {
  return (
    <div className='w-full flex items-center justify-center'>
      <a href='https://www.favera.com.tr/e-fatura-programi' target="_blank">
        <img src={einvoiceAd} alt="trendyol" className='w-full max-w-[600px] max-h-[600px]' />
      </a>
    </div>
  )
}

export default index