import { useSanctum } from "react-sanctum"
import { useLocation, Navigate } from "react-router-dom"
import Loading from "pages/Loading"

function RequireAuth({ children }) {
    const { authenticated } = useSanctum()
    let location = useLocation()

    if (authenticated === true) {
        // Signed in
        return children
    } else if (authenticated === false) {
        // Signed out
        return <Navigate to="/login" state={{ from: location }} replace />
    } else {
        // API call hasn't finished
        return <Loading />
    }
}

export default RequireAuth