import {useState} from 'react';
import {useForm} from "react-hook-form"
import FormModal from 'components/FormModal';
import ButtonBigWithAction from 'elements/ButtonBigWithAction';
import InputWithLabel from 'elements/InputWithLabel';
import SelectWithLabel from 'elements/SelectWithLabel';
import InputSplit from 'components/InputSplit';
import PostRequest from 'components/PostRequest'
import LoadingIndicator from 'pages/LoadingIndicator';
import countries from 'data/Countries';

const Create = ({isOpen, isOpenAction, updateStatus, updatedAction}) => {
    const {register, handleSubmit, reset, formState: { errors }} = useForm({
        defaultValues: {
            country: 'Türkiye'
        }
    })

    const actions = (
        <>
            <ButtonBigWithAction type="submit" addClass="text-white bg-emerald-500" title="Kaydet" />
        </>
    )

    const inputs = [
        {
            key: "createInput1",
            type: "text",
            name: "name",
            placeholder: "Zorunlu Alan",
            label: "Şirket Adı *",
            register: register,
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 4, 
                            message: "En az 4 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"}
            },
            error: errors?.name?.message 
        },
        {
            key: "createInput2",
            type: "text",
            name: "taxDepartment",
            placeholder: "Zorunlu Alan",
            label: "Vergi Dairesi *",
            register: register,
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 4, 
                            message: "En az 4 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"}
            },
            error: errors?.taxDepartment?.message
        },
        {
            key: "createInput3",
            type: "text",
            name: "taxNo",
            placeholder: "Zorunlu Alan",
            label: "Vergi Numarası *",
            register: register,
            validator: {
                required: "Bu alan zorunludur.", 
                pattern: {value: /^[0-9]{10,11}$/,
                            message: "10 veya 11 haneli rakam olmalı"}
            },
            error: errors?.taxNo?.message
        },
        {
            key: "createInput4",
            type: "text",
            name: "address",
            placeholder: " ",
            label: "Adres",
            register: register,
            validator: {
                required: false, 
                minLength: {value: 4, 
                    message: "En az 4 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"}
            },
            error: errors?.address?.message
        },
        {
            key: "createInput5",
            type: "select",
            name: "country",
            placeholder: "Zorunlu Alan",
            label: "Ülke *",
            register: register,
            validator: {
                required: "Bu alan zorunludur.", 
            },
            error: errors?.country?.message,
            data: countries
        },
        {
            key: "createInput6",
            type: "text",
            name: "phone",
            placeholder: " ",
            label: "Telefon",
            register: register,
            validator: {
                required: false, 
                minLength: {value: 10, 
                    message: "En az 10 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"}
            },
            error: errors?.phone?.message
        },
        {
            key: "createInput7",
            type: "email",
            name: "email",
            placeholder: " ",
            label: "E-Posta",
            register: register,
            validator: {
                required: false, 
                pattern: {value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "Geçerli bir e-posta adresi olmalı"}
            },
            error: errors?.email?.message
        },
    ]

    const formColumnNumber = 2

    const dividedInputs = InputSplit(inputs, formColumnNumber)

    const [createStatus, setCreateStatus] = useState(null)
    const [loading, setLoading] = useState(false)

    const handleForm = (data) => {
        setLoading(true)
        PostRequest('api/customers', data)
            .then(response => {
                const successMessage = (
                    <div className='text-sm text-green-100 bg-green-600 text-bold mb-2 p-1'>
                        <p>Müşteri başarıyla eklendi.</p> 
                    </div>
                )
                setCreateStatus(successMessage)
                setLoading(false)
                reset()
                updatedAction(!updateStatus)
            })
            .catch(error => {
                const err = error.response.data.errors
                const errorItems = Object.keys(err).map((value, index) => {
                    return (
                        <li key={value.toString()}>{err[value][0]}</li>
                    )
                })
                const errorMessage = (
                    <div className='text-sm text-red-600'>
                        <p>{error.response.data.message}</p>   
                        <ul className='list-[circle] list-inside'>
                            {errorItems}
                        </ul>                     
                    </div>
                )
                setCreateStatus(errorMessage)
                setLoading(false)
            })
    }

    return (
        <FormModal isOpen={isOpen} 
                    isOpenAction={isOpenAction} 
                    id="customerCreateModal" 
                    title="Yeni Müşteri Oluştur" 
                    cancelButton={true} 
                    actions={actions}
                    submitAction={handleSubmit(handleForm)}
                    actionsForClear={[setCreateStatus]}>
            <div>
                {createStatus}
            </div>
            <div className='lg:grid lg:grid-cols-2 lg:gap-x-4'>                
                    {
                        dividedInputs.map((dividedInput, index) => {
                            return (
                                <div className='lg:col-span-1 flex flex-col gap-y-2' key={`inputContainer${index}`}>
                                    {dividedInput.map(input => {
                                        return input.type === "select" ? 
                                        <SelectWithLabel {...input} addClass="mt-2" /> :
                                        <InputWithLabel {...input} addClass="mt-2"/>
                                    })}
                                </div>
                            )
                        })                        
                    }
            </div>
            {loading && <LoadingIndicator />}
        </FormModal>
    );
};

export default Create;
