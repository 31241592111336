import React from 'react';
import NavigationLinkMobile from 'elements/NavigationLinkMobile'

const NavigationMobile = ({handleSignout, closeAction}) => {

    const handleClose = () => {
        closeAction(false)
    }

  return (
    <div onClick={handleClose} className="z-50 bg-gray-800 w-full absolute md:hidden top-0 left-0 mt-14" id="mobile-menu">
        <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <NavigationLinkMobile destination="/" title="Ana Sayfa"/>
            <NavigationLinkMobile destination="/invoices" title="Faturalar"/>
            <NavigationLinkMobile destination="/customers" title="Müşteriler"/>
            <NavigationLinkMobile destination="/items" title="Ürün/Hizmet"/>
        </div>
        <div className="pb-3 border-t border-gray-700">
            <div className="mt-3 px-2 space-y-1">
                <NavigationLinkMobile destination="/settings" title="Hesap Ayarları"/>
                <NavigationLinkMobile destination="/purchases" title="Satın Al"/>
                <button onClick={handleSignout} className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Çıkış Yap</button>
            </div>
        </div>
    </div>
  );
};

export default NavigationMobile;
