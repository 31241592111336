import React from 'react'
import PriceSummary from './PriceSummary'
import Transfer from './Transfer'
import CreditCard from './CreditCard'

const PurchaseProcess = ({updatedStatus, updatedAction}) => {
    const [currentTab, setCurrentTab] = React.useState(1)
    return (
        <>
            <div className='py-2 border-b-2 border-gray-400 flex items-center justify-between'>
                <p className='text-gray-800 text-xl'>Satın Al</p>
            </div>  
            <div className='mt-4 lg:grid lg:grid-cols-3 lg:gap-8'>
                <div className='col-span-1'>
                   <PriceSummary /> 
                </div>
                <div className='col-span-2 border border-gray-700 shadow-rb bg-white p-6 mt-4 lg:mt-0'>
                    <div className="w-full flex flex-grow justify-between items-center">
                        <div className={["w-full text-center border-b-4", currentTab === 1 ? 'border-blue-600' : 'border-transparent'].join(' ')}>
                            <button className="block w-full pb-4" onClick={() => setCurrentTab(1)}>
                            Kredi Kartı
                            </button>
                        </div>
                        <div className={["w-full text-center border-b-4", currentTab === 2 ? 'border-blue-600' : 'border-transparent'].join(' ')}>
                            <button className="block w-full pb-4" onClick={() => setCurrentTab(2)}>
                                Havale / EFT
                            </button>
                        </div>
                    </div>
                    <div className="w-full px-4 py-6">
                        <div className={currentTab === 1 ? 'visible' : 'hidden'}>
                            <CreditCard />
                        </div>
                        <div className={currentTab === 2 ? 'visible' : 'hidden'}>
                            <Transfer  updatedStatus={updatedStatus} updatedAction={updatedAction} />
                        </div>
                    </div>
                </div>
            </div>
        </>      
    )
}

export default PurchaseProcess