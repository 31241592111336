const FormatDateFromMysql = (mysql_string) => {
    var t = null
    var result = null

    if( typeof mysql_string === 'string' )
    {
        var t = new Date(mysql_string)
        var day = t.getDate().toString().padStart(2, '0')
        var month = (t.getMonth()+1).toString().padStart(2, '0')
        var year = t.getFullYear()
        var hour = t.getHours().toString().padStart(2, '0')
        var minute = t.getMinutes().toString().padStart(2, '0')

        result = `${day}.${month}.${year} ${hour}:${minute}`        
    }

    return result;   
}

export default FormatDateFromMysql