import React from 'react'
import GetRequest from 'components/GetRequest'
import {RandId} from 'components/RandId'

const ItemInput = ({name, placeholder, register, validation, index, setValue, calculation}) => {
    const [isOpenMenu, setIsOpenMenu] = React.useState(false)
    const [itemList, setItemList] = React.useState([])
    const [items, setItems] = React.useState([])
    const [selectedItem, setSelectedItem] = React.useState(null)
    const [inputContainerId, setInputContainerId] = React.useState(RandId(`item_name_input_${index}_`))

    React.useEffect(() => {
        GetRequest('api/savedItems?no_pagination=true')
        .then((response) => {
            setItems(response.data.data)            
        })
        .catch(error => {
            setItems({})
        })
    }, [])

    React.useEffect(() => {
        setItemList(items)
    }, [items])

    React.useEffect(() => {
        const container = document.getElementById(inputContainerId)
        const eventFunction = (e) => {
            if(!e.composedPath().includes(container)) {
                setIsOpenMenu(false)
            }
        }
        document.addEventListener('click', eventFunction )
        return () => {
            document.removeEventListener('click', eventFunction)
        }
    }, []) 

    const Item = ({value, name}) => {
        const match = selectedItem === value
        const className = match  
                        ? 'w-full px-2 py-1 text-left bg-gray-200 text-gray-800 font-semibold cursor-pointer text-sm hover:bg-gray-200'
                        : 'w-full px-2 py-1 text-left text-gray-700 cursor-pointer text-sm hover:bg-gray-200'
        
        return <li className={className} value={value} key={value} onClick={handleItemClick}>{name}</li>
    }

    const handleOpen = (e) => {
        e.preventDefault()
        setIsOpenMenu(!isOpenMenu)
    }

    const filterResult= (e) => {
        var result = items.filter(value => {
            return value.name.toLowerCase().includes(e.target.value.toLowerCase())
        })
        setItemList(result)
    }

    const handleItemClick = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setIsOpenMenu(false)
        setSelectedItem(e.target.value)
    }

    const handleBlur = (e) => {
        console.log(e)
        e.preventDefault()
        e.stopPropagation()
        setIsOpenMenu(false)
    }

    React.useEffect(() => {
        if(selectedItem === null || selectedItem === 0) {
            return
        } else {
            var selectedItemData = items.filter(value => {return value.id == selectedItem})
            console.log(selectedItemData)
            setValue(`itemInputs.${index}.name`, selectedItemData[0].name)
            setValue(`itemInputs.${index}.unit`, selectedItemData[0].unit)
            setValue(`itemInputs.${index}.unitPrice`, selectedItemData[0].unitPrice.toFixed(2).replace(".", ","))
            setValue(`itemInputs.${index}.vatRatio`, selectedItemData[0].vatRatio)
            calculation()
        }
    }, [selectedItem])

    return (
        
        <div id={inputContainerId} className='lg:max-w-[400px] w-full block relative'>
            <input className='form-input w-full rounded-sm px-1 py-1 text-sm border border-gray-400' 
            name={name}
            placeholder={placeholder}
            {...register(name, validation)} 
            onFocus={() => {setIsOpenMenu(true)}}
            autoComplete="off"
            onChange={filterResult}
            />
            <div className={'absolute w-full max-h-[200px] overflow-y-auto rounded shadow border border-gray-300 bg-white z-10 mt-1 flex-col'+(isOpenMenu ? ' flex' : ' hidden')}>
                <ul>
                {itemList.length ? itemList.map((item, index) => {
                    return <Item name={item.name} value={item.id} key={index}/>
                })
                : <></>}
                </ul>
            </div>
        </div>
    )
}

export default ItemInput