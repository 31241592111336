import {useEffect, useState} from 'react'
import {useForm} from "react-hook-form"
import InputWithLabel from 'elements/InputWithLabel'
import ButtonBigWithAction from 'elements/ButtonBigWithAction'
import LoadingIndicator from 'pages/LoadingIndicator'
import PutRequest from 'components/PutRequest'
import { useSanctum } from "react-sanctum";

const UserInfo = () => {

    const { user, setUser } = useSanctum();
    
    const {register, handleSubmit, reset, formState: { errors }, setValue} = useForm({
        defaultValues: {
            name: user.name,
            company: user.company,
            phone: user.phone,
            email: user.email
        }
    })

    const inputs = [
        {
            key: "userInfoInput1",
            type: "text",
            name: "name",
            placeholder: "Zorunlu Alan",
            label: "Ad Soyad *",
            register: register,
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 4, 
                            message: "En az 4 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"},
                pattern: {value: /^[a-zA-Z\s]*$/,
                            message: "Sadece harf ve boşluktan oluşabilir."}
            },
            error: errors?.name?.message 
        },
        {
            key: "userInfoInput2",
            type: "text",
            name: "company",
            placeholder: "Zorunlu Alan",
            label: "Şirket Adı *",
            register: register,
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 4, 
                            message: "En az 4 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"}
            },
            error: errors?.company?.message 
        },
        {
            key: "userInfoInput3",
            type: "text",
            name: "phone",
            placeholder: "Zorunlu Alan",
            label: "Telefon *",
            register: register,
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 4, 
                            message: "En az 4 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"},
                pattern: {value: /^[0-9]{10}$/,
                            message: "10 haneli rakam olmalı"}
            },
            error: errors?.phone?.message 
        },
        {
            key: "userInfoInput4",
            type: "text",
            name: "email",
            placeholder: "Zorunlu Alan",
            label: "E-Posta *",
            register: register,
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 4, 
                            message: "En az 4 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"},
                pattern: {value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Geçerli bir e-posta adresi olmalı"}
            },
            error: errors?.email?.message 
        },
    ]

    const [saveStatus, setSaveStatus] = useState()
    const [loading, setLoading] = useState(false)

    const handleForm = (data) => {
        setLoading(true)
        PutRequest('api/user/'+user.id, data)
            .then(response => {
                
                const successMessage = (
                    <div className='text-sm text-green-100 bg-green-600 text-bold mb-2 p-1'>
                        <p>Bilgiler başarıyla kaydedildi.</p> 
                    </div>
                )
                setSaveStatus(successMessage)
                setUser(response.data)
                setLoading(false)
            })
            .catch(error => {
                const err = error.response.data.errors
                if(err) {
                    const errorItems = Object.keys(err).map((value, index) => {
                        return (
                            <li key={value.toString()}>{err[value][0]}</li>
                        )
                    })
                    const errorMessage = (
                        <div className='text-sm text-red-600'>
                            <p>{error.response.data.message}</p>   
                            <ul className='list-[circle] list-inside'>
                                {errorItems}
                            </ul>                     
                        </div>
                    )
                    setSaveStatus(errorMessage)
                } else {
                    const errorMessage = (
                        <div className='text-sm text-red-100 bg-red-600 text-bold mb-2 p-1'>
                            <p>{`${error.response.statusText} (${error.response.status})`}</p> 
                        </div>
                    )   
                    
                    setSaveStatus(errorMessage)
                }  
                setLoading(false)
            })
    }
    return (
        <div className='lg:grid lg:grid-cols-2 lg:gap-8'>
            <div className='text-left mt-12'>
                <h2 className='text-2xl font-semibold text-gray-700 my-4 '>Kullanıcı Bilgileri</h2>
                <p className='text-md text-gray-700'> Bu alandan sistemde kayıtlı olan kullanıcı bilgilerinizi güncelleyebilirsiniz.</p>
            </div>
            <div className='text-left'>
                    <div className='h-12'>
                        {saveStatus}
                    </div>
                    <form onSubmit={handleSubmit(handleForm)}>
                    {inputs.map((input, index) => {
                                return (
                                    <div className='max-w-[400px]' key={`inputContainer${index}`}>                                    
                                            <InputWithLabel {...input} addClass="mt-2"/>
                                    </div>
                                )
                            })  }
                        <ButtonBigWithAction type="submit" addClass="text-white bg-emerald-500 w-full max-w-[400px]" title="Kaydet" />
                    </form>
                </div>
                {loading && <LoadingIndicator />}
                    
        </div>
    )
}

export default UserInfo