import React from 'react'

const PriceSummary = () => {
  return (
    <div className="md:flex justify-center bg-white">
        <div className="px-8 py-2 border-2 border-orange-500 flex flex-col items-center">
            <p className="px-8 py-2 bg-gray-800 text-gray-100 font-bold">Yıllık Plan</p>
            <div className="w-full flex justify-center items-center gap-2 my-4">
            <div className="text-5xl font-bold text-cham-600">500</div>
            <div className="flex flex-col justify-center items-start">
                <div className="text-xl leading-none font-normal">
                ₺
                </div>
                <div className="text-lg leading-none font-normal">
                +KDV / Yıllık
                </div>
            </div>
            </div>
            <p className="text-sm text-gray-600 px-2 py-2 border-b-2 border-gray-300">Yıllık <span className="font-bold">Sınırsız</span> Fatura</p>
            <p className="text-sm text-gray-600 px-2 py-2 border-b-2 border-gray-300"><span className="font-bold">Sınırsız</span> Cari Kaydetme</p>
            <p className="text-sm text-gray-600 px-2 py-2 border-b-2 border-gray-300"><span className="font-bold">Sınırsız</span> E-Posta Gönderme</p>
            <p className="text-sm text-gray-600 px-2 py-2 border-b-2 border-gray-300">Toplu <span className="font-bold">Muhasebe</span> Raporları</p>
            <p className="text-sm text-gray-600 px-2 py-2 border-b-2 border-gray-300 mb-12"><span className="font-bold">Sınırsız</span> Saklama Alanı</p>
            
        </div>
    </div>
  )
}

export default PriceSummary