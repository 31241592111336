import {useEffect, useState} from 'react';
import {useForm} from "react-hook-form"
import { useSanctum } from "react-sanctum";
import FormModal from 'components/FormModal';
import ButtonBigWithAction from 'elements/ButtonBigWithAction';
import InputWithLabel from 'elements/InputWithLabel';
import SelectWithLabel from 'elements/SelectWithLabel';
import InputSplit from 'components/InputSplit';
import PutRequest from 'components/PutRequest'
import LoadingIndicator from 'pages/LoadingIndicator';
import units from 'data/Units';
import vatRatios from 'data/VatRatios';

const EditInvoiceInfo = ({isOpen, isOpenAction, updateStatus, updatedAction}) => {
    const { user, setUser } = useSanctum();

    const {register, handleSubmit, reset, formState: { errors }, setValue} = useForm({
        defaultValues: {
            company: null,
            tax_no: null,
            tax_dep: null,
            address: null,
        }
    })

    useEffect(() => {
        setValue('company', user.company)
        setValue('tax_no', user.tax_no)
        setValue('tax_dep', user.tax_dep)
        setValue('address', user.address)
    }, [])

    const actions = (
        <>
            <ButtonBigWithAction type="submit" addClass="text-white bg-emerald-500" title="Kaydet" />
        </>
    )

    const inputs = [
        {
            key: "editInput1",
            type: "text",
            name: "company",
            placeholder: "Zorunlu Alan",
            label: "Şirket/Kişi Adı *",
            register: register,
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 3, 
                    message: "En az 3 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"}
            },
            error: errors?.company?.message 
        },
        {
            key: "editInput2",
            type: "text",
            name: "tax_no",
            placeholder: "Zorunlu Alan",
            label: "Vergi No *",
            register: register,
            validator: {
                required: "Bu alan zorunludur.", 
                pattern: {value: /^[0-9]{10,11}$/,
                            message: "10 veya 11 haneli rakam olmalı"},
            },
            error: errors?.tax_no?.message 
        },
        {
            key: "editInput3",
            type: "text",
            name: "tax_dep",
            placeholder: "Zorunlu Alan",
            label: "Vergi Dairesi *",
            register: register,
            validator: {
                required: "Bu alan zorunludur.",   
                minLength: {value: 4, 
                            message: "En az 4 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"}
            },
            error: errors?.tax_dep?.message
        },
        {
            key: "editInput4",
            type: "text",
            name: "address",
            placeholder: "Zorunlu Alan",
            label: "Şirket Adresi *",
            register: register,
            validator: {
                required: "Bu alan zorunludur.",   
                minLength: {value: 4, 
                            message: "En az 4 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"}
            },
            error: errors?.address?.message
        }
    ]

    const formColumnNumber = 1

    const dividedInputs = InputSplit(inputs, formColumnNumber)

    const [editStatus, setEditStatus] = useState(null)
    const [loading, setLoading] = useState(false)

    const handleForm = (data) => {
        setLoading(true)
        PutRequest(`api/user/${user.id}/invoice`, data)
            .then(response => {
                const successMessage = (
                    <div className='text-sm text-green-100 bg-green-600 text-bold mb-2 p-1'>
                        <p>Fatura bilgileri başarıyla güncellendi.</p> 
                    </div>
                )
                setEditStatus(successMessage)
                setLoading(false)
                updatedAction(!updateStatus)
                setUser(response.data)
            })
            .catch(error => {
                const err = error.response.data.errors
                if(err) {
                    const errorItems = Object.keys(err).map((value, index) => {
                        return (
                            <li key={value.toString()}>{err[value][0]}</li>
                        )
                    })
                    const errorMessage = (
                        <div className='text-sm text-red-600'>
                            <p>{error.response.data.message}</p>   
                            <ul className='list-[circle] list-inside'>
                                {errorItems}
                            </ul>                     
                        </div>
                    )

                    setEditStatus(errorMessage)
                } else {
                    const errorMessage = (
                        <div className='text-sm text-red-100 bg-red-600 text-bold mb-2 p-1'>
                            <p>{`${error.response.statusText} (${error.response.status})`}</p> 
                        </div>
                    )   
                    
                    setEditStatus(errorMessage)
                }                
                
                setLoading(false)
            })
    }

    return (
        <FormModal isOpen={isOpen} 
                    isOpenAction={isOpenAction} 
                    id="itemEditModal" 
                    title="Fatura Bilgilerini Düzenle" 
                    cancelButton={true} 
                    actions={actions}
                    submitAction={handleSubmit(handleForm)}
                    actionsForClear={[setEditStatus]}>
            <div>
                {editStatus}
            </div>
            <div className='lg:grid lg:grid-cols-1 lg:gap-x-4'>                
                    {
                        dividedInputs.map((dividedInput, index) => {
                            return (
                                <div className='lg:col-span-1 flex flex-col gap-y-2' key={`inputContainer${index}`}>
                                    {dividedInput.map(input => {
                                        return input.type === "select" ? 
                                        <SelectWithLabel {...input} addClass="mt-2" /> :
                                        <InputWithLabel {...input} addClass="mt-2"/>
                                    })}
                                </div>
                            )
                        })                        
                    }
            </div>
            {loading && <LoadingIndicator />}
        </FormModal>
    );
};

export default EditInvoiceInfo;
