import { useState, useEffect } from 'react'
import {Link} from 'react-router-dom'
import AuthStatus from './AuthStatus'
import companyLogo from 'assets/img/logo-b.png'
import NavigationLink from 'elements/NavigationLink'
import NavigationMobile from 'elements/NavigationMobile'
import NavigationDropdown from 'elements/NavigationDropdown'
import {useSanctum} from "react-sanctum"
import {Icon} from "elements/Icons";
 
function Navbar() {

    const {signOut} = useSanctum()

    const handleSignout = (e) => {
        e.preventDefault()
        signOut().then(() => {}).catch((error) => console.log(error))
    }

    const [isOpenMobile, setIsOpenMobile] = useState(false)

    const handleMobileMenu = (e) => {
        e.preventDefault()
        setIsOpenMobile(!isOpenMobile)
    }

    useEffect(() => {
        const mobileMenu = document.getElementById("mobile-menu")
        const openSvg = document.getElementById("mobile-open-svg")
        const closeSvg = document.getElementById("mobile-close-svg")
        if(isOpenMobile) {
            mobileMenu.classList.add("block")
            mobileMenu.classList.remove("hidden")
            openSvg.classList.remove("block")
            openSvg.classList.add("hidden")
            closeSvg.classList.remove("hidden")
            closeSvg.classList.add("block")
        } else {
            mobileMenu.classList.add("hidden")
            mobileMenu.classList.remove("block")
            openSvg.classList.add("block")
            openSvg.classList.remove("hidden")
            closeSvg.classList.add("hidden")
            closeSvg.classList.remove("block")
        }
    })

    const links = [
        {
            id: 1,
            type: 'link',
            action: '/settings',
            title: 'Hesap Ayarları'
        }, 
        {
            id: 2,
            type: 'link',
            action: '/purchases',
            title: 'Satın Al'
        }, 
        {
            id: 3,
            type: 'button',
            action: handleSignout,
            title: 'Çıkış Yap'
        }, 

        
    ]
    return (
       <>
       <nav className="bg-gray-800">
            <div className="mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-16">
                    <div className="flex items-center">
                        <div className="flex-shrink-0">
                            <a href="https://favera.com.tr" target="_blank">
                            <img className="h-8" src={companyLogo} alt="Favera" />
                            </a>
                        </div>
                        <div className="hidden md:block">
                            <div className="ml-10 flex items-baseline space-x-4">
                                <NavigationLink destination="/" title="Ana Sayfa"/>
                                <NavigationLink destination="/invoices" title="Faturalar"/>
                                <NavigationLink destination="/customers" title="Müşteriler"/>
                                <NavigationLink destination="/items" title="Ürün/Hizmet"/>
                                <NavigationLink destination="/settings" title="Ayarlar"/>
                                <NavigationLink destination="/purchases" title="Satın Al"/>
                                <NavigationLink destination="/einvoices" title="E-Faturaya Geç"/>
                            </div>
                        </div>
                    </div>
                    <div className="hidden md:block">
                        <div className="ml-4 flex items-center md:ml-6">
                            <button onClick={handleSignout} className="inline-flex items-center justify-center gap-x-2 text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium" type="button">
                                <span>Çıkış Yap</span>
                                <Icon name="signout" size="24"/>
                            </button>
                        </div>
                    </div>
                    <div className="-mr-2 flex md:hidden">
                        <button type="button" id="mobile-button" onClick={handleMobileMenu} className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white" aria-controls="mobile-menu" aria-expanded="false">
                            <span className="sr-only">Open main menu</span>
                            <svg id="mobile-open-svg" className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                            </svg>
                            <svg id="mobile-close-svg" className="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>

            <NavigationMobile handleSignout={handleSignout} closeAction={setIsOpenMobile}/>
        </nav>
        
        </>
   ) 
}

export default Navbar