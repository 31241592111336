import React, {Component} from "react"

class Checkbox extends Component {
    render() {
        return (
            <div className="block pt-3 pb-2 space-x-4">
                <label htmlFor={this.props.name} className="flex items-center justify-start cursor-pointer">
                    <input 
                    type="checkbox"
                    id={this.props.name}
                    name={this.props.name}
                    value={this.props.referanceProp}
                    className="form-checkbox mr-2 text-sky-500 border-2 border-sky-300 rounded focus:outline-none focus:shadow-none focus:ring-0 focus:ring-transparent"
                    />
                    <span>{this.props.title}</span>
                    
                </label>
            </div>
        );
    }
}

export default Checkbox