import {useRef, useState} from "react"
import {useSanctum} from "react-sanctum"
import {useNavigate, useLocation} from "react-router-dom"
import AuthLayout from "layouts/AuthLayout"
import InputFloatingLabel from "elements/InputFLoatingLabel"
import Checkbox from "elements/Checkbox"
import LinkBlue from "elements/LinkBlue"
import ButtonBig from "elements/ButtonBig"

function Login() {
    const inputEmail = useRef(null)
    const inputPassword = useRef(null)
    const inputRememberMe = useRef(false)
    const [loginStatus, setLoginStatus] = useState('')
    const { signIn } = useSanctum()

    let navigate = useNavigate();
    let location = useLocation();

    let from = location.state?.from?.pathname || "/";

    const handleLogin = (e) => {
        e.preventDefault()
        const email = inputEmail.current.value;
        const password = inputPassword.current.value;
        const remember = inputRememberMe.current.value;
    
        signIn(email, password, remember)
          .then((signedIn) => {
              if(signedIn) {
                navigate(from, { replace: true })
              } else {
                setLoginStatus('Crediential not correct')
              }
              
            })
          .catch((error) => {
              console.log(error.response.data)
              const err = error.response.data.errors
              if(err) {
                const errorItems = Object.keys(err).map((value, index) => {
                    return (
                        <li key={value.toString()}>{err[value][0]}</li>
                    )
                })
                const errorMessage = (
                    <div className='text-sm text-red-600'>
                        <p>{error.response.data.message}</p>   
                        <ul className='list-[circle] list-inside'>
                            {errorItems}
                        </ul>                     
                    </div>
                )

                setLoginStatus(errorMessage)
            } else {
                const errorMessage = (
                    <div className='text-sm text-red-100 bg-red-600 text-bold mb-2 p-1'>
                        <p>{`${error.response.statusText} (${error.response.status})`}</p> 
                    </div>
                )   
                
                setLoginStatus(errorMessage)
            }  
            })
    }

    return (
       
            
            <AuthLayout>
                <div>
                    <div className="text-center text-lg">
                        GİB e-Arşiv Portal Girişi
                    </div>
                    <div className="h-16">
                        {loginStatus}
                    </div>
                    <form className="w-full flex flex-col" onSubmit={handleLogin}>
                        <InputFloatingLabel required={true} name="email" referanceProp={inputEmail} label="E-Posta" type="text"/>
                        <InputFloatingLabel required={true} name="password" referanceProp={inputPassword} label="Şifre" type="password" />
                        <div className="flex justify-between items-center">
                            <Checkbox name="remember" referanceProp={inputRememberMe} title="Beni Hatırla!" />
                            <LinkBlue route="/forgot-password" title="Şifremi Unuttum" />
                        </div>
                        <ButtonBig type="submit" title="Giriş Yap" addClass="bg-emerald-600 text-white"/>
                    </form>
                    <div className="mt-4 text-center">
                        <p className="text-black-700">Hesabım yok. Hemen <LinkBlue route="/register" title="hesap oluşturalım" /> </p>   
                    </div>
                </div>
            </AuthLayout>
    )
}

export default Login