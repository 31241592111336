const InputSplit = (inputs, columnNumber) => {
    var perChunk = Math.ceil(inputs.length / columnNumber) // items per chunk    

    var inputArray = inputs

    var result = inputArray.reduce((resultArray, item, index) => { 
        const chunkIndex = Math.floor(index/perChunk)

        if(!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [] // start a new chunk
        }

        resultArray[chunkIndex].push(item)

        return resultArray
    }, [])
  
  
    return result
};

export default InputSplit;
