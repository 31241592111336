import {useEffect, useState} from 'react';
import TableLayoutWithoutPagination from 'layouts/TableLayoutWithoutPagination';
import { FormatDate } from 'components/FormatDate';


const PurchaseTable = ({data, actions, progressPending}) => {
    const statusNames = {
        0: <span className='py-[3px] px-4 bg-amber-600 text-amber-50'>Onaylanmadı</span>,
        1: <span className='py-[3px] px-4 bg-lime-600 text-lime-50'>Onaylandı</span>
    }
    const paymentTypeNames = {
        1: <span className='py-[3px] px-4 bg-rose-600 text-rose-50'>Kredi Kartı</span>,
        2: <span className='py-[3px] px-4 bg-sky-600 text-sky-50'>Havale/EFT</span>
    }
    const columns = [
        {
            name: 'Ürün',
            selector: (row) => {return row.name},
            headerMobileHidden: false,
        },
        {
            name: 'Tarih',
            selector: (row) => {return FormatDate(row.purchaseDate)},
            headerMobileHidden: false,
        },
        {
            name: 'Tutar (KDV Dahil)',
            selector: (row) => {return parseFloat(row.price*1.18).toFixed(2).replace(".", ",")},
            headerMobileHidden: false,
        },
        {
            name: 'Ödeme Yöntemi',
            selector: (row) => {return paymentTypeNames[row.type]},
            headerMobileHidden: false,
        },
        {
            name: 'Durum',
            selector: (row) => {return statusNames[row.is_approved]},
            headerMobileHidden: false,
        }
    ];

    const mobileHeader = (row) => {return `${row.name} (${FormatDate(row.purchaseDate)})`}

    return (
        <>
            <div className='py-2 border-b-2 border-gray-400 flex items-center justify-between'>
                <p className='text-gray-800 text-xl'>Satın Alma Bilgileri</p>
            </div>  
            <div className='mt-4'>
                <TableLayoutWithoutPagination columns={columns} data={data} progressPending={progressPending} mobileHeader={mobileHeader} />
            </div>
        </>
    );
};

export default PurchaseTable;
