import React, {Component} from "react"
import { Link } from "react-router-dom";

class LinkBlue extends Component {
    render() {
        return (
            <Link to={this.props.route} className="text-md font-semibold text-sky-500 hover:text-sky-800">
                {this.props.title}
            </Link>
        );
    }
}

export default LinkBlue