import ButtonBigWithAction from 'elements/ButtonBigWithAction';
import Create from './Create';
import Table from './Table';
import {useState,useEffect} from 'react';
import { useSearchParams, useLocation } from 'react-router-dom';
import {DebounceInput} from 'react-debounce-input';
import GetRequest from 'components/GetRequest';
import Edit from './Edit';
import Delete from './Delete';

const Customers = () => {
    const [isOpenCreate, setIsOpenCreate] = useState(false)
    const [isOpenEdit, setIsOpenEdit] = useState(false)
    const [customerForEdit, setCustomerForEdit] = useState(null)
    const [isOpenDelete, setIsOpenDelete] = useState(false)
    const [customerForDelete, setCustomerForDelete] = useState(null)
    const [customers, setCustomers] = useState(null)
    const [pagination, setPagination] = useState(null)
    const [pageMeta, setPageMeta] = useState(null)
    const [updatedCustomers, setUpdatedCustomers] = useState(false)
    const [pending, setPending] = useState(false)
    const [exSearch, setExSearch] = useState(null)
    const [searchParams, setSearchParams] = useSearchParams()
    let { search } = useLocation();

    useEffect(() => {
        setPending(true)
        var page = searchParams.get("page")
        var searchPa = searchParams.get("search") || ''
        if(searchPa != null && exSearch != searchPa) {
            page = "1"
            setSearchParams({page: 1, search: searchPa})
        }
        GetRequest('api/customers?page='+page+'&search='+searchPa)
        .then((response) => {
            setCustomers(response.data.data)
            setPagination(response.data.links)
            setPageMeta({total: response.data.total, from: response.data.from, to: response.data.to, currentPage: response.data.current_page})
            setExSearch(searchPa)
            setPending(false)
        })
        .catch(error => {
            setCustomers(error.response.data)
            setPending(false)
        })
    }, [updatedCustomers, search])

    const handleClick = (e) => {
        setIsOpenCreate(!isOpenCreate)
    } 
    
    const actions = (
        <>
            <ButtonBigWithAction type="submit" addClass="text-white bg-emerald-500" title="Gönder" />
        </>
    )

    return (
        <div className='text-center mx-6 mb-6 mt-3'>
            
            <div className='py-2 border-b-2 border-gray-400 flex items-center justify-between'>
                <p className='text-gray-800 text-xl'>Müşteriler</p>
                <div className='flex justify-end gap-x-4'>
                    <ButtonBigWithAction type="button" onClick={handleClick} addClass="text-white bg-sky-700" title="Yeni Müşteri Ekle" />
                </div>                  
            </div>  
            <div className='mt-4'>
                <div className='w-full flex justify-start mb-2'>
                <DebounceInput
                    minLength={4}
                    debounceTimeout={500}
                    onChange={event =>setSearchParams({page: searchParams.get("page"),search: event.target.value})} 
                    className="form-input w-full lg:max-w-[250px] rounded px-2 py-1 text-sm"
                    placeholder="İsim ve VKN İçerisinde Ara"
                    value={searchParams.get("search")} />

                </div>
                <Table data={customers} 
                    actions={
                        {edit: {status: isOpenEdit, action: setIsOpenEdit}, 
                        editted: {status: customerForEdit, action: setCustomerForEdit},
                        delete: {status: isOpenDelete, action: setIsOpenDelete},
                        deleted: {status: customerForDelete, action: setCustomerForDelete},}
                    } 
                    progressPending={pending}
                    pagination={pagination}
                    pageMeta={pageMeta}
                /> 
            </div>
            <Create isOpen={isOpenCreate} isOpenAction={setIsOpenCreate} updateStatus={updatedCustomers} updatedAction={setUpdatedCustomers} />
            <Edit isOpen={isOpenEdit} isOpenAction={setIsOpenEdit} updateStatus={updatedCustomers} updatedAction={setUpdatedCustomers} selectedData={customerForEdit}/>
            <Delete isOpen={isOpenDelete} isOpenAction={setIsOpenDelete} updateStatus={updatedCustomers} updatedAction={setUpdatedCustomers} selectedData={customerForDelete}/>
            
            
        </div>
    )
};

export default Customers;
