import {useEffect, useState} from 'react';
import TableActionsForCancelled from './TableActionsForCancelled';
import TableLayout from 'layouts/TableLayout';
import { FormatDate } from 'components/FormatDate';


const CancelledTable = ({data, actions, progressPending, pagination, pageMeta}) => {
    const statusNames = {
        saved: <span className='p-[2px] bg-amber-600 text-amber-50'>Onaylanmadı</span>,
        approved: <span className='p-[2px] bg-lime-600 text-lime-50'>Onaylandı</span>,
        cancelled: <span className='p-[2px] bg-rose-600 text-rose-50'>İptal Edildi</span>,
        deleted: <span className='p-[2px] bg-rose-600 text-rose-50'>Silindi</span>
    }
    const columns = [
        {
            name: 'Fatura No',
            selector: (row) => {return row.invoiceNo},
            headerMobileHidden: false,
        },
        {
            name: 'Müşteri Adı',
            selector: (row) => {return (row.customerName)},
            headerMobileHidden: false,
        },
        {
            name: 'Müşteri TCKN/VKN',
            selector: (row) => {return row.taxNo},
            headerMobileHidden: false,
        },
        {
            name: 'Fatura Tutarı',
            selector: (row) => {return `${parseFloat(row.paid).toFixed(2).replace(".", ",")} ${row.currency}`},
            headerMobileHidden: false,
        },
        {
            name: 'Tarih/Saat',
            selector: (row) => {return FormatDate(row.issueDate, row.issueTime)},
            headerMobileHidden: false,
        },
        {
            name: 'Durum',
            selector: (row) => {return statusNames[row.status]},
            headerMobileHidden: false,
        },
        {
            name: 'İşlemler',
            selector: (row) => {return <TableActionsForCancelled rowId={row.id} actions={actions} data={row}/>},
            headerMobileHidden: true,
        },
    ];

    const mobileHeader = (row) => {return `${row.customerName} (${FormatDate(row.issueDate)})`}

    return (
        <>
        <TableLayout columns={columns} data={data} progressPending={progressPending} pagination={pagination} pageMeta={pageMeta} mobileHeader={mobileHeader} />
        </>
    );
};

export default CancelledTable;
