import {useState, useEffect} from 'react'
import {DebounceInput} from 'react-debounce-input';
import { useSearchParams, useLocation } from 'react-router-dom';
import GetRequest from 'components/GetRequest';
import DeleteDraft from './DeleteDraft';
import DraftTable from './DraftTable';

const InvoiceDraft = () => {

  const [searchParams, setSearchParams] = useSearchParams()
  const [pending, setPending] = useState(false)
  const [pagination, setPagination] = useState(null)
  const [pageMeta, setPageMeta] = useState(null)
  const [exSearch, setExSearch] = useState(null)

  const [invoices, setInvoices] = useState(null)
  const [updatedInvoices, setUpdatedInvoices] = useState(false)

  const [isOpenDeleteDraft, setIsOpenDeleteDraft] = useState(false)
  const [invoiceForDeleteDraft, setInvoiceForDeleteDraft] = useState(null)

  let { search } = useLocation();

  useEffect(() => {
    setPending(true)
    var page = searchParams.get("page")
    var searchPa = searchParams.get("search") || ''
    if(searchPa != null && exSearch != searchPa) {
        page = "1"
        setSearchParams({page: 1, search: searchPa})
    }
    GetRequest('api/invoices/draft?page='+page+'&search='+searchPa)
    .then((response) => {
        setInvoices(response.data.data)
        setPagination(response.data.links)
        setPageMeta({total: response.data.total, from: response.data.from, to: response.data.to, currentPage: response.data.current_page})
        setExSearch(searchPa)
        setPending(false)
    })
    .catch(error => {
        setInvoices(error.response.data)
        setPending(false)
    })
  }, [search, updatedInvoices])

  return (
    <div className='text-center mb-6 mt-3'>
            
      <div className='py-2 border-b-2 border-gray-400 flex items-center justify-between'>
          <p className='text-gray-800 text-xl'>Şablon Faturalar</p>
      </div>
      <div className='mt-4'>
          <div className='w-full flex justify-start mb-2'>
          <DebounceInput
              minLength={4}
              debounceTimeout={500}
              onChange={event =>setSearchParams({page: searchParams.get("page"),search: event.target.value})} 
              className="form-input w-full lg:max-w-[250px] rounded px-2 py-1 text-sm"
              placeholder="Fatura No, Müşteri ve VKN İçerisinde Ara"
              value={searchParams.get("search")} />

          </div>
          <DraftTable data={invoices} 
              actions={
                {
                    delete: {status: isOpenDeleteDraft, action: setIsOpenDeleteDraft}, 
                    deleted: {status: invoiceForDeleteDraft, action: setInvoiceForDeleteDraft},
                }
              } 
              progressPending={pending}
              pagination={pagination}
              pageMeta={pageMeta}
          /> 
      </div>
      <DeleteDraft isOpen={isOpenDeleteDraft} isOpenAction={setIsOpenDeleteDraft} updateStatus={updatedInvoices} updatedAction={setUpdatedInvoices} selectedData={invoiceForDeleteDraft}/>
    </div>
  )
}

export default InvoiceDraft