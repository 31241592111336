
const InputWithLabel = ({type, name, placeholder, label, register, validator, error, addClass = null, readOnly = false}) => {
  const borderColor = error ? 'border-red-500' : 'border-gray-400'
  return (    
      <div className='flex flex-col gap-y-1 items-start'>
          <label htmlFor={name} className={['text-gray-700 text-sm', addClass].join(' ')}>{label}</label>
          <input type={type} 
              name={name}
              placeholder={placeholder} 
              {...register(name, validator)}
              className={['form-input w-full rounded px-2 py-1 text-base', borderColor].join(' ')} 
              readOnly={readOnly && 'readOnly'}/>
          <span className="text-sm text-red-600">{error}</span>
      </div>
  );
};

export default InputWithLabel;
