import { useLocation, Navigate } from "react-router-dom"
import { useState, useEffect } from "react"
import Loading from "pages/Loading"
import GetRequest from "./GetRequest"

function RequireGib({ children }) {
    let location = useLocation()
    const [gibCheck, setGibCheck] = useState(null)
    useEffect(() => {
        GetRequest('api/settings/gibportal/check')
            .then(response => {
                if(response.data.status == true) {
                    setGibCheck(true)                    
                } else {
                    setGibCheck(false)
                }
            }).catch(error => {
                setGibCheck(false)
            })
        }, [])

    if (gibCheck === true) {
        // Signed in
        return children
    } else if (gibCheck === false) {
        // Signed out
        return <Navigate to="/settings/gibportal" state={{ from: location }} replace />
    } else {
        // API call hasn't finished
        return <Loading />
    }
}

export default RequireGib