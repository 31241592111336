import axios from 'axios'

const DeleteRequest = (url) => {
    const  baseUrl = 'https://earsiv-api.faveraportal.com'
    axios.defaults.withCredentials = true;
    const request = axios.delete(`${baseUrl}/${url}`)

  return request;
};

export default DeleteRequest;
