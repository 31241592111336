import React from 'react'
import { useSanctum } from "react-sanctum"
import Loading from "pages/Loading"
import { Icon } from 'elements/Icons'
import { FormatDate } from 'components/FormatDate'

const ExpiryAlert = () => {
    const [expireDateCheck, setExpireDateCheck] = React.useState(null)
    const { user } = useSanctum()

    React.useEffect(() => {
        const expireDate = new Date(user.expire_date)
        const today = new Date()
        if(today > expireDate) {
            setExpireDateCheck(false)
        } else {
            setExpireDateCheck(true)
        }
    }, [])

    if (expireDateCheck === true) {
        return (
            <div className='w-full px-4 py-4 bg-green-600 text-green-50 flex gap-4 justify-start items-center'>
                <div>
                    <Icon name="success" size="32" />
                </div>
                <div>
                    <p>Kullanım süreniz {FormatDate(user.expire_date)} tarihinde sona erecektir. Programı kullanmaya devam edebilmek için bu tarihten önce satın alabilirsiniz.</p>
                </div>
            </div>
        )
    } else if (expireDateCheck === false) {
        return (
            <div className='w-full px-4 py-4 bg-rose-600 text-rose-50 flex gap-4 justify-start items-center'>
                <div>
                    <Icon name="exclamation" size="32" />
                </div>
                <div>
                    <p>Kullanım süreniz {FormatDate(user.expire_date)} tarihinde dolmuştur. Programı kullanmaya devam edebilmek için satın almalısınız.</p>
                </div>
            </div>
        )
    } else {
        return <Loading />
    }
}

export default ExpiryAlert