import React from 'react'
import { Icon } from 'elements/Icons';
import { Link } from 'react-router-dom';

const TableActions = ({rowId, actions, data}) => {

    const handlePreview = (e) => {
        actions.preview.action(true)
        actions.previewed.action(data)
    }
    const handleDelete = (e) => {
        actions.delete.action(true)
        actions.deleted.action(data)
    }
    const handleApprove = (e) => {
        actions.approve.action(true)
        actions.approved.action(data)
    }
    const handleCancel = (e) => {
        actions.cancel.action(true)
        actions.cancelled.action(data)
    }
    const handleEmail = (e) => {
        actions.email.action(true)
        actions.emailed.action(data)
    }

    const Content = () => {
        return data.status === 'saved' ?
        (
            <div className='w-full grid grid-cols-4 gap-x-3'>
                <button type="button" onClick={handlePreview} className='inline-flex justify-center items-center gap-x-2 px-2 py-1 text-white bg-sky-700' >
                    <Icon name="view" size="16"/>
                    <span>Görüntüle</span>
                </button>
                <button type="button" onClick={handleApprove} className='inline-flex justify-center items-center gap-x-2 px-2 py-1 text-white bg-emerald-700' >
                    <Icon name="success" size="16"/>
                    <span>Onayla</span>
                </button>
                <Link to={`/invoices/${rowId}/update`} className='inline-flex justify-center items-center gap-x-2 px-2 py-1 text-white bg-amber-700' >
                    <Icon name="documentEdit" size="16"/>
                    <span>Düzenle</span>
                </Link>
                <button type="button" onClick={handleDelete} className='inline-flex justify-center items-center gap-x-2 px-2 py-1 text-white bg-red-700' >
                    <Icon name="delete" size="16"/>
                    <span>Sil</span>
                </button>
            </div>
        ) :
        (
            <div className='w-full grid grid-cols-3 gap-x-3'>
                <button type="button" onClick={handlePreview} className='inline-flex justify-center items-center gap-x-2 px-2 py-1 text-white bg-sky-700' >
                    <Icon name="view" size="16"/>
                    <span>Görüntüle</span>
                </button>
                <button type="button" onClick={handleEmail} className='inline-flex justify-center items-center gap-x-2 px-2 py-1 text-white bg-indigo-700' >
                    <Icon name="mailFilled" size="16"/>
                    <span>E-Posta</span>
                </button>
                <button type="button" onClick={handleCancel} className='inline-flex justify-center items-center gap-x-2 px-2 py-1 text-white bg-red-700' >
                    <Icon name="close" size="16"/>
                    <span>İptal Et</span>
                </button>
            </div>
        )}

    return (<Content />)
};

export default TableActions
