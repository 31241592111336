import {useState, useEffect} from 'react'
import {useForm} from "react-hook-form"
import { useSearchParams, useLocation } from 'react-router-dom';
import GetRequest from 'components/GetRequest';
import InputWithLabel from 'elements/InputWithLabel'
import ReportTable from './ReportTable';

const InvoiceReport = () => {
  const getCurrentDate = () => {
    var date = new Date()
    var d = date.getDate()
    var m = date.getMonth() + 1
    var y = date.getFullYear()
    return '' + y + '-' + (m<=9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d)
  }
  const getStartDate = () => {
    var date = new Date()
    var d = date.getDate()
    var m = date.getMonth() === 0 ? 12 : date.getMonth()
    var y = date.getMonth() === 0 ? date.getFullYear() -1 : date.getFullYear()
    return '' + y + '-' + (m<=9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d)
  }

  const {register, handleSubmit, formState: { errors }, setValue, getValues} = useForm({
    defaultValues: {
        startDate: getStartDate(),
        endDate: getCurrentDate(),        
    },
    shouldFocusError: false
  })

  const dateInputs = {
    startDate: {
        key: "dateInput1",
        type: "date",
        name: "startDate",
        placeholder: "Zorunlu Alan",
        label: "Başlangıç *",
        register: register,
        validator: {
            required: "Bu alan zorunludur.",                 
        },
        error: errors?.startDate?.message,
    },
    endDate: {
        key: "dateInput2",
        type: "date",
        name: "endDate",
        placeholder: "Zorunlu Alan",
        label: "Bitiş *",
        register: register,
        validator: {
          required: "Bu alan zorunludur.",                 
        },
        error: errors?.endDate?.message 
    },
  }

  const [searchParams, setSearchParams] = useSearchParams()
  const [pending, setPending] = useState(false)
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState(null)
  const [pageMeta, setPageMeta] = useState(null)

  const [invoices, setInvoices] = useState(null)

  const [isOpenPreview, setIsOpenPreview] = useState(false)
  const [invoiceForPreview, setInvoiceForPreview] = useState(null)

  let { search } = useLocation();

  const getData = (start, end) => {
    setLoading(true)
    var page = searchParams.get("page")
    GetRequest(`api/invoices/report?page=${page}&startDate=${start}&endDate=${end}`)
      .then(response => {
        setInvoices(response.data.data)
        setPagination(response.data.links)
        setPageMeta({total: response.data.total, from: response.data.from, to: response.data.to, currentPage: response.data.current_page})
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  useEffect(() => {
    getData(getValues('startDate'), getValues('endDate'))
  }, [search])

  const handleForm = (data) => {
    getData(data.startDate, data.endDate)
    setSearchParams({page: 1})
  }

  const handleExcel = () => {
    setLoading(true)
    GetRequest(`api/invoices/report/excel?startDate=${getValues('startDate')}&endDate=${getValues('endDate')}`, {responseType: 'blob'})
      .then(response => {
        const temp = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = temp
        link.setAttribute('download', 'rapor.xlsx')
        document.body.appendChild(link)
        link.click()
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  return (
    <div className='text-center mb-6 mt-3'>
            
      <div className='py-2 border-b-2 border-gray-400 flex items-center justify-between'>
          <p className='text-gray-800 text-xl'>Muhasebe Raporu</p>
      </div>
      <div className=''>
        <form onSubmit={handleSubmit(handleForm)}>
          <div className='lg:grid lg:grid-cols-6 gap-4'>
              <div className='col-span-1'>
                <InputWithLabel {...dateInputs["startDate"]} addClass="mt-2" />
              </div>
              <div className='col-span-1'>
                <InputWithLabel {...dateInputs["endDate"]} addClass="mt-2" />
              </div>
              <div className='col-span-4 flex items-end justify-between lg:justify-start mb-1 mt-4 lg:mt-0 gap-4'>
                <button type="submit" className="text-white bg-emerald-500 px-2 py-1 border border-emerald-500 min-w-[120px]" >Faturaları Göster</button>
                <button type="button" onClick={handleExcel} className="text-white bg-rose-500 px-2 py-1 border border-rose-500 min-w-[120px]" >Excel İndir</button>
              </div>              
          </div>
        </form>
      </div>
      <div className='mt-4'>
          <div className='w-full flex justify-start mb-2'>
          

          </div>
          <ReportTable data={invoices} 
              actions={
                {}
              } 
              progressPending={pending}
              pagination={pagination}
              pageMeta={pageMeta}
          /> 
      </div>
    </div>
  )
}

export default InvoiceReport