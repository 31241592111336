import {useEffect, useState} from 'react';
import TableActions from './TableActions';
import TableLayout from 'layouts/TableLayout';


const Table = ({data, actions, progressPending, pagination, pageMeta}) => {
    const columns = [
        {
            name: 'Şirket Adı',
            selector: (row) => {return row.name},
            headerMobileHidden: false,
        },
        {
            name: 'Vergi No',
            selector: (row) => {return row.taxNo},
            headerMobileHidden: false,
        },
        {
            name: 'Vergi Dairesi',
            selector: (row) => {return row.taxDepartment},
            headerMobileHidden: false,
        },
        {
            name: 'Telefon',
            selector: (row) => {return row.phone},
            headerMobileHidden: false,
        },
        {
            name: 'E-Posta',
            selector: (row) => {return row.email},
            headerMobileHidden: false,
        },
        {
            name: 'Adres',
            selector: (row) => {return row.address},
            headerMobileHidden: false,
        },
        {
            name: 'İşlemler',
            selector: (row) => {return <TableActions rowId={row.id} actions={actions} data={row}/>},
            headerMobileHidden: true,
        },
    ];

    const mobileHeader = (row) => {return row.name}

    return (
        <>
        <TableLayout columns={columns} data={data} progressPending={progressPending} pagination={pagination} pageMeta={pageMeta} mobileHeader={mobileHeader} />
        </>
    );
};

export default Table;
