import {useSanctum} from 'react-sanctum'


 
function AuthStatus() {
    const { authenticated} = useSanctum()

    if(authenticated === true) {
        return (
            <div className="text-green-700">Authenticated</div>
        )
    } else {
        return (
            <div className="text-red-700">Unauthenticated</div>
        )
    }


}

export default AuthStatus