import {useEffect} from 'react'
import ButtonBigWithAction from 'elements/ButtonBigWithAction';
import { Icon } from 'elements/Icons';

const InfoModal = ({isOpen, isOpenAction, id, title, cancelButton = false, actions = null, children, success = true}) => {

    useEffect(() => {
        const modal = document.getElementById(id)
        const visibleClass = ['translate-y-0', 'opacity-100']
        const hiddenClass = ['-translate-y-full', 'opacity-0']
        if(isOpen) {
            modal.classList.add(...visibleClass)
            modal.classList.remove(...hiddenClass)
        } else {
            modal.classList.remove(...visibleClass)
            modal.classList.add(...hiddenClass)
        }
    })

    const handleClose = () => {
        isOpenAction(false)
    }

    return (
        <div className='absolute inset-0 transform flex items-center justify-center -translate-y-full transition ease-in-out duration-300' id={id}>
            <div className='absolute inset-0 bg-white opacity-75 w-full h-full'>    
            </div>
            <div className='w-full max-w-3xl bg-gray-800 drop-shadow-lg border border-gray-300 z-10'>
                <div className='px-4 py-4 border-b border-gray-100 text-gray-100 flex items-center justify-between'>
                    <p className='text-lg'>{title}</p>
                    <button className='' onClick={handleClose}><Icon name="close" size="28"/></button>
                </div>
                <div className='max-h-[80vh] flex flex-col'>
                    <div className='p-4 bg-gray-100 overflow-y-auto'>
                        <div className='w-full flex flex-col items-center justify-center'>
                            {success ? 
                                (<div className='flex justify-center text-green-600'>
                                    <Icon name='success' size='80' />
                                </div>)
                                : (<div className='flex justify-center text-red-600'>
                                        <Icon name='error' size='80' />
                                    </div>)
                            }

                            {children}

                        </div>
                        
                    </div>
                    <div className='px-4 py-4 bg-gray-300 border-t border-gray-100 flex items-center justify-end gap-x-4'>
                        {cancelButton && 
                            <ButtonBigWithAction type="button" addClass="text-white bg-red-500" onClick={handleClose} title="Kapat" />
                        }
                        {actions}
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default InfoModal
