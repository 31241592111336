export default [
  { key: "Türkiye", value: "Türkiye" },
  { key: "Abd Minor Outlying A", value: "Abd Minor Outlying A" },
  { key: "Abd Virjin Adaları", value: "Abd Virjin Adaları" },
  { key: "Afganistan", value: "Afganistan" },
  { key: "Almanya", value: "Almanya" },
  { key: "Amerika Birleşik Dev", value: "Amerika Birleşik Dev" },
  { key: "Amerika Samoasi", value: "Amerika Samoasi" },
  { key: "Andorra", value: "Andorra" },
  { key: "Angola", value: "Angola" },
  { key: "Anguilla", value: "Anguilla" },
  { key: "Antartika", value: "Antartika" },
  { key: "Antigua Ve Bermuda", value: "Antigua Ve Bermuda" },
  { key: "Arjantin", value: "Arjantin" },
  { key: "Arnavutluk", value: "Arnavutluk" },
  { key: "Aruba", value: "Aruba" },
  { key: "Avustralya", value: "Avustralya" },
  { key: "Avustralya Okyanusu", value: "Avustralya Okyanusu" },
  { key: "Avusturya", value: "Avusturya" },
  { key: "Azerbeycan-Nahçivan", value: "Azerbeycan-Nahçivan" },
  { key: "Bahamalar", value: "Bahamalar" },
  { key: "Bahreyn", value: "Bahreyn" },
  { key: "Bangladeş", value: "Bangladeş" },
  { key: "Barbados", value: "Barbados" },
  { key: "Belçika", value: "Belçika" },
  { key: "Belize", value: "Belize" },
  { key: "Benin", value: "Benin" },
  { key: "Bermuda", value: "Bermuda" },
  { key: "Beyaz Rusya", value: "Beyaz Rusya" },
  { key: "Bhutan", value: "Bhutan" },
  { key: "Bolivya", value: "Bolivya" },
  { key: "Bosna-Hersek", value: "Bosna-Hersek" },
  { key: "Bostvana", value: "Bostvana" },
  { key: "Bouvet Adası", value: "Bouvet Adası" },
  { key: "Brezilya", value: "Brezilya" },
  { key: "Brunei", value: "Brunei" },
  { key: "Bulgaristan", value: "Bulgaristan" },
  { key: "Burkina Faso", value: "Burkina Faso" },
  { key: "Burma", value: "Burma" },
  { key: "Burundi", value: "Burundi" },
  { key: "Birleşik Arap Emirli", value: "Birleşik Arap Emirli" },
  { key: "Birleşik Devletler M", value: "Birleşik Devletler M" },
  { key: "Birleşik Krallik", value: "Birleşik Krallik" },
  { key: "Bismark Archipelago", value: "Bismark Archipelago" },
  { key: "Cape Verde", value: "Cape Verde" },
  { key: "Cayman Adaları", value: "Cayman Adaları" },
  { key: "Cebeli Tarik", value: "Cebeli Tarik" },
  { key: "Ceuta Ve Melilla", value: "Ceuta Ve Melilla" },
  { key: "Cezayir", value: "Cezayir" },
  { key: "Christmas Adaları", value: "Christmas Adaları" },
  { key: "Cook Adaları", value: "Cook Adaları" },
  { key: "Cibuti", value: "Cibuti" },
  { key: "Çad", value: "Çad" },
  { key: "Çek Cumhuriyeti", value: "Çek Cumhuriyeti" },
  { key: "Çeçen Cumhuriyeti", value: "Çeçen Cumhuriyeti" },
  { key: "Çin Halk Cumhuriyeti", value: "Çin Halk Cumhuriyeti" },
  { key: "Danimarka", value: "Danimarka" },
  { key: "Dağistan Cumhuriyeti", value: "Dağistan Cumhuriyeti" },
  { key: "Dominik Cumhuriyeti", value: "Dominik Cumhuriyeti" },
  { key: "Dominika", value: "Dominika" },
  { key: "Doğu Timor", value: "Doğu Timor" },
  { key: "Dubai", value: "Dubai" },
  { key: "Ekvator", value: "Ekvator" },
  { key: "Ekvator Ginesi", value: "Ekvator Ginesi" },
  { key: "El Salvador", value: "El Salvador" },
  { key: "Endonezya", value: "Endonezya" },
  { key: "Ermenistan", value: "Ermenistan" },
  { key: "Eritre", value: "Eritre" },
  { key: "Estonya", value: "Estonya" },
  { key: "Etiyopya", value: "Etiyopya" },
  { key: "Falkland Adaları", value: "Falkland Adaları" },
  { key: "Faroe Adaları", value: "Faroe Adaları" },
  { key: "Fas", value: "Fas" },
  { key: "Fransa", value: "Fransa" },
  { key: "Fransiz Guyanasi", value: "Fransiz Guyanasi" },
  { key: "Fransiz Güney Toprak", value: "Fransiz Güney Toprak" },
  { key: "Fransiz Polinezyasi", value: "Fransiz Polinezyasi" },
  { key: "Fiji", value: "Fiji" },
  { key: "Fildişi Sahili", value: "Fildişi Sahili" },
  { key: "Filipinler", value: "Filipinler" },
  { key: "Filistin", value: "Filistin" },
  { key: "Finlandiya", value: "Finlandiya" },
  { key: "Gabon", value: "Gabon" },
  { key: "Gambiya", value: "Gambiya" },
  { key: "Gana", value: "Gana" },
  { key: "Grenada", value: "Grenada" },
  { key: "Grönland", value: "Grönland" },
  { key: "Guadelup", value: "Guadelup" },
  { key: "Guam", value: "Guam" },
  { key: "Guatemala", value: "Guatemala" },
  { key: "Guyana", value: "Guyana" },
  { key: "Güney Afrika Cumhuri", value: "Güney Afrika Cumhuri" },
  { key: "Güney Fransiz Toprak", value: "Güney Fransiz Toprak" },
  { key: "Güney Georgia Ve Gün", value: "Güney Georgia Ve Gün" },
  { key: "Güney Kore Cumhuriye", value: "Güney Kore Cumhuriye" },
  { key: "Güney Yemen", value: "Güney Yemen" },
  { key: "Gürcistan", value: "Gürcistan" },
  { key: "Gine", value: "Gine" },
  { key: "Gine-Bissau", value: "Gine-Bissau" },
  { key: "Haiti", value: "Haiti" },
  { key: "Heard Adaları Ve Mc", value: "Heard Adaları Ve Mc" },
  { key: "Hirvatistan", value: "Hirvatistan" },
  { key: "Hollanda", value: "Hollanda" },
  { key: "Hollanda Antilleri", value: "Hollanda Antilleri" },
  { key: "Honduras", value: "Honduras" },
  { key: "Hong Kong", value: "Hong Kong" },
  { key: "Hindistan", value: "Hindistan" },
  { key: "Irak", value: "Irak" },
  { key: "İngiliz Hint Oky.Top", value: "İngiliz Hint Oky.Top" },
  { key: "İngiliz Virgin Adala", value: "İngiliz Virgin Adala" },
  { key: "İran", value: "İran" },
  { key: "İrlanda", value: "İrlanda" },
  { key: "İspanya", value: "İspanya" },
  { key: "İsrail", value: "İsrail" },
  { key: "İsveç", value: "İsveç" },
  { key: "İsviçre", value: "İsviçre" },
  { key: "İtalya", value: "İtalya" },
  { key: "İzlanda", value: "İzlanda" },
  { key: "İşgal Altindaki Fili", value: "İşgal Altindaki Fili" },
  { key: "Jamaika", value: "Jamaika" },
  { key: "Japonya", value: "Japonya" },
  { key: "Kamboçya", value: "Kamboçya" },
  { key: "Kamerun", value: "Kamerun" },
  { key: "Kanada", value: "Kanada" },
  { key: "Karadağ", value: "Karadağ" },
  { key: "Katar", value: "Katar" },
  { key: "Kazakistan", value: "Kazakistan" },
  { key: "Kenya", value: "Kenya" },
  { key: "Kibris Rum Kesimi", value: "Kibris Rum Kesimi" },
  { key: "Kirgizistan", value: "Kirgizistan" },
  { key: "Kokos Adaları", value: "Kokos Adaları" },
  { key: "Kolombiya", value: "Kolombiya" },
  { key: "Komoro Adaları", value: "Komoro Adaları" },
  { key: "Kongo", value: "Kongo" },
  { key: "Kosova", value: "Kosova" },
  { key: "Kosta Rika", value: "Kosta Rika" },
  { key: "Kutup Bölgeleri", value: "Kutup Bölgeleri" },
  { key: "Kuveyt", value: "Kuveyt" },
  { key: "Kuzey Kibris Türk Cu", value: "Kuzey Kibris Türk Cu" },
  { key: "Kuzey Kore Demokrati", value: "Kuzey Kore Demokrati" },
  { key: "Kuzey Mariana Adalar", value: "Kuzey Mariana Adalar" },
  { key: "Kuzey Yemen", value: "Kuzey Yemen" },
  { key: "Kongo (Demokratik Cu)", value: "Kongo (Demokratik Cu)" },
  { key: "Küba", value: "Küba" },
  { key: "Kiribati", value: "Kiribati" },
  { key: "Laos", value: "Laos" },
  { key: "Lesotho", value: "Lesotho" },
  { key: "Letonya", value: "Letonya" },
  { key: "Lieshtenstein", value: "Lieshtenstein" },
  { key: "Lübnan", value: "Lübnan" },
  { key: "Lüksemburg", value: "Lüksemburg" },
  { key: "Liberya", value: "Liberya" },
  { key: "Libya", value: "Libya" },
  { key: "Litvanya", value: "Litvanya" },
  { key: "Macaristan", value: "Macaristan" },
  { key: "Madagaskar", value: "Madagaskar" },
  { key: "Makao", value: "Makao" },
  { key: "Makedonya", value: "Makedonya" },
  { key: "Malavi", value: "Malavi" },
  { key: "Maldiv Adaları", value: "Maldiv Adaları" },
  { key: "Malezya", value: "Malezya" },
  { key: "Malta", value: "Malta" },
  { key: "Mali", value: "Mali" },
  { key: "Marshall Adaları", value: "Marshall Adaları" },
  { key: "Martinik", value: "Martinik" },
  { key: "Mauritius", value: "Mauritius" },
  { key: "Mayotte", value: "Mayotte" },
  { key: "Meksika", value: "Meksika" },
  { key: "Melilla", value: "Melilla" },
  { key: "Misir", value: "Misir" },
  { key: "Moldavya", value: "Moldavya" },
  { key: "Monaco", value: "Monaco" },
  { key: "Montserrat", value: "Montserrat" },
  { key: "Moritanya", value: "Moritanya" },
  { key: "Mozambik", value: "Mozambik" },
  { key: "Moğolistan", value: "Moğolistan" },
  { key: "Myanmar", value: "Myanmar" },
  { key: "Mikronezya", value: "Mikronezya" },
  { key: "Namibya", value: "Namibya" },
  { key: "Nauru", value: "Nauru" },
  { key: "Nepal", value: "Nepal" },
  { key: "Norfolk Adası", value: "Norfolk Adası" },
  { key: "Norveç", value: "Norveç" },
  { key: "Nijer", value: "Nijer" },
  { key: "Nijerya", value: "Nijerya" },
  { key: "Nikaragua", value: "Nikaragua" },
  { key: "Niue", value: "Niue" },
  { key: "Orta Afrika Cumhuriy", value: "Orta Afrika Cumhuriy" },
  { key: "Özbekistan", value: "Özbekistan" },
  { key: "Pakistan", value: "Pakistan" },
  { key: "Palau", value: "Palau" },
  { key: "Panama", value: "Panama" },
  { key: "Papua Yeni Gine", value: "Papua Yeni Gine" },
  { key: "Paraguay", value: "Paraguay" },
  { key: "Peru", value: "Peru" },
  { key: "Polonya", value: "Polonya" },
  { key: "Portekiz", value: "Portekiz" },
  { key: "Pitcairn", value: "Pitcairn" },
  { key: "Reunion", value: "Reunion" },
  { key: "Romanya", value: "Romanya" },
  { key: "Ruanda", value: "Ruanda" },
  { key: "Rusya Federasyonu", value: "Rusya Federasyonu" },
  { key: "San Marino", value: "San Marino" },
  { key: "Sao Tome And Princip", value: "Sao Tome And Princip" },
  { key: "Senegal", value: "Senegal" },
  { key: "Septe(Ceuta)", value: "Septe(Ceuta)" },
  { key: "Seyşel Adaları Ve Ba", value: "Seyşel Adaları Ve Ba" },
  { key: "Sierra Leone", value: "Sierra Leone" },
  { key: "Sirbistan", value: "Sirbistan" },
  { key: "Slovakya", value: "Slovakya" },
  { key: "Slovenya", value: "Slovenya" },
  { key: "Solomon Adaları", value: "Solomon Adaları" },
  { key: "Somali", value: "Somali" },
  { key: "Sri Lanka", value: "Sri Lanka" },
  { key: "St Vincent Ve Grenad", value: "St Vincent Ve Grenad" },
  { key: "St. Helena Ve Bağlan", value: "St. Helena Ve Bağlan" },
  { key: "St. Kitts Ve Nevis", value: "St. Kitts Ve Nevis" },
  { key: "St. Lucia", value: "St. Lucia" },
  { key: "St. Pierre Ve Miquel", value: "St. Pierre Ve Miquel" },
  { key: "Sudan", value: "Sudan" },
  { key: "Surinam", value: "Surinam" },
  { key: "Suriye", value: "Suriye" },
  { key: "Suudi Arabistan", value: "Suudi Arabistan" },
  { key: "Svaziland", value: "Svaziland" },
  { key: "Samoa (Batı Samoa)", value: "Samoa (Batı Samoa)" },
  { key: "Serbest Bölge (Ba-Bs)", value: "Serbest Bölge (Ba-Bs)" },
  { key: "Singapur", value: "Singapur" },
  { key: "Şili", value: "Şili" },
  { key: "Tacikistan", value: "Tacikistan" },
  { key: "Tayland", value: "Tayland" },
  { key: "Tayvan", value: "Tayvan" },
  { key: "Togo", value: "Togo" },
  { key: "Tokelau", value: "Tokelau" },
  { key: "Tonga", value: "Tonga" },
  { key: "Trinidad Ve Tobago", value: "Trinidad Ve Tobago" },
  { key: "Tunus", value: "Tunus" },
  { key: "Turks Ve Caicos Adas", value: "Turks Ve Caicos Adas" },
  { key: "Tuvalu", value: "Tuvalu" },
  { key: "Tanzanya (Birleşik C)", value: "Tanzanya (Birleşik C)" },
  { key: "Türkmenistan", value: "Türkmenistan" },
  { key: "Uganda", value: "Uganda" },
  { key: "Ukrayna", value: "Ukrayna" },
  { key: "Umman", value: "Umman" },
  { key: "Uruguay", value: "Uruguay" },
  { key: "Ürdün", value: "Ürdün" },
  { key: "Vanuatu", value: "Vanuatu" },
  { key: "Vatikan", value: "Vatikan" },
  { key: "Venezuella", value: "Venezuella" },
  { key: "Vietnam", value: "Vietnam" },
  { key: "Wallis Ve Futuna Ada", value: "Wallis Ve Futuna Ada" },
  { key: "Yakutistan", value: "Yakutistan" },
  { key: "Yemen", value: "Yemen" },
  { key: "Yeni Kalodenya Ve Ba", value: "Yeni Kalodenya Ve Ba" },
  { key: "Yeni Zelanda", value: "Yeni Zelanda" },
  { key: "Yeni Zelanda Okyanus", value: "Yeni Zelanda Okyanus" },
  { key: "Yugoslavya(Eski Yugo)", value: "Yugoslavya(Eski Yugo)" },
  { key: "Yugoslavya(Sirbistan)", value: "Yugoslavya(Sirbistan)" },
  { key: "Yunanistan", value: "Yunanistan" },
  { key: "Zambia", value: "Zambia" },
  { key: "Zimbabve", value: "Zimbabve" },
];
