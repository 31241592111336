import React from 'react'
import { Link } from 'react-router-dom';
import { Icon } from 'elements/Icons';

const TableActionsForDraft = ({rowId, actions, data}) => {

    const handleDelete = (e) => {
        actions.delete.action(true)
        actions.deleted.action(data)
    }    

    const Content = () => {
        return (
            <div className='w-full grid grid-cols-2 gap-x-3'>
                <button type="button" onClick={handleDelete} className='inline-flex justify-center items-center gap-x-2 px-2 py-1 text-white bg-red-700' >
                    <Icon name="delete" size="16"/>
                    <span>Şablonu Sil</span>
                </button>
                <Link to={'/invoices/create?draftId='+rowId} className='inline-flex justify-center items-center gap-x-2 px-2 py-1 text-white bg-sky-700' >
                    <span className='-rotate-90'>
                        <Icon name="chewronDownFilled" size="16"/>
                    </span>
                    <span>Şablondan Fatura Oluştur</span>
                </Link>
            </div>
        ) }

    return (<Content />)
};

export default TableActionsForDraft
