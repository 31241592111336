import React from 'react'
import { Icon } from 'elements/Icons';

const TableActionsForCancelled = ({rowId, actions, data}) => {

    const handlePreview = (e) => {
        actions.preview.action(true)
        actions.previewed.action(data)
    }    

    const Content = () => {
        return (
            <div className='w-full grid grid-cols-1 gap-x-3'>
                <button type="button" onClick={handlePreview} className='inline-flex justify-center items-center gap-x-2 px-2 py-1 text-white bg-sky-700' >
                    <Icon name="view" size="16"/>
                    <span>Görüntüle</span>
                </button>
            </div>
        ) }

    return (<Content />)
};

export default TableActionsForCancelled
