import {useState, useEffect} from 'react'
import {DebounceInput} from 'react-debounce-input';
import { useSearchParams, useLocation } from 'react-router-dom';
import IssuedTable from './IssuedTable';
import GetRequest from 'components/GetRequest';
import Preview from './Preview';
import DeleteInvoice from './DeleteInvoice';
import Approve from './Approve';
import CancelInvoice from './CancelInvoice';
import Email from './Email';

const InvoiceIssued = () => {

  const [searchParams, setSearchParams] = useSearchParams()
  const [pending, setPending] = useState(false)
  const [pagination, setPagination] = useState(null)
  const [pageMeta, setPageMeta] = useState(null)
  const [exSearch, setExSearch] = useState(null)

  const [invoices, setInvoices] = useState(null)
  const [updatedInvoices, setUpdatedInvoices] = useState(false)

  const [isOpenPreview, setIsOpenPreview] = useState(false)
  const [invoiceForPreview, setInvoiceForPreview] = useState(null)
  const [isOpenDelete, setIsOpenDelete] = useState(false)
  const [invoiceForDelete, setInvoiceForDelete] = useState(null)
  const [isOpenCancel, setIsOpenCancel] = useState(false)
  const [invoiceForCancel, setInvoiceForCancel] = useState(null)
  const [isOpenApprove, setIsOpenApprove] = useState(false)
  const [invoiceForApprove, setInvoiceForApprove] = useState(null)
  const [isOpenEmail, setIsOpenEmail] = useState(false)
  const [invoiceForEmail, setInvoiceForEmail] = useState(null)

  let { search } = useLocation();

  useEffect(() => {
    setPending(true)
    var page = searchParams.get("page")
    var searchPa = searchParams.get("search") || ''
    if(searchPa != null && exSearch != searchPa) {
        page = "1"
        setSearchParams({page: 1, search: searchPa})
    }
    GetRequest('api/invoices?page='+page+'&search='+searchPa)
    .then((response) => {
        setInvoices(response.data.data)
        setPagination(response.data.links)
        setPageMeta({total: response.data.total, from: response.data.from, to: response.data.to, currentPage: response.data.current_page})
        setExSearch(searchPa)
        setPending(false)
    })
    .catch(error => {
        setInvoices(error.response.data)
        setPending(false)
    })
}, [updatedInvoices, search])

  return (
    <div className='text-center mb-6 mt-3'>
            
      <div className='py-2 border-b-2 border-gray-400 flex items-center justify-between'>
          <p className='text-gray-800 text-xl'>Kesilen Faturalar</p>
      </div>
      <div className='mt-4'>
          <div className='w-full flex justify-start mb-2'>
          <DebounceInput
              minLength={4}
              debounceTimeout={500}
              onChange={event =>setSearchParams({page: searchParams.get("page"),search: event.target.value})} 
              className="form-input w-full lg:max-w-[250px] rounded px-2 py-1 text-sm"
              placeholder="Fatura No, Müşteri ve VKN İçerisinde Ara"
              value={searchParams.get("search")} />

          </div>
          <IssuedTable data={invoices} 
              actions={
                {
                    preview: {status: isOpenPreview, action: setIsOpenPreview}, 
                    previewed: {status: invoiceForPreview, action: setInvoiceForPreview}, 
                    delete: {status: isOpenDelete, action: setIsOpenDelete},
                    deleted: {status: invoiceForDelete, action: setInvoiceForDelete},
                    approve: {status: isOpenApprove, action: setIsOpenApprove},
                    approved: {status: invoiceForApprove, action: setInvoiceForApprove},
                    cancel: {status: isOpenCancel, action: setIsOpenCancel},
                    cancelled: {status: invoiceForCancel, action: setInvoiceForCancel},
                    email: {status: isOpenEmail, action: setIsOpenEmail},
                    emailed: {status: invoiceForEmail, action: setInvoiceForEmail},
                }
              } 
              progressPending={pending}
              pagination={pagination}
              pageMeta={pageMeta}
          /> 
      </div>
      <Preview isOpen={isOpenPreview} isOpenAction={setIsOpenPreview} selectedData={invoiceForPreview}/>
      <DeleteInvoice isOpen={isOpenDelete} isOpenAction={setIsOpenDelete} updateStatus={updatedInvoices} updatedAction={setUpdatedInvoices} selectedData={invoiceForDelete}/>
      <Approve isOpen={isOpenApprove} isOpenAction={setIsOpenApprove} updateStatus={updatedInvoices} updatedAction={setUpdatedInvoices} selectedData={invoiceForApprove}/>
      <CancelInvoice isOpen={isOpenCancel} isOpenAction={setIsOpenCancel} updateStatus={updatedInvoices} updatedAction={setUpdatedInvoices} selectedData={invoiceForCancel}/>
      <Email isOpen={isOpenEmail} isOpenAction={setIsOpenEmail} updateStatus={updatedInvoices} updatedAction={setUpdatedInvoices} selectedData={invoiceForEmail}/>
    </div>
  )
}

export default InvoiceIssued