import {useEffect, useState} from 'react'
import {useForm} from "react-hook-form"
import TextareaWithLabel from 'elements/TextareaWithLabel'
import ButtonBigWithAction from 'elements/ButtonBigWithAction'
import LoadingIndicator from 'pages/LoadingIndicator'
import GetRequest from 'components/GetRequest'
import PostRequest from 'components/PostRequest'
import DeleteRequest from 'components/DeleteRequest'

const StaticNote = () => {

    const {register, handleSubmit, reset, formState: { errors }, setValue} = useForm({
        defaultValues: {
            note: null,
        }
    })

    const inputs = [
        {
            key: "ibanInput1",
            type: "textarea",
            name: "note",
            placeholder: "Zorunlu Alan",
            label: "Sabit Not *",
            register: register,
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 4, 
                            message: "En az 4 karakter olmalı"}, 
                maxLength: {value: 1000, 
                            message:"En fazla 1000 karakter olmalı"}
            },
            error: errors?.note?.message 
        },
    ]

    const [saveStatus, setSaveStatus] = useState()
    const [loading, setLoading] = useState(false)
    const [updatedNoteList, setUpdatedNoteList] = useState(false)
    const [staticNotes, setStaticNotes] = useState([])

    useEffect(() => {
        setLoading(true)
        GetRequest('api/staticNotes')
            .then(response => {
                var notes = response.data.map(value => {
                    return {note: value.note, id: value.id}
                })
                setStaticNotes(notes)
                setLoading(false)
            })
            .catch(error => {
                console.log(error.response)
                setStaticNotes([])
                setLoading(false)
            })
    }, [updatedNoteList])

    

    const handleForm = (data) => {
        setLoading(true)
        PostRequest('api/staticNotes', data)
            .then(response => {
                
                const successMessage = (
                    <div className='text-sm text-green-100 bg-green-600 text-bold mb-2 p-1'>
                        <p>Bilgiler başarıyla kaydedildi.</p> 
                    </div>
                )
                setSaveStatus(successMessage)
                setUpdatedNoteList(!updatedNoteList)
                setLoading(false)
            })
            .catch(error => {
                const err = error.response.data.errors
                if(err) {
                    const errorItems = Object.keys(err).map((value, index) => {
                        return (
                            <li key={value.toString()}>{err[value][0]}</li>
                        )
                    })
                    const errorMessage = (
                        <div className='text-sm text-red-600'>
                            <p>{error.response.data.message}</p>   
                            <ul className='list-[circle] list-inside'>
                                {errorItems}
                            </ul>                     
                        </div>
                    )
                    setSaveStatus(errorMessage)
                } else {
                    const errorMessage = (
                        <div className='text-sm text-red-100 bg-red-600 text-bold mb-2 p-1'>
                            <p>{`${error.response.statusText} (${error.response.status})`}</p> 
                        </div>
                    )   
                    
                    setSaveStatus(errorMessage)
                }  
                setLoading(false)
            })
    }

    const handleDelete = (id) => {
        setLoading(true)
        DeleteRequest('api/staticNotes/'+id)
            .then(response => {
                setUpdatedNoteList(!updatedNoteList)
                setLoading(false)
            })
            .catch(error => {
                const err = error.response.data.errors
                if(err) {
                    const errorItems = Object.keys(err).map((value, index) => {
                        return (
                            <li key={value.toString()}>{err[value][0]}</li>
                        )
                    })
                    const errorMessage = (
                        <div className='text-sm text-red-600'>
                            <p>{error.response.data.message}</p>   
                            <ul className='list-[circle] list-inside'>
                                {errorItems}
                            </ul>                     
                        </div>
                    )
                    setSaveStatus(errorMessage)
                } else {
                    const errorMessage = (
                        <div className='text-sm text-red-100 bg-red-600 text-bold mb-2 p-1'>
                            <p>{`${error.response.statusText} (${error.response.status})`}</p> 
                        </div>
                    )   
                    
                    setSaveStatus(errorMessage)
                }  
                setLoading(false)
            })
    }
    return (
        <div className='lg:grid lg:grid-cols-2 lg:gap-8'>
            <div className='text-left mt-12'>
                <h2 className='text-2xl font-semibold text-gray-700 my-4 '>Sabit Not Bilgileri</h2>
                <p className='text-md text-gray-700'> Bu alandan faturanızda görünmesini istediğiniz sabit notları kaydedebilirisiniz. Kaydetmiş olduğunuz sabit notlar yeni fatura keserken fatura notu alanında otomatik olarak görünecektir. Faturayı kesmeden önce fatura kesme ekranındaki not alanından bu notları düzenleyebilir veya silebilirsiniz.</p>
            </div>
            <div className='text-left'>
                <div className='h-12'>
                    {saveStatus}
                </div>
                <form onSubmit={handleSubmit(handleForm)}>
                {inputs.map((input, index) => {
                            return (
                                <div key={`inputContainer${index}`}>                                    
                                        <TextareaWithLabel {...input} addClass="mt-2"/>
                                </div>
                            )
                        })  }
                    <ButtonBigWithAction type="submit" addClass="text-white bg-emerald-500 w-full max-w-[400px]" title="Kaydet" />
                </form>
                <div className='mt-4'>
                    <h3 className='pb-1 border-b border-gray-500 text-gray-700'>Kayıtlı Notlar</h3>
                    <table className='w-full text-sm'>                        
                        <thead>
                            <tr className='text-center'>
                                <th>Not</th>
                                <th>Sil</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(staticNotes).length > 0 
                            ? staticNotes.map(value => {
                                return (
                                    <tr key={`bankinfo${value.id}`}>
                                        <td>{value.note}</td>
                                        <td className='text-center'>
                                            <button className='py-1 px-10 bg-red-600 text-red-50' onClick={() => handleDelete(value.id)}>Sil</button>
                                        </td>
                                    </tr>
                                )
                            })
                            : <tr><td>Kayıtlı not bulunamadı.</td></tr>
                            }
                        </tbody>                        
                    </table>
                </div>
            </div>
            
            {loading && <LoadingIndicator />}
                    
        </div>
    )
}

export default StaticNote