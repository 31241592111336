
const SelectWithLabelSmall = ({type, name, placeholder, label, register, validator, error, addClass = null, readOnly = false, data}) => {
  const borderColor = error ? 'border-red-500' : 'border-gray-400'
  return (    
      <div className='flex flex-col gap-y-1 items-start'>
          <label htmlFor={name} className={['text-gray-700 text-xs', addClass].join(' ')}>{label}</label>
          <select 
              name={name}
              placeholder={placeholder} 
              {...register(name, validator)}
              className={['form-input w-full rounded-sm px-1 py-1 text-sm border', borderColor].join(' ')} 
              readOnly={readOnly && 'readOnly'}>
                <option key="0" value="">Seçiniz</option>  
                {data.map(data => {
                  return <option key={data.key} value={data.key}>{data.value}</option>
                })}
            </select>
          <span className="text-sm text-red-600">{error}</span>
      </div>
  );
};

export default SelectWithLabelSmall;
