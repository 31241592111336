import {useState, useEffect} from 'react'
import {DebounceInput} from 'react-debounce-input';
import { useSearchParams, useLocation } from 'react-router-dom';
import GetRequest from 'components/GetRequest';
import Preview from './Preview';
import CancelledTable from './CancelledTable';

const InvoiceCancelled = () => {

  const [searchParams, setSearchParams] = useSearchParams()
  const [pending, setPending] = useState(false)
  const [pagination, setPagination] = useState(null)
  const [pageMeta, setPageMeta] = useState(null)
  const [exSearch, setExSearch] = useState(null)

  const [invoices, setInvoices] = useState(null)

  const [isOpenPreview, setIsOpenPreview] = useState(false)
  const [invoiceForPreview, setInvoiceForPreview] = useState(null)

  let { search } = useLocation();

  useEffect(() => {
    setPending(true)
    var page = searchParams.get("page")
    var searchPa = searchParams.get("search") || ''
    if(searchPa != null && exSearch != searchPa) {
        page = "1"
        setSearchParams({page: 1, search: searchPa})
    }
    GetRequest('api/invoices/cancelled?page='+page+'&search='+searchPa)
    .then((response) => {
        setInvoices(response.data.data)
        setPagination(response.data.links)
        setPageMeta({total: response.data.total, from: response.data.from, to: response.data.to, currentPage: response.data.current_page})
        setExSearch(searchPa)
        setPending(false)
    })
    .catch(error => {
        setInvoices(error.response.data)
        setPending(false)
    })
  }, [search])

  return (
    <div className='text-center mb-6 mt-3'>
            
      <div className='py-2 border-b-2 border-gray-400 flex items-center justify-between'>
          <p className='text-gray-800 text-xl'>İptal Edilen Faturalar</p>
      </div>
      <div className='mt-4'>
          <div className='w-full flex justify-start mb-2'>
          <DebounceInput
              minLength={4}
              debounceTimeout={500}
              onChange={event =>setSearchParams({page: searchParams.get("page"),search: event.target.value})} 
              className="form-input w-full lg:max-w-[250px] rounded px-2 py-1 text-sm"
              placeholder="Fatura No, Müşteri ve VKN İçerisinde Ara"
              value={searchParams.get("search")} />

          </div>
          <CancelledTable data={invoices} 
              actions={
                {
                    preview: {status: isOpenPreview, action: setIsOpenPreview}, 
                    previewed: {status: invoiceForPreview, action: setInvoiceForPreview},
                }
              } 
              progressPending={pending}
              pagination={pagination}
              pageMeta={pageMeta}
          /> 
      </div>
      <Preview isOpen={isOpenPreview} isOpenAction={setIsOpenPreview} selectedData={invoiceForPreview}/>
    </div>
  )
}

export default InvoiceCancelled