export default [
  { key: "DAY", value: "Gün" },
  { key: "MON", value: "Ay" },
  { key: "ANN", value: "Yıl" },
  { key: "HUR", value: "Saat" },
  { key: "D61", value: "Dakika" },
  { key: "D62", value: "Saniye" },
  { key: "C62", value: "Adet" },
  { key: "PA", value: "Paket" },
  { key: "BX", value: "Kutu" },
  { key: "MGM", value: "mg" },
  { key: "GRM", value: "g" },
  { key: "KGM", value: "kg" },
  { key: "LTR", value: "lt" },
  { key: "TNE", value: "ton" },
  { key: "NT", value: "Net Ton" },
  { key: "GT", value: "Gross ton" },
  { key: "MMT", value: "mm" },
  { key: "CMT", value: "cm" },
  { key: "MTR", value: "m" },
  { key: "KTM", value: "km" },
  { key: "MLT", value: "ml" },
  { key: "MMQ", value: "mm3" },
  { key: "CMK", value: "cm2" },
  { key: "CMQ", value: "cm3" },
  { key: "MTK", value: "m2" },
  { key: "MTQ", value: "m3" },
  { key: "KJO", value: "kJ" },
  { key: "CLT", value: "cl" },
  { key: "CT", value: "KARAT" },
  { key: "KWH", value: "KWH" },
  { key: "MWH", value: "MWH" },
  { key: "CCT", value: "Ton baş.taşıma kap." },
  { key: "D30", value: "Brüt kalori" },
  { key: "D40", value: "1000 lt" },
  { key: "LPA", value: "Saf alkol lt" },
  { key: "B32", value: "kg.m2" },
  { key: "NCL", value: "Hücre adet" },
  { key: "PR", value: "Çift" },
  { key: "R9", value: "1000 m3" },
  { key: "SET", value: "Set" },
  { key: "T3", value: "1000 adet" },
  { key: "Q37", value: "SCM" },
  { key: "Q39", value: "NCM" },
  { key: "J39", value: "mmBTU" },
  { key: "G52", value: "CM³" },
  { key: "DZN", value: "Düzine" },
  { key: "DMK", value: "dm2" },
  { key: "DMT", value: "dm" },
  { key: "HAR", value: "ha" },
];
