import { useEffect } from "react";
import {useSanctum} from "react-sanctum"
import {useNavigate} from 'react-router-dom'

function Home() {
    let navigate = useNavigate();

    useEffect(() => {
        navigate('/invoices/issued', { replace: true })
    }, [])    

    return (
        <div>
        </div>
    )
}

export default Home 