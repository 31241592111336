import {useEffect, useState} from 'react';
import TableLayoutWithoutPagination from 'layouts/TableLayoutWithoutPagination';
import { FormatDate } from 'components/FormatDate';


const IncomingTable = ({data, actions, progressPending}) => {
    const statusNames = {
        saved: <span className='p-[2px] bg-amber-600 text-amber-50'>Onaylanmadı</span>,
        approved: <span className='p-[2px] bg-lime-600 text-lime-50'>Onaylandı</span>,
        cancelled: <span className='p-[2px] bg-rose-600 text-rose-50'>İptal Edildi</span>,
        deleted: <span className='p-[2px] bg-rose-600 text-rose-50'>Silindi</span>
    }
    const columns = [
        {
            name: 'Fatura No',
            selector: (row) => {return row.belgeNumarasi},
            headerMobileHidden: false,
        },
        {
            name: 'Müşteri Adı',
            selector: (row) => {return (row.saticiUnvanAdSoyad)},
            headerMobileHidden: false,
        },
        {
            name: 'Müşteri TCKN/VKN',
            selector: (row) => {return row.saticiVknTckn},
            headerMobileHidden: false,
        },
        {
            name: 'Tarih',
            selector: (row) => {return row.belgeTarihi},
            headerMobileHidden: false,
        },
        {
            name: 'Durum',
            selector: (row) => {return row.onayDurumu},
            headerMobileHidden: false,
        }
    ];

    const mobileHeader = (row) => {return `${row.saticiUnvanAdSoyad} (${FormatDate(row.belgeTarihi)})`}

    return (
        <>
        <TableLayoutWithoutPagination columns={columns} data={data} progressPending={progressPending} mobileHeader={mobileHeader} />
        </>
    );
};

export default IncomingTable;
