import { useLocation, Navigate } from "react-router-dom"
import { useState, useEffect } from "react"
import { useSanctum } from "react-sanctum"
import Loading from "pages/Loading"

function RequireExpire({ children }) {
    let location = useLocation()
    const [expireDateCheck, setExpireDateCheck] = useState(null)
    const { user } = useSanctum()
    useEffect(() => {
        const expireDate = new Date(user.expire_date)
        const today = new Date()
        if(today > expireDate) {
            setExpireDateCheck(false)
        } else {
            setExpireDateCheck(true)
        }
    }, [])

    if (expireDateCheck === true) {
        return children
    } else if (expireDateCheck === false) {
        return <Navigate to="/purchases" state={{ from: location }} replace />
    } else {
        return <Loading />
    }
}

export default RequireExpire