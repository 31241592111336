
const InputFloatingLabelWithValidaiton = ({type, name, register, label, validator}) => {
  return (
    <>
        <div className="relative z-0 w-full mt-6">
        <input type={type} 
            name={name} 
            {...register(name, validator)}
            placeholder=" " 
            className="pb-1 block w-full px-0 py-px mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-sky-500 border-sky-300" 
            
        />
        <label htmlFor={name} className="absolute duration-300 top-1 -z-1 origin-0 text-gray-500 mb-3">{label}</label>
        </div>
        
    </>
  );
};

export default InputFloatingLabelWithValidaiton;
