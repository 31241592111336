import {useState, useEffect} from 'react'
import GetRequest from 'components/GetRequest'
import LoadingIndicator from 'pages/LoadingIndicator'
import OldInvoiceTable from './OldInvoiceTable'
import InputWithLabel from 'elements/InputWithLabel'
import PreviewForOld from './PreviewForOld'
import {useForm} from "react-hook-form"
import { useSanctum } from "react-sanctum";

const InvoiceOld = () => {

  const { user } = useSanctum();

  const getCurrentDate = () => {
    var date = new Date(user.created_at)
    var d = date.getDate()
    var m = date.getMonth() + 1
    var y = date.getFullYear()
    return '' + y + '-' + (m<=9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d)
  }
  const getStartDate = () => {
    var date = new Date(user.created_at)
    var d = date.getDate()
    var m = date.getMonth() === 0 ? 12 : date.getMonth()
    var y = date.getMonth() === 0 ? date.getFullYear() -1 : date.getFullYear()
    return '' + y + '-' + (m<=9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d)
  }

  const {register, handleSubmit, formState: { errors }, setValue, getValues} = useForm({
    defaultValues: {
        startDate: getStartDate(),
        endDate: getCurrentDate(),        
    },
    shouldFocusError: false
  })

  const dateInputs = {
    startDate: {
        key: "dateInput1",
        type: "date",
        name: "startDate",
        placeholder: "Zorunlu Alan",
        label: "Başlangıç *",
        register: register,
        validator: {
            required: "Bu alan zorunludur.",                 
        },
        error: errors?.startDate?.message,
    },
    endDate: {
        key: "dateInput2",
        type: "date",
        name: "endDate",
        placeholder: "Zorunlu Alan",
        label: "Bitiş *",
        register: register,
        validator: {
          required: "Bu alan zorunludur.",                 
        },
        error: errors?.endDate?.message 
    },
  }


  const [pending, setPending] = useState(false)
  const [loading, setLoading] = useState(false)

  const [invoices, setInvoices] = useState(null)
  const [invoiceCount, setInvoiceCount] = useState(0)

  const [isOpenPreview, setIsOpenPreview] = useState(false)
  const [invoiceForPreview, setInvoiceForPreview] = useState(null)

  const getData = (start, end) => {
    setLoading(true)
    GetRequest(`api/invoices/old?startDate=${start}&endDate=${end}`)
      .then(response => {
        setInvoices(response.data.invoices)
        setInvoiceCount(response.data.count)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  useEffect(() => {
    getData(getValues('startDate'), getValues('endDate'))
  }, [])

  const handleForm = (data) => {
    getData(data.startDate, data.endDate)
  }
  
  return (
    <div className='text-center mb-6 mt-3'>
            
      <div className='py-2 border-b-2 border-gray-400 flex items-center justify-between'>
          <p className='text-gray-800 text-xl'>Eski Faturalar</p>
      </div>
      <div className=''>
        <form onSubmit={handleSubmit(handleForm)}>
          <div className='lg:grid lg:grid-cols-6 gap-4'>
              <div className='col-span-1'>
                <InputWithLabel {...dateInputs["startDate"]} addClass="mt-2" />
              </div>
              <div className='col-span-1'>
                <InputWithLabel {...dateInputs["endDate"]} addClass="mt-2" />
              </div>
              <div className='col-span-1 flex items-end mb-1'>
                <button type="submit" className="text-white bg-emerald-500 px-2 py-1 border border-emerald-500 min-w-[120px]" >Faturaları Göster</button>
              </div>
          </div>
        </form>
      </div>
      <div className='w-full py-2 px-2 mt-2 text-left text-sm bg-rose-200 border border-rose-400'>
          <p>Seçmiş olduğunuz tarih aralığında {invoiceCount} adet fatura bulunmuştur.</p>
          {(invoices && Object.keys(invoices).length < invoiceCount) && 
            <p>Performans nedenlerinden dolayı seçmiş olduğunuz tarih aralığındaki son 50 adet fatura gösterilmektedir. Aradığınız faturayı bulamadıysanız daha dar bir tarih aralığı seçiniz.</p>
          }
      </div>
      <div className='mt-4'>
        <OldInvoiceTable data={invoices} 
              actions={
                {
                    preview: {status: isOpenPreview, action: setIsOpenPreview}, 
                    previewed: {status: invoiceForPreview, action: setInvoiceForPreview}, 
                }
              } 
              progressPending={pending}
          /> 
      </div>
      <PreviewForOld isOpen={isOpenPreview} isOpenAction={setIsOpenPreview} selectedData={invoiceForPreview}/>
      {loading && <LoadingIndicator />}
    </div>
  )
}

export default InvoiceOld