import { useState } from 'react'
import {useForm} from "react-hook-form"
import {useSanctum} from "react-sanctum"
import AuthLayout from "layouts/AuthLayout"
import InputFloatingLabelWithValidaiton from "elements/InputFloatingLabelWithValidaiton"
import LinkBlue from "elements/LinkBlue"
import ButtonBig from "elements/ButtonBig"
import PostRequest from 'components/PostRequest'

function Register() {

    const {register, handleSubmit, formState: { errors }, getValues} = useForm()
    const [registerStatus, setRegisterStatus] = useState(null)
    const {setUser} = useSanctum()
    const handleLRegister = (data) => {
        PostRequest('register', data)
            .then(response => {
                const user = response.data
                setUser(user)})
            .catch(error => {
                const err = error.response.data.errors
                const errorItems = Object.keys(err).map((value, index) => {
                    return (
                        <li key={value.toString()}>{err[value][0]}</li>
                    )
                })
                const errorMessage = (
                    <div className='text-sm text-red-600'>
                        <p>{error.response.data.message}</p>   
                        <ul className='list-[circle] list-inside'>
                            {errorItems}
                        </ul>                     
                    </div>
                )
                setRegisterStatus(errorMessage)
            })
    }

    return (
        <AuthLayout>
            <div>
                <div>
                    {registerStatus}
                </div>
                <form className="w-full flex flex-col" onSubmit={handleSubmit(handleLRegister)}>
                    <InputFloatingLabelWithValidaiton 
                        name="name" 
                        register={register} 
                        label="Ad Soyad" 
                        type="text" 
                        validator={{
                            required: "Bu alan zorunludur.", 
                            minLength: {value: 4, 
                                        message: "En az 4 karakter olmalı"}, 
                            maxLength: {value: 255, 
                                        message:"En fazla 255 karakter olmalı"},
                            pattern: {value: /^[a-zA-Z\s]*$/,
                                        message: "Sadece harf ve boşluktan oluşabilir."}
                        }} 
                        
                    />
                    <p className="text-sm text-red-600">{errors?.name?.message}</p>
                    <InputFloatingLabelWithValidaiton 
                        name="company" 
                        register={register} 
                        label="Şirket Adı" 
                        type="text" 
                        validator={{
                            required: "Bu alan zorunludur.", 
                            minLength: {value: 4, 
                                        message: "En az 4 karakter olmalı"}, 
                            maxLength: {value: 255, 
                                        message:"En fazla 255 karakter olmalı"}
                        }} 
                        
                    />
                    <p className="text-sm text-red-600">{errors?.company?.message}</p>
                    <InputFloatingLabelWithValidaiton 
                        name="phone" 
                        register={register} 
                        label="Telefon" 
                        type="text" 
                        validator={{
                            required: "Bu alan zorunludur.", 
                            minLength: {value: 4, 
                                        message: "En az 4 karakter olmalı"}, 
                            maxLength: {value: 255, 
                                        message:"En fazla 255 karakter olmalı"},
                            pattern: {value: /^[0-9]{10}$/,
                                        message: "10 haneli rakam olmalı"}
                        }} 
                        
                    />
                    <p className="text-sm text-red-600">{errors?.phone?.message}</p>
                    <InputFloatingLabelWithValidaiton 
                        name="email" 
                        register={register} 
                        label="E-Posta" 
                        type="text" 
                        validator={{
                            required: "Bu alan zorunludur.", 
                            minLength: {value: 4, 
                                        message: "En az 4 karakter olmalı"}, 
                            maxLength: {value: 255, 
                                        message:"En fazla 255 karakter olmalı"},
                            pattern: {value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        message: "Geçerli bir e-posta adresi olmalı"}
                        }} 
                        
                    />
                    <p className="text-sm text-red-600">{errors?.email?.message}</p>
                    <InputFloatingLabelWithValidaiton 
                        name="password" 
                        register={register} 
                        label="Şifre" 
                        type="password" 
                        validator={{
                            required: "Bu alan zorunludur.", 
                            minLength: {value: 8, 
                                        message: "En az 8 karakter olmalı"}, 
                            maxLength: {value: 24, 
                                        message:"En fazla 24 karakter olmalı"}
                        }} 
                        
                    />
                    <p className="text-sm text-red-600">{errors?.password?.message}</p>
                    <InputFloatingLabelWithValidaiton 
                        name="password_confirmation" 
                        register={register} 
                        label="Şifre Tekrar" 
                        type="password" 
                        validator={{
                            validate: (value) =>
                                value === getValues("password")
                        }} 
                        
                    />
                    <p className="text-sm text-red-600">{errors.password_confirmation && errors.password_confirmation.type === "validate" && "Girdiğiniz şifreler eşleşmiyor."}</p>
                    <ButtonBig type="submit" title="Ücretsiz Kayıt Ol" addClass="bg-red-600 text-white"/>
                    <p className='text-xs text-gray-700 mt-1'>Kayıt olarak <a className='text-blue-700 underline font-bold' href='https://www.favera.com.tr/kaynaklar/kullanici-sozlesmesi' target="_blank">linkte</a> yer alan tüm sözleşmeleri kabul etmiş sayılırsınız.</p>
                </form>
                <div className="mt-2 text-center">
                    <p className="text-black-700">Hesabınız var mı? Hemen <LinkBlue route="/login" title="giriş yapın." /> </p>   
                </div>
            </div>
        </AuthLayout>
    )
}

export default Register