import {useEffect, useState} from 'react'
import {useForm} from "react-hook-form"
import GetRequest from 'components/GetRequest'
import PostRequest from 'components/PostRequest'
import InputWithLabel from 'elements/InputWithLabel'
import ButtonBigWithAction from 'elements/ButtonBigWithAction'
import LoadingIndicator from 'pages/LoadingIndicator'
import { useNavigate } from 'react-router-dom'

const Gibportal = () => {
    const {register, handleSubmit, reset, formState: { errors }, setValue} = useForm({
        defaultValues: {
            username: null,
            password: null
        }
    })

    const navigate = useNavigate()

    const [saveStatus, setSaveStatus] = useState()
    const [currentData, setCurrentData] = useState({username: null, password: null})
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        GetRequest('api/settings/gibportal')
        .then(response => {
            if(Object.keys(response.data).length) {
                setCurrentData({username: response.data.username, password: response.data.password})
                setValue('username', response.data.username)
                setValue('password', response.data.password)
            }
            
        }).catch(error => {
            console.log(error)
        })
    }, [])

    const inputs = [
        {
            key: "createInput1",
            type: "text",
            name: "username",
            placeholder: "Zorunlu Alan",
            label: "Kullanıcı Adı *",
            register: register,
            validator: {
                required: "Bu alan zorunludur.",                 
            },
            error: errors?.username?.message 
        },
        {
            key: "createInput2",
            type: "text",
            name: "password",
            placeholder: "Zorunlu Alan",
            label: "Şifre *",
            register: register,
            validator: {
                required: "Bu alan zorunludur.",                 
            },
            error: errors?.password?.message 
        },
    ]

    const handleForm = (data) => {
        setLoading(true)
        PostRequest('api/settings/gibportal', data)
            .then(response => {
                if(response.data.token == null) {
                    const successMessage = (
                        <div className='text-sm text-red-100 bg-red-600 text-bold mb-2 p-1'>
                            <p>Girdiğiniz bilgiler GİB portal tarafından kabul edilmemiştir. Lütfen kontrol edip tekrar deneyin.</p> 
                        </div>
                    )
                    setSaveStatus(successMessage)
                } else {
                    const successMessage = (
                        <div className='text-sm text-green-100 bg-green-600 text-bold mb-2 p-1'>
                            <p>Bilgiler başarıyla kaydedildi. Faturalarınızı oluşturmaya başlayabilirsiniz.</p> 
                        </div>
                    )
                    setSaveStatus(successMessage)
                }
                
                
                setLoading(false)
            })
            .catch(error => {
                const err = error.response.data.errors
                if(err) {
                    const errorItems = Object.keys(err).map((value, index) => {
                        return (
                            <li key={value.toString()}>{err[value][0]}</li>
                        )
                    })
                    const errorMessage = (
                        <div className='text-sm text-red-600'>
                            <p>{error.response.data.message}</p>   
                            <ul className='list-[circle] list-inside'>
                                {errorItems}
                            </ul>                     
                        </div>
                    )
                    setSaveStatus(errorMessage)
                } else {
                    const errorMessage = (
                        <div className='text-sm text-red-100 bg-red-600 text-bold mb-2 p-1'>
                            <p>{`${error.response.statusText} (${error.response.status})`}</p> 
                        </div>
                    )   
                    
                    setSaveStatus(errorMessage)
                }  
                setLoading(false)
            })
    }
    return (
        <div className='text-center mx-6 mb-6 mt-3 lg:grid lg:grid-cols-2 lg:gap-8'>
            <div className='text-left mt-12'>
                <h2 className='text-2xl font-semibold text-gray-700 my-4 '>GİB Portal Ayarları</h2>
                <p className='text-md text-gray-700'> Programı kullanmaya devam edebilmek için GİB Portal kullanıcı adı ve şifrenizi girmelisiniz. Doğru bilgileri girdikten sonra programı kullanmaya başlayabilirsiniz.</p>
            </div>
            <div className='text-left'>
                <div className='h-12'>
                    {saveStatus}
                </div>
                <form onSubmit={handleSubmit(handleForm)}>
                {inputs.map((input, index) => {
                            return (
                                <div className='max-w-[400px]' key={`inputContainer${index}`}>                                    
                                        <InputWithLabel {...input} addClass="mt-2"/>
                                </div>
                            )
                        })  }
                    <ButtonBigWithAction type="submit" addClass="text-white bg-emerald-500 w-full max-w-[400px]" title="Kaydet" />
                </form>
            </div>
            {loading && <LoadingIndicator />}
        </div>
    )
};

export default Gibportal;
