import React from 'react'
import ButtonBigWithAction from 'elements/ButtonBigWithAction';
import PostRequest from 'components/PostRequest';

const Transfer = ({updatedStatus, updatedAction}) => {
    const [status, setStatus] = React.useState('notapproved')
    const [errorStatus, setErrorStatus] = React.useState(null)
    const [loading, setLoading] = React.useState(false)

    const handleApprove = () => {
        setLoading(true)
        PostRequest(`api/purchases/transfer`, null)
            .then(response => {
                setStatus('approved')
                updatedAction(!updatedStatus)
                setErrorStatus(null)
                setLoading(false)
            })
            .catch(error => {
                const err = error.response.data.errors
                if(err) {
                    const errorItems = Object.keys(err).map((value, index) => {
                        return (
                            <li key={value.toString()}>{err[value][0]}</li>
                        )
                    })
                    const errorMessage = (
                        <div className='text-sm text-red-600'>
                            <p>{error.response.data.message}</p>   
                            <ul className='list-[circle] list-inside'>
                                {errorItems}
                            </ul>                     
                        </div>
                    )
                    setErrorStatus(errorMessage)
                } else {
                    const errorMessage = (
                        <div className='text-sm text-red-100 bg-red-600 text-bold mb-2 p-1'>
                            <p>{`${error.response.statusText} (${error.response.status})`}</p> 
                        </div>
                    )   
                    
                    setErrorStatus(errorMessage)
                }                
                
                setLoading(false)
            })
    }

    return (
        <div className="pt-4 border-t border-gray-300 text-left" >
            <div className={status === 'notapproved' ? 'visible' : 'hidden'}>
                <h2 className="font-semibold pb-4">KDV Dahil Ödenecek Toplam Tutar: 600 TL</h2>
                {errorStatus ?? 
                    <>{errorStatus}</>
                }
                <ButtonBigWithAction onClick={handleApprove} type="button" addClass="text-white bg-rose-500" title="Havale ile Satın Almayı Onayla" />
            </div>
            <div className={status === 'approved' ? 'visible' : 'hidden'}>
                <h2 className="font-semibold pb-4">1 Yıllık plan için talebiniz alınmıştır.</h2>
                <h2 className="font-semibold pb-4">KDV Dahil Ödenecek Toplam Tutar: 600 TL</h2>
                <p>Aşağıda yer alan banka hesap bilgilerine belirtilen tutarı havale yapabilirsiniz.</p>
                <p className="pb-4">Söz konusu tutar hesabımıza ulaştıktan hemen sonra onay işlemleriniz devam edecektir.</p>
                <p>ALICI: Favera Yazılım ve Bilişim Teknolojileri Ltd. Şti.</p>
                <p>BANKA: Garanti Bankası</p>
                <p>IBAN: TR 89 0006 2000 7520 0006 2992 92</p>
                <p className="pb-4">AÇIKLAMA: E-Arşiv Portal Yıllık Plan Bedeli</p>
            </div>
        </div>
    )
}

export default Transfer