import {Sanctum} from "react-sanctum";
import {BrowserRouter as Router} from "react-router-dom";
import Content from "components/Content";

const sanctumConfig = {
  apiUrl: "https://earsiv-api.faveraportal.com",
  csrfCookieRoute: "sanctum/csrf-cookie",
  signInRoute: "login",
  signOutRoute: "logout",
  userObjectRoute: "api/user",
};

function App() {

  return (
    <>
    <Sanctum config={sanctumConfig}>
      <Router>
        <Content />
      </Router>
    </Sanctum>
    </>
  );
}

export default App;
