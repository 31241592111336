export default [
  {
    key: "601",
    value:
      "601 - Yapım İşleri ile Bu İşlerle Birlikte İfa Edilen Mühendislik-Mimarlık ve Etüt-Proje Hizmetleri [KDVGUT-(I/C-2.1.3.2.1)]",
    ratio: 40,
  },
  {
    key: "602",
    value: "602 - Etüt, plan-proje, danışmanlık, denetim vb",
    ratio: 90,
  },
  {
    key: "603",
    value:
      "603 - Makine, Teçhizat, Demirbaş ve Taşıtlara Ait Tadil, Bakım ve Onarım Hizmetleri [KDVGUT- (I/C-2.1.3.2.3)] ",
    ratio: 70,
  },
  { key: "604", value: "604 - Yemek servis hizmeti", ratio: 50 },
  { key: "605", value: "605 - Organizasyon hizmeti", ratio: 50 },
  { key: "606", value: "606 - İşgücü temin hizmetleri", ratio: 90 },
  { key: "607", value: "607 - Özel güvenlik hizmeti", ratio: 90 },
  { key: "608", value: "608 - Yapı denetim hizmetleri", ratio: 90 },
  {
    key: "609",
    value:
      "609 - Fason Olarak Yaptırılan Tekstil ve Konfeksiyon İşleri, Çanta ve Ayakkabı Dikim İşleri ve Bu İşlere Aracılık Hizmetleri [KDVGUT-(I/C-2.1.3.2.7)]",
    ratio: 70,
  },
  {
    key: "610",
    value:
      "610 - Turistik mağazalara verilen müşteri bulma/ götürme hizmetleri",
    ratio: 90,
  },
  {
    key: "611",
    value:
      "611 - Spor kulüplerinin yayın, reklam ve isim hakkı gelirlerine konu işlemleri",
    ratio: 90,
  },
  {
    key: "612",
    value: "612 - Temizlik Hizmeti [KDVGUT-(I/C-2.1.3.2.10)]",
    ratio: 90,
  },
  {
    key: "613",
    value: "613 - Çevre, Bahçe ve Bakım Hizmetleri [KDVGUT-(I/C-2.1.3.2.11)]",
    ratio: 90,
  },
  { key: "614", value: "614 - Servis taşımacıliğı", ratio: 50 },
  {
    key: "615",
    value:
      "615 - Her Türlü Baskı ve Basım Hizmetleri [KDVGUT-(I/C-2.1.3.2.12)]",
    ratio: 70,
  },
  {
    key: "616",
    value: "616 - Diğer Hizmetler [KDVGUT-(I/C-2.1.3.2.13)]",
    ratio: 50,
  },
  {
    key: "617",
    value: "617 - Hurda metalden elde edilen külçe teslimleri",
    ratio: 70,
  },
  {
    key: "618",
    value:
      "618 - Hurda Metalden Elde Edilenler Dışındaki Bakır, Çinko, Demir Çelik, Alüminyum ve Kurşun Külçe Teslimi [KDVGUT-(I/C-2.1.3.3.1)]",
    ratio: 70,
  },
  {
    key: "619",
    value: "619 - Bakir, çinko ve alüminyum ürünlerinin teslimi",
    ratio: 70,
  },
  {
    key: "620",
    value: "620 - istisnadan vazgeçenlerin hurda ve atık teslimi",
    ratio: 70,
  },
  {
    key: "621",
    value:
      "621 - Metal, plastik, lastik, kauçuk, kâğit ve cam hurda ve atıklardan elde edilen hammadde teslimi",
    ratio: 90,
  },
  {
    key: "622",
    value: "622 - Pamuk, tiftik, yün ve yapaği ile ham post ve deri teslimleri",
    ratio: 90,
  },
  { key: "623", value: "623 - Ağaç ve orman ürünleri teslimi", ratio: 50 },
  {
    key: "624",
    value: "624 - Yük Taşımacılığı Hizmeti [KDVGUT-(I/C-2.1.3.2.11)]",
    ratio: 20,
  },
  {
    key: "625",
    value: "625 - Ticari Reklam Hizmetleri [KDVGUT-(I/C-2.1.3.2.15)]",
    ratio: 30,
  },
  {
    key: "626",
    value: "626 - Diğer Teslimler [KDVGUT-(I/C-2.1.3.3.7.)]",
    ratio: 20,
  },
  {
    key: "627",
    value: "627 - Demir-Çelik Ürünlerinin Teslimi [KDVGUT-(I/C-2.1.3.3.8)]",
    ratio: 50,
  },
  {
    key: "627-Ex",
    value: "627 - Demir-Çelik Ürünlerinin Teslimi [KDVGUT-(I/C-2.1.3.3.8)] (01/11/2022 tarihi öncesi)",
    ratio: 40,
  },
  {
    key: "801",
    value: "801 - Yapım İşleri ile Bu İşlerle Birlikte İfa Edilen Mühendislik-Mimarlık ve Etüt-Proje Hizmetleri[KDVGUT-(I/C-2.1.3.2.1)]",
    ratio: 100,
  },
  {
    key: "802",
    value: "802 - Etüt, Plan-Proje, Danışmanlık, Denetim ve Benzeri Hizmetler[KDVGUT-(I/C-2.1.3.2.2)]",
    ratio: 100,
  },
  {
    key: "803",
    value: "803 - Makine, Teçhizat, Demirbaş ve Taşıtlara Ait Tadil, Bakım ve Onarım Hizmetleri[KDVGUT- (I/C-2.1.3.2.3)]",
    ratio: 100,
  },
  {
    key: "804",
    value: "804 - Yemek Servis Hizmeti[KDVGUT-(I/C-2.1.3.2.4)]",
    ratio: 100,
  },
  {
    key: "805",
    value: "805 - Organizasyon Hizmeti[KDVGUT-(I/C-2.1.3.2.4)]",
    ratio: 100,
  },
  {
    key: "806",
    value: "806 - İşgücü Temin Hizmetleri[KDVGUT-(I/C-2.1.3.2.5)]",
    ratio: 100,
  },
  {
    key: "807",
    value: "807 - Özel Güvenlik Hizmeti[KDVGUT-(I/C-2.1.3.2.5)]",
    ratio: 100,
  },
  {
    key: "808",
    value: "808 - Yapı Denetim Hizmetleri[KDVGUT-(I/C-2.1.3.2.6)]",
    ratio: 100,
  },
  {
    key: "809",
    value: "809 - Fason Olarak Yaptırılan Tekstil ve Konfeksiyon İşleri, Çanta ve Ayakkabı Dikim İşleri ve Bu İşlere Aracılık Hizmetleri[KDVGUT-(I/C-2.1.3.2.7)]",
    ratio: 100,
  },
  {
    key: "810",
    value: "810 - Turistik Mağazalara Verilen Müşteri Bulma/ Götürme Hizmetleri[KDVGUT-(I/C-2.1.3.2.8)]",
    ratio: 100,
  },
  {
    key: "811",
    value: "811 - Spor Kulüplerinin Yayın, Reklâm ve İsim Hakkı Gelirlerine Konu İşlemleri[KDVGUT-(I/C-2.1.3.2.9)]",
    ratio: 100,
  },
  {
    key: "812",
    value: "812 - Temizlik Hizmeti[KDVGUT-(I/C-2.1.3.2.10)]",
    ratio: 100,
  },
  {
    key: "813",
    value: "813 - Çevreve Bahçe Bakım Hizmetleri[KDVGUT-(I/C-2.1.3.2.10)]",
    ratio: 100,
  },
  {
    key: "814",
    value: "814 - Servis Taşımacılığı Hizmeti[KDVGUT-(I/C-2.1.3.2.11)]",
    ratio: 100,
  },
  {
    key: "815",
    value: "815 - Her Türlü Baskı ve Basım Hizmetleri[KDVGUT-(I/C-2.1.3.2.12)]",
    ratio: 100,
  },
  {
    key: "816",
    value: "816 - Hurda Metalden Elde Edilen Külçe Teslimleri[KDVGUT-(I/C-2.1.3.3.1)]",
    ratio: 100,
  },
  {
    key: "817",
    value: "817 - Hurda Metalden Elde Edilenler Dışındaki Bakır, Çinko, Demir Çelik, Alüminyum ve Kurşun Külçe Teslimi [KDVGUT-(I/C-2.1.3.3.1)]",
    ratio: 100,
  },
  {
    key: "818",
    value: "818 - Bakır, Çinko, Alüminyum ve Kurşun Ürünlerinin Teslimi[KDVGUT-(I/C-2.1.3.3.2)]",
    ratio: 100,
  },
  {
    key: "819",
    value: "819 - İstisnadan Vazgeçenlerin Hurda ve Atık Teslimi[KDVGUT-(I/C-2.1.3.3.3)]",
    ratio: 100,
  },
  {
    key: "820",
    value: "820 - Metal, Plastik, Lastik, Kauçuk, Kâğıt ve Cam Hurda ve Atıklardan Elde Edilen Hammadde Teslimi[KDVGUT-(I/C-2.1.3.3.4)]",
    ratio: 100,
  },
  {
    key: "821",
    value: "821 - Pamuk, Tiftik, Yün ve Yapağı İle Ham Post ve Deri Teslimleri[KDVGUT-(I/C-2.1.3.3.5)]",
    ratio: 100,
  },
  {
    key: "822",
    value: "822 - Ağaç ve Orman Ürünleri Teslimi[KDVGUT-(I/C-2.1.3.3.6)]",
    ratio: 100,
  },
  {
    key: "823",
    value: "823 - Yük Taşımacılığı Hizmeti [KDVGUT-(I/C-2.1.3.2.11)]",
    ratio: 100,
  },
  {
    key: "824",
    value: "824 - Ticari Reklam Hizmetleri [KDVGUT-(I/C-2.1.3.2.15)]",
    ratio: 100,
  },
  {
    key: "825",
    value: "825 - Demir-Çelik Ürünlerinin Teslimi [KDVGUT-(I/C-2.1.3.3.8)]",
    ratio: 100,
  },
];
