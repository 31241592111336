import {useEffect} from 'react';
import LoadingIndicator from 'pages/LoadingIndicator';
import { Icon } from 'elements/Icons';

const TableLayoutWithoutPagination = ({columns, data, mobileHeader, progressPending = null}) => {

    const handleMobileOpen = (id) => {
        const tds =  document.querySelectorAll(`[data-id='${id}']`)
        const chewron =  document.querySelectorAll(`[data-chewron-id='${id}']`)
        Array.from(tds).map(node => {
            node.classList.toggle('visible')
            node.classList.toggle('hidden')
        })
       
        Array.from(chewron).map(node => {
            node.classList.toggle('rotate-180')            
        })
    }
  return (
      <div className='w-full relative'>
        <table className='w-full text-xs'>
            <thead className='text-gray-100'>
                <tr className='bg-gray-700'>
                {columns.map((column, index) => {
                    return <th className='px-2 py-2 hidden lg:table-cell' key={`tableHead${index}`}>{column.name}</th>
                    })}
                </tr>
            </thead>
            <tbody>
                    {data != null && Object.keys(data).length > 0 ? data.map((customer) => {
                        return (<tr className='border lg:border-x-0 lg:border-t-0 border-gray-500 even:bg-gray-200 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-4 lg:mb-0 shadow-lg lg:shadow-none' key={`tableRow${customer.id ?? customer.ettn}`}>
                            <td className='w-full lg:w-auto lg:hidden text-left text-base bg-gray-700 text-gray-100'>
                                <button onClick={(e) => handleMobileOpen(customer.id ?? customer.ettn)} className='px-2 py-1 w-full text-left flex justify-between items-center'>
                                    <span>{mobileHeader(customer)}</span>
                                    <span data-chewron-id={customer.id ?? customer.ettn}>
                                        <Icon name="chewronDownFilled" size="18"/>
                                    </span>
                                </button>
                            </td>
                            {columns.map((column, index) => {
                                return <td className='px-2 py-1 w-full lg:w-auto lg:table-cell text-left hidden lg:visible' data-id={customer.id ?? customer.ettn} key={`tableCell${customer.id}_${index}`}>
                                    {column.headerMobileHidden === false && <span className="lg:hidden font-semibold">{column.name}: </span>}
                                    <span>{column.selector(customer)}</span>
                                    </td>
                            })}
                        </tr>)
                    })
                    : (<tr><td>Gösterilecek veri bulunamadı.</td></tr>)
                    }
            </tbody>          
        </table>
        {progressPending && <LoadingIndicator />}
      </div>
  );
};

export default TableLayoutWithoutPagination;
