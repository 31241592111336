import { useSanctum } from "react-sanctum"
import { Navigate } from "react-router-dom"
import Loading from "pages/Loading"

function RedirectIfAuthanticated({ children }) {
    const { authenticated} = useSanctum()
    
    if (authenticated === true) {
        // Signed in        
        return <Navigate to="/" replace />;
    } else if (authenticated === false) {
        // Signed out
        return children
    } else {
        // API call hasn't finished
        return <Loading />
    }
    
}

export default RedirectIfAuthanticated

