function Loading() {
    return (
        <div className='absolute inset-0 bg-white opacity-90 flex justify-center items-center'>
            <div className='flex flex-col items-center justify-center gap-y-6'>
            <span className="animate-ping inline-flex h-10 w-10 rounded-full bg-sky-400 opacity-75"></span>
            <p>Yükleniyor</p>
            </div>
        </div>
    )
}

export default Loading 