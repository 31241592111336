import {Routes, Route} from 'react-router-dom'
import Login from 'pages/Login'
import Register from 'pages/Register'
import ForgotPassword from 'pages/ForgotPassword'
import ResetPassword from 'pages/ResetPassword'
import Home from 'pages/Home'
import Invoices from 'pages/Invoices'
import InvoiceIssued from 'pages/Invoices/InvoiceIssued'
import InvoiceCreate from 'pages/Invoices/InvoiceCreate'
import InvoiceUpdate from 'pages/Invoices/InvoiceUpdate'
import InvoiceDraft from 'pages/Invoices/InvoiceDraft'
import InvoiceIncoming from 'pages/Invoices/InvoiceIncoming'
import InvoiceCancelled from 'pages/Invoices/InvoiceCancelled'
import InvoiceOld from 'pages/Invoices/InvoiceOld'
import InvoiceReport from 'pages/Invoices/InvoiceReport'
import Customers from 'pages/Customers'
import Items from 'pages/Items'
import Trendyols from 'pages/Trendyols'
import Einvoices from 'pages/Einvoices'
import Settings from 'pages/Settings'
import Gibportal from 'pages/Settings/Gibportal'
import Purchases from 'pages/Purchases'
import AppLayout from 'layouts/AppLayout'
import RequireAuth from './RequireAuth'
import RequireGib from './RequireGib'
import RequireExpire from './RequireExpire'
import RedirectIfAuthanticated from './RedirectIfAuthanticated'

import Navbar from "components/Navbar";

function Content() {
    return (
            <Routes>
                <Route path="/" element={
                    <RequireAuth>
                        <RequireExpire>
                            <RequireGib>
                                <AppLayout />
                            </RequireGib>
                        </RequireExpire>
                    </RequireAuth>
                } >
                    
                        <Route index element={<Home />} />
                        <Route path="/invoices" element={<Invoices />}>
                            <Route path="/invoices/issued" element={<InvoiceIssued />} />
                            <Route path="/invoices/create" element={<InvoiceCreate />} />
                            <Route path="/invoices/:invoiceId/update" element={<InvoiceUpdate />} />
                            <Route path="/invoices/draft" element={<InvoiceDraft />} />
                            <Route path="/invoices/incoming" element={<InvoiceIncoming />} />
                            <Route path="/invoices/cancelled" element={<InvoiceCancelled />} />
                            <Route path="/invoices/old" element={<InvoiceOld />} />
                            <Route path="/invoices/report" element={<InvoiceReport />} />
                        </Route>
                        <Route path="/customers" element={<Customers />} />
                        <Route path="/items" element={<Items />} />
                        <Route path="/trendyols" element={<Trendyols />} />
                        <Route path="/settings" element={<Settings />} />
                        <Route path="/einvoices" element={<Einvoices />} />
                </Route>
                <Route path="/" element={
                    <RequireAuth>
                        <RequireExpire>
                            <AppLayout />
                        </RequireExpire>
                    </RequireAuth>
                } >
                    <Route path="/settings/gibportal" element={<Gibportal />} />
                </Route>
                <Route path="/" element={
                    <RequireAuth>
                            <AppLayout />
                    </RequireAuth>
                } >
                    <Route path="/purchases" element={<Purchases />} />
                </Route>
                <Route path="/login" element={
                    <RedirectIfAuthanticated>
                        <Login />
                    </RedirectIfAuthanticated>
                    } />
                <Route path="/register" element={
                    <RedirectIfAuthanticated>
                        <Register />
                    </RedirectIfAuthanticated>
                } />
                <Route path="/forgot-password" element={
                    <RedirectIfAuthanticated>
                        <ForgotPassword />
                    </RedirectIfAuthanticated>
                } />
                <Route path="/password-reset/:token" element={
                    <RedirectIfAuthanticated>
                        <ResetPassword />
                    </RedirectIfAuthanticated>
                } />
            </Routes>
    )
}

export default Content 