import {useEffect, useState} from 'react';
import {useForm} from "react-hook-form"
import FormModal from 'components/FormModal';
import ButtonBigWithAction from 'elements/ButtonBigWithAction';
import InputWithLabel from 'elements/InputWithLabel';
import SelectWithLabel from 'elements/SelectWithLabel';
import InputSplit from 'components/InputSplit';
import PutRequest from 'components/PutRequest'
import LoadingIndicator from 'pages/LoadingIndicator';
import units from 'data/Units';
import vatRatios from 'data/VatRatios';

const Edit = ({isOpen, isOpenAction, updateStatus, updatedAction, selectedData}) => {
    const {register, handleSubmit, reset, formState: { errors }, setValue} = useForm({
        defaultValues: {
            name: null,
            unit: 'C62',
            unitPrice: 0,
            vatRatio: "20",
        }
    })

    useEffect(() => {
        if(selectedData) {
            setValue('name', selectedData.name)
            setValue('unit', selectedData.unit)
            setValue('unitPrice', selectedData.unitPrice)
            setValue('vatRatio', selectedData.vatRatio)
           
        } else {
            setValue('name', null)
            setValue('unit', null)
            setValue('unitPrice', null)
            setValue('vatRatio', null)
        }
    }, [selectedData])

    const actions = (
        <>
            <ButtonBigWithAction type="submit" addClass="text-white bg-emerald-500" title="Kaydet" />
        </>
    )

    const inputs = [
        {
            key: "createInput1",
            type: "text",
            name: "name",
            placeholder: "Zorunlu Alan",
            label: "Ürün/Hizmet Adı *",
            register: register,
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 3, 
                    message: "En az 3 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"}
            },
            error: errors?.name?.message 
        },
        {
            key: "createInput2",
            type: "select",
            name: "unit",
            placeholder: "Zorunlu Alan",
            label: "Birim *",
            register: register,
            validator: {
                required: "Bu alan zorunludur."
            },
            error: errors?.unit?.message,
            data: units
        },
        {
            key: "createInput3",
            type: "text",
            name: "unitPrice",
            placeholder: "Zorunlu Alan",
            label: "Birim Fiyat *",
            register: register,
            validator: {
                required: "Bu alan zorunludur.", 
                pattern: {value: /^[0-9]\d*(,\d+)?$/,
                  message: "12345,67 formatında bir sayı olmalı"},
                validate: {
                    positive: v => parseFloat(v.toString().replace(",", ".")) >= 0 || 'Sıfır veya sıfırdan büyük olmalı'
                },
            },
            error: errors?.unitPrice?.message
        },
        {
            key: "createInput4",
            type: "select",
            name: "vatRatio",
            placeholder: "Zorunlu Alan",
            label: "KDV Oranı *",
            register: register,
            validator: {
                required: "Bu alan zorunludur."
            },
            error: errors?.vatRatio?.message,
            data: vatRatios
        }
    ]

    const formColumnNumber = 1

    const dividedInputs = InputSplit(inputs, formColumnNumber)

    const [editStatus, setEditStatus] = useState(null)
    const [loading, setLoading] = useState(false)

    const handleForm = (data) => {
        setLoading(true)
        PutRequest('api/savedItems/'+selectedData.id, data)
            .then(response => {
                const successMessage = (
                    <div className='text-sm text-green-100 bg-green-600 text-bold mb-2 p-1'>
                        <p>Ürün/Hizmet bilgileri başarıyla güncellendi.</p> 
                    </div>
                )
                setEditStatus(successMessage)
                setLoading(false)
                updatedAction(!updateStatus)
            })
            .catch(error => {
                const err = error.response.data.errors
                if(err) {
                    const errorItems = Object.keys(err).map((value, index) => {
                        return (
                            <li key={value.toString()}>{err[value][0]}</li>
                        )
                    })
                    const errorMessage = (
                        <div className='text-sm text-red-600'>
                            <p>{error.response.data.message}</p>   
                            <ul className='list-[circle] list-inside'>
                                {errorItems}
                            </ul>                     
                        </div>
                    )

                    setEditStatus(errorMessage)
                } else {
                    const errorMessage = (
                        <div className='text-sm text-red-100 bg-red-600 text-bold mb-2 p-1'>
                            <p>{`${error.response.statusText} (${error.response.status})`}</p> 
                        </div>
                    )   
                    
                    setEditStatus(errorMessage)
                }                
                
                setLoading(false)
            })
    }

    return (
        <FormModal isOpen={isOpen} 
                    isOpenAction={isOpenAction} 
                    id="itemEditModal" 
                    title="Ürün/Hizmet Bilgilerini Düzenle" 
                    cancelButton={true} 
                    actions={actions}
                    submitAction={handleSubmit(handleForm)}
                    actionsForClear={[setEditStatus]}>
            <div>
                {editStatus}
            </div>
            <div className='lg:grid lg:grid-cols-1 lg:gap-x-4'>                
                    {
                        dividedInputs.map((dividedInput, index) => {
                            return (
                                <div className='lg:col-span-1 flex flex-col gap-y-2' key={`inputContainer${index}`}>
                                    {dividedInput.map(input => {
                                        return input.type === "select" ? 
                                        <SelectWithLabel {...input} addClass="mt-2" /> :
                                        <InputWithLabel {...input} addClass="mt-2"/>
                                    })}
                                </div>
                            )
                        })                        
                    }
            </div>
            {loading && <LoadingIndicator />}
        </FormModal>
    );
};

export default Edit;
