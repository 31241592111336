export default [
    {
        key: 'SATIS',
        value: 'SATIS'
    },
    {
        key: 'IADE',
        value: 'IADE'
    },
    {
        key: 'TEVKIFAT',
        value: 'TEVKIFAT'
    },
    {
        key: 'ISTISNA',
        value: 'ISTISNA'
    },
]